import axios from "axios";

async function UploadMap(title, content, jwt){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/maps/upload';
    const params = {
        title, 
        content, 
        jwt
    };

    var response = await axios.post(url, params);
    if (response == null) return null;
    return response.data;
}

export {
    UploadMap,
}