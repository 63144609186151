function PlatformLogo(props){
    const {
        fontSize='28px'
    } = props;

    return (
        <>
            <div style={{fontFamily: 'Lato', fontWeight: '300'}}>
                <div style={{display: 'inline-block', fontWeight: '400', fontSize}}>Headai</div><div style={{display: 'inline-block', backgroundColor: '#08233b', padding: '8px 10px', marginLeft: '5px', borderRadius: '0 11px 11px 0', color: '#ffffff', fontSize}}>GRAPHMIND</div>
                <div style={{fontSize: '14px'}}>Infrastructure for predictive text analytics</div>
            </div>
        </>
    )
}

export default PlatformLogo;