import Nav from 'react-bootstrap/Nav';

function LoginButton() {
    return (
        <>
            <Nav.Link href="/login">Login</Nav.Link>
        </>
    )
}

export default LoginButton;