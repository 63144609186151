import React from 'react';

import { Navigate } from "react-router-dom";
import withRouter from '../components/withRouter';
import * as authServices from '../services/auth.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';

class LogoutPage extends React.Component{
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        super(props);

        const jwtVerification = props.searchParams.get('token');

        const invalidToken = (typeof(jwtVerification) !== 'string' || jwtVerification.length == 0);

        this.state = {
            verificationIsReady: false,
            redirectToHome: false,
            jwt: jwtVerification,
            invalidToken: invalidToken,
        };
    }

    async componentDidMount(){
        const token = this.state.jwt;
        const response = await authServices.VerifyAccount(token);

        if (response.code == 'InvalidToken') {
            this.setState({invalidToken:true});
        } else {
            this.setState({verificationIsReady:true});
        }
    }

    render(){
        if (this.state.invalidToken){
            return InvalidToken();
        }

        if (this.state.redirectToHome){
            return <Navigate to="/Logout"/>
        }

        if (this.state.verificationIsReady){
            new Promise(r => setTimeout(r, 5000)).then(()=>{
                this.setState({redirectToHome:true});
            });

            return (
                <h2>
                    Your Account was verified succesfully. Now you can Login and start using our solutions...
                </h2>
            )
        }

        return (
            <>
                <h2>Verifying your account...</h2>
            </>
        )
    }
}

function InvalidToken(){
    return (
        <h2>An error occurred verifying your account: Token Is Invalid</h2>
    )
}

// With Router passes URL info to Class Component
export default withRouter(LogoutPage);