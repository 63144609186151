import React from 'react'

import Carousel from 'react-bootstrap/Carousel';

import * as MegatronServices from '../../services/megatron.service';

import './MapCarousel.css';

import * as d3 from "d3"
import wordplot from "../../lib/wordplot";

class MapCarousel extends React.Component {
    constructor(props){
        super(props);

        var {
            onLoading = ()=>{},
            info_maps = [],
            active_index_carousel = 0,
        } = props;

        if (!Array.isArray(info_maps)) info_maps = [];
        const nPlots = info_maps.length;

        if (typeof(onLoading) !== 'function') onLoading = ()=>{};


        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            info_maps,
            onLoading,
            plots: new Array(nPlots),
            alreadyRendered: false,
            active_index_carousel,
        }
    }

    handleSelect(index,e){
        this.props.handler(index);
        this.setState({
            active_index_carousel: index,
        });
    }

    refreshDifferentUrls(oldUrls, newUrls){
        for (let i = 0; i < newUrls.length; i++) {
            const newUrl = newUrls[i].url;
            const oldUrl = oldUrls[i].url;
            if (newUrl !== oldUrl){
                this.refreshOneMap(newUrl, i);
            }
        }
    }

    listsAreDifferent(list1,list2){
        if (list1.length !== list2.length) return true;

        for (let i = 0; i < list1.length; i++) {
            const e1 = list1[i];
            const e2 = list2[i];
            if (e1.url !== e2.url) return true;
        }
        return false;
    }

    componentDidUpdate(prevProps,prevEv){
        const newState = {};
        // Urls
        const newUrls = this.props.info_maps;
        const oldUrls = prevProps.info_maps;
        if (this.listsAreDifferent(newUrls,oldUrls)){
            newState.info_maps = newUrls;
        }

        const newIndex = this.props.active_index_carousel;
        const oldIndex = prevProps.active_index_carousel;
        if (newIndex !== oldIndex){
            newState.active_index_carousel = newIndex;
        }

        if (Object.keys(newState) == 0) return;

        this.setState(newState);
        this.refreshDifferentUrls(oldUrls,newUrls);
    }

    componentDidMount(){
        this.refreshMaps();
        this.setState({alreadyRendered:true});
    }
    
    refreshMaps(){
        const info_maps = this.props.info_maps;

        for (let index = 0; index < info_maps.length; index++) {
            const info = info_maps[index];
            const url = info.map;
            this.refreshOneMap(url, index);
        }
    }

    async refreshOneMap(url,index){
        const plotId = 'Map' + String(index);

        const {
            props
        } = this.props.info_maps[index];

        props.width = 1139;
        props.height = 500;
        props.disableSaveButtons = true;

        if (this.state.plots[index] == null){
            const plot = BuildEmptyVisualization(plotId, props);
            this.state.plots[index] = plot;
        }

        if (typeof(url) !== "string") return;

        try {
            var plot = BuildEmptyVisualization(plotId,props);
            plot.showMessage('Loading Map...');
            
            // Wait until Map is calculated
            var loading = true;
            this.state.onLoading(index,loading);
            await MegatronServices.waitUntilReady(url);
            await new Promise(r => setTimeout(r, 2000));
            var loading = false;
            this.state.onLoading(index,loading);

            const json = await d3.json(url);
            plot.getComponents().div.selectAll('*').remove();
            plot = BuildHexagonMap(json,plotId,props);
            this.state.plots[index] = plot;
        } catch (error) {
            var plot = BuildEmptyVisualization(plotId,props);
            this.state.plots[index] = plot;
        }
    }

    render(){
        return (
            <Carousel 
                activeIndex={this.state.active_index_carousel}
                onSelect={this.handleSelect}
                variant="dark" 
                interval={null}
                controls={false}
            >
                {/* {console.log(this.state.active_index_carousel)} */}
                {
                    this.state.info_maps.map((info_map,i)=>{
                        const { } = info_map;
                        const plotId = "Map" + String(i)
                        return (<Carousel.Item data={this.state.active_index_carousel} key={i}>
                            <center>
                                <div id={plotId} className={"CarouselMap"}></div>
                            </center>
                        </Carousel.Item>)
                    })
                }

                
            </Carousel>
        );
    }
}

function BuildHexagonMap(json, plotId, props){
    var plot = null;

    try {
        plot = wordplot.buildVisualization(plotId,json,'hexagon',props);
        plot.refresh();
        return plot;            
    } catch (error) {
        plot = BuildEmptyVisualization(plotId, props)
    }
    return plot;
}

function BuildEmptyVisualization(plotId, props){
    const plot = new wordplot.Visualization(plotId,props);
    return plot;
}

export default MapCarousel;