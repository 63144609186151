import { default as jwt_decode } from 'jwt-decode';

// These functions should be used ONLY in MainContext.js
function setJwt(token){
    if (!jwtIsValid(token)){
        token = null;
    }
    localStorage.setItem("jwt", token);
    return token;
}

// These functions should be used ONLY in MainContext.js
function getJwt(){
    const token = localStorage.getItem("jwt");
    if (token === 'null') return null;
    return token;
}

function jwtIsValid(token){
    if (token == null) return false;
    var decodedToken = jwt_decode(token, {complete: true});
    var dateNow = new Date();

    const isExpired = (decodedToken.exp*1000 < dateNow.getTime())

    const isValid = isExpired === false;
    return isValid;
}

function decodeJwt(token){
    var decodedToken = jwt_decode(token, {complete: true});
    return decodedToken;
}

export default {
    setJwt,
    getJwt,
    jwtIsValid,
    decodeJwt,
}