import { useState } from "react";
import { UploadFileModal } from "./UploadFileModal";

import './UploadFile.css';

export const UploadFile = (params) => {
    const {
        message="Upload your own data",
        triggerRefresh, onLoading
    } = params;

    const [modalIsVisible, showModal] = useState(false);

    return (
        <>
            <span className="uploadFileLabel" onClick={()=>{showModal(true)}}><u>{message}</u></span>
            <UploadFileModal
                triggerRefresh={triggerRefresh}
                modalIsVisible={modalIsVisible}
                showModal={showModal}
            ></UploadFileModal>
        </>
    )
};