import React from 'react'

import Carousel from 'react-bootstrap/Carousel';

import './MapCarousel.css';

class HomeCarousel extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <Carousel 
                className='HomeCarousel'
                variant="dark" 
                interval={3000}
                controls={false}
                fade={true}
            >
                <Carousel.Item key={1} style={{height:'200px'}}>
                    <div className='BorderContainer'>
                        <b>Future-Proof Education.</b> Forecast future skill needs with data-driven insights.
                    </div>
                </Carousel.Item>
                <Carousel.Item key={2} style={{height:'200px'}}>
                    <div className='BorderContainer'>
                        <b>Build smarter cities with data.</b> Big data for cities and organizations to make smart, informed decisions.
                        </div>
                </Carousel.Item>
                <Carousel.Item key={3} style={{height:'200px'}}>
                    <div className='BorderContainer'>
                        <b>Unlock Your Potential.</b> See recommendations to reach your dream career.
                        </div>
                </Carousel.Item>
            </Carousel>
        );
    }
}

export default HomeCarousel;