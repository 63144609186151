import axios from "axios";

async function loadRecommendations(map_url, namespace, jwt){
    if (typeof(map_url) !== "string" || map_url.length == 0) return null;
    if (typeof(namespace) !== "string" || namespace.length == 0) return null;

    const {existing, missing, intersection} = await getGapFromCompareMap(map_url);
    const existing_intersection = existing.concat(intersection);
    const missing_intersection = missing.concat(intersection);
    var renamedNamespace = '';

    if (namespace === 'linkedin'){
        renamedNamespace = 'linkedin_learning';
    } else {
        renamedNamespace = namespace;
    }

    const params = {
        interests: missing,
        skills: existing_intersection,
        jwt: jwt,
        namespace: renamedNamespace,
    }

    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/megatron/Compass';

    const results = await axios.post(url,params)

    const recom1 = results.data.recommendations_based_on_extensive_skills;
    const recom2 = results.data.recommendations_based_on_match;

    const recommendations = recom1.concat(recom2);

    return recommendations;
    // Call Compass
}

async function getGapFromCompareMap(map_url){
    const response = await axios.get(map_url);
    const map = response.data;
    const nodes = map.data.nodes;

    const groupIntersection = '1';
    const groupOnly1 = '2';
    const groupOnly2 = '3';

    const existing = [];    // Only 1
    const missing = [];     // Only 2
    const intersection = [];

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const group = node.group;
        const label = node.label;
        if (group === groupOnly1){
            existing.push(label)
        } else if (group === groupOnly2){
            missing.push(label);
        } else if (group === groupIntersection){
            intersection.push(label);
        } else {
            console.log(`Warning at getGap() -> Node group was not recognized: '${group}'`);
        }
    }

    return {existing, missing, intersection};
}

export {
    loadRecommendations,
    getGapFromCompareMap,
}