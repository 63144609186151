import React from 'react';

import withRouter from '../components/withRouter';

import { Message } from 'semantic-ui-react'

// Handle Logged In State - Verified Account
import SessionServices from '../services/session.service';
import { Navigate } from "react-router-dom";

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import { Button } from 'semantic-ui-react';
import PlatformLogo from '../components/PlatformLogo';

import * as authServices from '../services/auth.service';

class CheckYourEmailPage extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        super(props);

        this.state = {
            dateLastValidation: null,
            message: null, // 'successful', 'error'
        };
    }

    componentDidMount(){
        // ...
    }

    async handleSubmit(event){
        const jwt = this.context.getJwt();
        const currDate = new Date();
        
        var newState = {};

        try {
            await authServices.ResendVerificationEmail(jwt);   
            
            newState = {
                'dateLastValidation':currDate,
                'message':'successful'
            }
        } catch (error) {
            console.log(error);
            newState = {
                'dateLastValidation':currDate,
                'message':'error'
            }
        }
        
        this.setState(newState);
    }

    render(){
        /*******      Redirect to Home if already logged in       ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt) === false){
            return <Navigate to="/Login"/>
        }

        if (SessionServices.accountIsVerified(jwt) === true){
            return <Navigate to="/"/>
        }

        var buttonResendEmail = <></>;

        //const currentTime = new Date();
        const lastEmailSent = this.state.dateLastValidation;

        if (lastEmailSent == null){
            buttonResendEmail = (
                <Button onClick={(event)=>{this.handleSubmit.call(this,event)}} color="violet" size="large" type="submit" style={{'backgroundColor': '#98379a'}} as='a'>
                    Resend Verification Email
                </Button>
            );
        } else {
            buttonResendEmail = (
                <Button disabled color="violet" size="large" type="submit" style={{'backgroundColor': '#98379a'}} as='a'>
                Resend Verification Email
                </Button>
            )
        }

        // Message

        var message = (<></>);

        if (this.state.message === 'successful'){
            message = messageEmailSent();
        } else if (this.state.message === 'error'){
            message = messageEmailError();
        }


        /****************************************************************/
        return (
            <>
                <div className="Flex" style={{marginBottom: '0'}}>
                    <div className="VerticalImage" style={{ height: 'auto' }}>
                        {/* <img src={"/VerticalSideImage.jpg"}></img> */}
                    </div>
                    <div className="CheckMailPageRightContainer">
                        <div className="CheckMailPageRightContent">
                            
                            {message}

                            <center><PlatformLogo /></center>
                            <br/><br/>
                            <b><h3>Account Verification Pending</h3></b>
                            <br/><br/>
                            <p className="description">
                                Your Account hasn't been verified.
                                Please check your inbox and open the Link to verify your Account.

                                <br/><br/><br/>
                                Can't see the email?
                                <br/><br/><br/>

                                If you do not receive an email from us promptly, 
                                we kindly request you to inspect your spam folder.

                                In case you can't find the message, you can click the following button to resend the verification e-mail.
                            </p>
                            <br />
                            <center>
                                {buttonResendEmail}
                            </center>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <Footer />
                </div> */}
            </>
        );
    }
}

function messageEmailSent(){
    return (
        <Message positive>
            <center>
            <Message.Header>Resend was Succesfull</Message.Header>
            <p>The verification e-mail was sent successfully</p>
            </center>
        </Message>
    )
}

function messageEmailError(){
    return (
        <Message negative>
            <center>
            <Message.Header>Try again later</Message.Header>
            <p>An unexpected error occured sending the verification e-mail.</p>
            </center>
        </Message>
    )
}

// With Router passes URL info to Class Component
export default withRouter(CheckYourEmailPage);