/* eslint-disable */
// Wordplot Library
import * as d3Module from "d3"
import * as d3Hex from "d3-hexbin"

const {hexbin} = d3Hex;
const d3 = d3Module;
d3['hexbin'] = hexbin;

var wordplot = (function(){
const properties = (()=>{

function validateType(value,type){
    if (type == 'array'){
        return (Array.isArray(value));
    } else if (type == 'number'){
        return (typeof(value) == 'number' && !isNaN(value));
    } else if (['boolean','string'].includes(type)){
        return (typeof(value) === type);
    } else {
        console.log(`Error at validateType(${value},${type}) - Unsuported type`);
        return false;
    }
}

function validateChange(oldValue, newValue, type){
    //console.log(`type:${type} - oldValue:${oldValue} - newValue:${newValue}`);

    if (type == 'array'){
        const changed = ( JSON.stringify(oldValue) !== JSON.stringify(newValue) );
        return changed;
    } else if (['number','boolean','string'].includes(type)){
        return (oldValue !== newValue);
    } else {
        console.log(`Error at validateChange(${oldValue},${newValue},${type}) - Unsuported type`);
        return false;
    }
}

function preprocess(value, type, preprocessType){
    if (type == 'array' && preprocessType == 'comma-separated' && validateType(value,'string')){
        value = value.split(',');
        const newValue = [];
        for (let i = 0; i < value.length; i++) {
            const val = value[i].trim();
            if (val.length == 0) continue;
            newValue.push(val);
        }
        value = newValue;
    }
    return value;
}

function setProperties(propsDetails, props, newProps){
    const changes = {};
    const propNames = Object.keys(propsDetails);

    propNames.forEach((prop)=>{
        const type = propsDetails[prop].type;
        const preprocessType = propsDetails[prop].preprocess;
        const oldValue = props[prop];
        var newValue = newProps[prop];

        if (typeof(preprocessType) == 'string') newValue = preprocess(newValue, type, preprocessType);

        if (newValue == null) return;

        const correctType = validateType(newValue, type);
        const changed = validateChange(oldValue, newValue, type);

        //console.log(`${prop} - correctType:${correctType} - changed:${changed} - type:${type}`);

        if (correctType && changed){
            props[prop] = newValue;
            changes[prop] = newValue;
        }
    });

    return changes;
}

return {
    setProperties,
    preprocess,
}

})();/**
 * @abstract class
 */
class GraphProcessor{
  constructor(mapType){
    if (this.constructor == GraphProcessor) {
      throw new Error("GraphProcessor is an Abstract class, can't be instantiated.");
    }
    this.mapType = mapType;
  }

  /** @abstract method */
  getNeighborsCoordinates(center){
    throw new Error("Abstract Method getNeighborsCoordinates(center) has no implementation");
  }

  /** @abstract method */
  getSurroundingIds(center,matrix){
    throw new Error("Abstract Method getSurroundingIds(center,matrix) has no implementation");
  }
  
  getValidNeighborsCoordinates(center, matrix){
    var coords = this.getNeighborsCoordinates(center);
    
    var possibleNeighbors = coords.length,
        validCoords = [];
      
    for (var i=0; i < possibleNeighbors; i++){
      var x = coords[i][0],
          y = coords[i][1];
      if (x >= 0 && y >=0 && y < matrix.length && x < matrix[0].length){
        var val = matrix[y][x];
        if (val < 0){
          validCoords.push([x,y]) 
        }
      }
    }
    return validCoords;
  }
}

class GraphToMap {
  constructor(mindmap,mapType,props={}){
    switch (mapType) {
      case 'hexagon':
        this.processor = new wordplot.graph.HexagonGraphProcessor();
        break;
      case 'square':
        this.processor = new wordplot.graph.SquareGraphProcessor();
        break;
      default:
        throw new Error('Invalid mapType ' + mapType);
    }

    this.mindmap = mindmap;

    const { nodes } = this.mindmap.getData();

    this.mapType = mapType;

    this.properties = {
      valueField: 'value',
      nameField: 'label',
      categoryField: 'group',
      centerNode: null,
    }

    var { centerNode, nameField } = props;
    if (!this.mindmap.hasLabel(centerNode)){
      props.centerNode = null;
      const centerNodeInfo = wordplot.json.getCenterNode(nodes);
      if (centerNodeInfo !== null) props.centerNode = centerNodeInfo[nameField];
    }

    this.setProperties(props);
    this._locatedNodes = [];
    this._clusters = [];

    this._processNodes(nodes);

    this.locateNodes();
  }

  setProperties(props){
    if (typeof(props) !== "object") return {changes:{}};

    if (!this.mindmap.hasLabel(props.centerNode)) props.centerNode = null;

    const propsDetails = {
      centerNode: {
        type: 'string',
      },
    };

    const oldProps = this.getProperties();
    const newProps = props;
    const changes = wordplot.properties.setProperties(propsDetails, oldProps, newProps);
    return {changes};
  }

  getProperties(){
    return this.properties;
  }

  cleanProcessor(){
    this._locatedNodes = [];
    this._clusters = [];

    const {nodes} = this.mindmap.getData();

    if (this.properties.hasDefaultCenter == true) this.properties.centerNode = null;

    this._numberOfPendingNodes = nodes.length;
    for (var i=0; i < nodes.length; i++){
      const node = nodes[i];
      node['isPlaced'] = false;
      ['s1','s2','s3','s4','s5','s6','s7','s8','x','y'].forEach( attr => delete node[attr] );
    }
  }

  getLocatedNodes(){
    return this._locatedNodes;
  }

  _processNodes(nodes){
    nodes.forEach(node => {
      if (!node.hasOwnProperty('id')) throw new Error('Node doesn\'t have a well defined id');
      node['isPlaced'] = false;
    })
    this._numberOfPendingNodes = nodes.length;
  }

  setCenterLabel(label){
    if (typeof(label) != "string") return;
    label = label.trim();
    if (label.length == 0) return;

    if (!this.mindmap.hasLabel(label)) return;

    this.setProperties({'centerNode':label});
  }

  locateNodes(){
    const { nameField } = this.getProperties();
    this.cleanProcessor();

    this._singles = [];

    const { nodes } = this.mindmap.getData();

    if (nodes.length == 0) return;

    // Defines default center if none is set
    if (this.getProperties().centerNode == null) this.setCenterLabel(nodes[0][nameField]);

    // Locate Center First
    var { centerNode } = this.getProperties();

    var node = this.mindmap.getNodeInfoByLabel(centerNode);
    var nodesBeingLocated = this.locateNode(node);
    this.placeNodes(nodesBeingLocated);

    for (var i=0; i < nodes.length; i++){
      var node = nodes[i];
      if (node['isPlaced'] === true) continue;
      var nodesBeingLocated = this.locateNode(node);
      this.placeNodes(nodesBeingLocated);
    }
    var nodesBeingLocated = this.locateSingles();
    this.placeNodes(nodesBeingLocated, true);
    this.generateBorders();
  }

  locateNodesAround(nodeId){
    const { nameField } = this.getProperties();
    this.cleanProcessor();
    const centralNode = this.mindmap.getNodeInfoById(nodeId);
    if (centralNode != null) this.setCenterLabel(centralNode[nameField]);
    var nodesBeingLocated = this.locateNode(centralNode);
    this.placeNodes(nodesBeingLocated);
    this.locateNodes();
  }

  placeNodes(nodesBeingLocated, forceVerticalStack=false){
    if (nodesBeingLocated.length === 0) return;  // Node without surrounding children is located along with other singles
    var A = this.buildMatrixFromNodes(this._locatedNodes);
    var B = this.buildMatrixFromNodes(nodesBeingLocated);

    this._clusters.push(nodesBeingLocated);

    const A_rows = A.length;
    if (A_rows !== 0){
      const A_cols = A[0].length;
      
      if (A_rows < A_cols || forceVerticalStack === true){
        var {xPad, yPad} = this.getMaxVerticalOverlap(A,B);
        yPad += 2;
        if (forceVerticalStack === true) yPad += 2;
      } else {
        var xPad = A_cols + 2;
        var yPad = 0;
      }
      this._padNodes(nodesBeingLocated, xPad, yPad);
    }
    this._locatedNodes = this._locatedNodes.concat(nodesBeingLocated);
  }

  locateNode(node){
    if (node == null) console.log('WARNING: LocateNode() received a null node');
    const neighbors = this.mindmap.getNeighborsByLabel(node.label);
    const numberOfNeighbors = neighbors.filter(id => this.mindmap.getNodeInfoById(id)['isPlaced'] === false).length;
    if (numberOfNeighbors === 0){
      this._singles.push(node);
      return [];
    }

    const c = Math.ceil(this._numberOfPendingNodes/2);
    const cx = c;
    const cy = c;
    const { id } = node;

    node.x = cx;
    node.y = cy;
    node.isPlaced = true;
    this._numberOfPendingNodes--;

    var nodesBeingLocated = [ node ];

    // Creates Matrix and locates Node in the center
    var rows = cy * 2 + 1,
        cols = cx * 2 + 1,
        matrix = Array(rows).fill(null).map(() => Array(cols).fill(-1));
    matrix[cy][cx] = id;

    this.locateChildren([node], nodesBeingLocated, matrix);
    this.centerNodes(nodesBeingLocated);
    return nodesBeingLocated;
  }

  locateChildren(nodes, nodesBeingLocated, matrix){
    var nodesForNextIteration = [];
    nodes.forEach(node => {
      const {id} = node;
      const children = this.mindmap.getNeighborsById(id).filter(id => this.mindmap.getNodeInfoById(id)['isPlaced'] === false);
      const center = [node.x,node.y];
      const validCoordinates = this.processor.getValidNeighborsCoordinates(center, matrix);
      const nChildrensToPlace = Math.min(children.length , validCoordinates.length);
      for (var i=0; i < nChildrensToPlace; i++){
        const x = validCoordinates[i][0];
        const y = validCoordinates[i][1];
        const childId = children[i];
        const child = this.mindmap.getNodeInfoById(childId);
        child.x = x;
        child.y = y;
        child['isPlaced'] = true;
        nodesBeingLocated.push(child);
        matrix[y][x] = childId;
        nodesForNextIteration.push(child);
      }
    });

    if (nodesForNextIteration.length > 0){
      this.locateChildren(nodesForNextIteration, nodesBeingLocated, matrix);
    }
  }

  matricesAreOverlapping(A,B,bx,by, emptyValue=-1){
    const A_rows = A.length;
    if (A_rows == 0) return false;
    if (by >= A_rows) return false;
    const A_cols = A[0].length;
    if (A_cols == 0) return false;
    if (bx >= A_cols) return false;
    const B_rows = B.length;
    if (B_rows == 0) return false;
    const B_cols = B[0].length;
    if (B_cols == 0) return false;
  
    const x0 = bx;
    const x1 = Math.min(A_cols, bx + B_cols);
    const y0 = by;
    const y1 = Math.min(A_rows, by + B_rows);
  
    var B_x = 0;
    for (var A_x=x0; A_x<x1; A_x++){
      var B_y = 0;
      for (var A_y=y0; A_y<y1; A_y++){
        var A_i = A[A_y][A_x];
        var B_i = B[B_y][B_x];
        //console.log(`A[${A_y}][${A_x}] = ${A_i}`)
        //console.log(`B[${B_y}][${B_x}] = ${B_i}`)
        if (A_i != emptyValue && B_i != emptyValue) return true;
        B_y++;
      }
      B_x++;
    }
    return false;
  }
  
  getMaxVerticalOverlapAux(A,B,x_i){
    const A_rows = A.length;
    var xPad = x_i;
    var yPad = A_rows;
    var y0 = A_rows - 1;
    var decrease = 1;
    if (this.mapType === 'hexagon'){
      if (y0 % 2 != 0) y0--;
      decrease = 2;
    }
    for(var y_i = y0; y_i >= 0; y_i-=decrease){
      if (this.matricesAreOverlapping(A,B,x_i,y_i)) break;
      yPad = y_i;
    }
    return {xPad, yPad};
  }
  
  getMaxVerticalOverlap(A,B){
    const A_rows = A.length;
    if (A_rows == 0) throw new Error('A matrix is empty');
    const A_cols = A[0].length;
    var xPadMin = 0;
    var yPadMin = A_rows;
    for(var x_i = 0; x_i < A_cols; x_i++){
      const {xPad, yPad} = this.getMaxVerticalOverlapAux(A,B,x_i);
      if (yPad < yPadMin){
        yPadMin = yPad;
        xPadMin = xPad;
      }
  
    }
    return {xPad:xPadMin , yPad:yPadMin};
  }

  centerNodes(nodes){
    var dx = 0, dy = 0;
    if (this.mapType == "square"){
        dx = Math.min.apply(Math, nodes.map(function(o) { return o.x; }))
        dy = Math.min.apply(Math, nodes.map(function(o) { return o.y; }))
    } else if (this.mapType == "hexagon"){
        dx = Math.min.apply(Math, nodes.map(function(o) { return o.x; }))
        dy = Math.min.apply(Math, nodes.map(function(o) { return o.y; }))
        if (dy % 2 != 0){
            dy = dy-1;
        }
    }
    return nodes.forEach(function(node) { node.y -= dy; node.x -= dx; });
  }

  buildMatrixFromNodes(nodes){
    if (nodes.length === 0) return [];
    var cols = Math.max.apply(null, nodes.map(function(node) { return node.x; })) + 1; 
    var rows = Math.max.apply(null, nodes.map(function(node) { return node.y; })) + 1;
    var matrix = Array(rows).fill(null).map(() => Array(cols).fill(-1));
    for(var i=0; i < nodes.length; i++){
        var node = nodes[i],
            x = node.x,
            y = node.y,
            id = node.id;
        matrix[y][x] = id;
    }
    return matrix;
  }

  generateBorders(){
    const nodes = this._locatedNodes;
    const matrix = this.buildMatrixFromNodes(nodes);
    for(var i=0; i < nodes.length; i++){
      var currentNode = nodes[i]
      var {x,y,id} = currentNode;
      var surrounding = this.processor.getSurroundingIds([x,y],matrix)
      var surroundingLabels = Object.keys(surrounding);
      var neighbors = this.mindmap.getNeighborsById(id);
      for (var j=0; j < surroundingLabels.length; j++){
        var side = surroundingLabels[j]
        var value = surrounding[side]
        if (!neighbors.includes(value)){
            currentNode[side] = true;
        }
      }
    }
  }

  locateSinglesSquare(singles, maxCols){
    const nSingles = singles.length;
    var x = 0;
    var y = 0;
    for (var i = 0; i < nSingles; i++){
      var node = singles[i];
      node.x = x;
      node.y = y;
      x += 2;
      if (x > maxCols){
        y+=2;
        x=0;
      }
    }
    return singles;
  }
  
  locateSinglesHexagon(singles, maxCols){
    const nSingles = singles.length;
    var x = 0;
    var y = 0;
    for (var i = 0; i < nSingles; i++){
      var node = singles[i];
      node.x = x;
      node.y = y;
      x += 3;
      if (x > maxCols){
        y++;
        x = (y%2 === 0)?0:1;
      }
    }
    return singles;
  }

  locateSingles(){
    const singles = this._singles;
    const maxCols = Math.ceil(Math.sqrt(singles.length));
    if (this.mapType == 'hexagon') return this.locateSinglesHexagon(singles, maxCols);
    if (this.mapType == 'square') return this.locateSinglesSquare(singles, maxCols)
  }

  _padNodes(nodes, xPad, yPad){
    return nodes.forEach(function(node) { node.x += xPad; node.y += yPad; });
  }
}class Visualization{
  constructor(plotId, props){
    this.removeUndefinedKeys(props);
    this.plotId = plotId;
    // Tooltip used to show messages
    this.tooltip = null;
    // Tooltip used to Draw Embedded Visualizations
    this.tooltipChart = null;
    // Tooltip subplot is a reference to the Visualization instance that uses the tooltip svg
    this.tooltipSubPlot = null;
    // Tooltip Chart Props
    this.tooltipChartProps = {};

    // Properties dictionary (Idea is to migrate all the properties here -> most of them are private attributes)
    this.properties = {
      disableSaveButtons: false,
      margin: {top:0, right:0, left:0, bottom:0},
      fontSize: 14,
      fontFamily: 'sans-serif',
      enableAutoResize: false,
      enableZoom: false,
      nameField: 'displayname',
      valueField: 'value',
      initialZoom: 1,
    };

    this._buttons = [];
    this._removedElements = [];
    this._subPlotProperties = {};
    this._initProperties(props);
    this.restartSVG();
  }

  _initProperties(props){
    const {
      width = 100,
      height = 100,
      backgroundColor = '#F9F9F9',
      colors = [],
      enableTooltip = false,
      minWidth = width,
      maxWidth = width,
      scale = 'linear',
      parentHtmlTag = 'div',
      subPlotField = 'subPlot',
      subPlotTypeField = 'plotType',
      actionOnClick = 'recenter',
      uniqueName = null,
      initialPosition = [0,0],
      hideLegend = false,
      
      // In properties dictionary
      nameField, valueField,
      margin, enableZoom,
      fontSize, fontFamily, enableAutoResize, disableSaveButtons,
      initialZoom,
    } = props;

    this._parentHtmlTag = parentHtmlTag;
    this._initialPosition = initialPosition;
    this.setInitialZoom(initialZoom);
    this._currentPosition = initialPosition;
    this._currentZoom = this.properties.initialZoom;
    this._width = width;
    this._height = height;
    this.setColors(colors);
    this.setBackgroundColor(backgroundColor);
    this.enableZoom(enableZoom);
    this._minWidth = minWidth;
    this._maxWidth = maxWidth;
    this.setScale(scale);
    this.hideLegend(hideLegend);
    this._enableTooltip = enableTooltip;
    this._legends = null;
    this._subPlotField = subPlotField;
    this._subPlotTypeField = subPlotTypeField;
    this.setPlotData([])
    this.setActionOnClick(actionOnClick);
    this._uniqueName = uniqueName;

    const propsVerifiedByType = {
      nameField, valueField,
      margin, enableZoom,
      fontSize, fontFamily, enableAutoResize, disableSaveButtons
    };
    
    this.setProperties(propsVerifiedByType);
  }

  setInitialZoom(zoom){
    if (typeof(zoom) !== 'number' || isNaN(zoom) || zoom <= 0) return;
    this.properties.initialZoom = zoom;
  }

  removeUndefinedKeys(props){
    if (props == null) return;
    Object.keys(props).forEach((prop)=>{
      if (props[prop] == undefined) delete props[prop];
    })
  }

  setProperties(props){
    if (props.constructor !== Object) return;
    this.removeUndefinedKeys(props);

    var {
      nameField, valueField,
      margin, enableZoom,
      fontFamily, fontSize, enableAutoResize, disableSaveButtons, initialZoom,
    } = props;

    var width = this._width;
    var height = this._height;
    if (props.hasOwnProperty('width')) width = props['width'];
    if (props.hasOwnProperty('height')) height = props['height'];
    this.setSize(width, height);
    if (props.hasOwnProperty('backgroundColor')) this.setBackgroundColor(props['backgroundColor']);
    if (props.hasOwnProperty('colors')) this.setColors(props['colors']);
    if (props.hasOwnProperty('minWidth')) this._minWidth = props['minWidth'];
    if (props.hasOwnProperty('maxWidth')) this._maxWidth = props['maxWidth'];
    if (props.hasOwnProperty('enableTooltip')) this.enableTooltip(props['enableTooltip']);
    if (props.hasOwnProperty('subPlotField')) this._subPlotField = props['subPlotField'];
    if (props.hasOwnProperty('actionOnClick')) this.setActionOnClick(props['actionOnClick']);
    if (props.hasOwnProperty('uniqueName')) this._uniqueName = props['uniqueName'];
    if (props.hasOwnProperty('scale')) this.setScale(props['scale']);
    if (props.hasOwnProperty('hideLegend')) this.hideLegend(props['hideLegend']);

    // Verified by Type
    this.setMargin(margin);
    if (typeof(fontFamily) == 'string') this.properties.fontFamily = fontFamily;
    if (typeof(fontSize) == 'number') this.properties.fontSize = fontSize;
    if (typeof(disableSaveButtons) == 'boolean') this.properties.disableSaveButtons = disableSaveButtons;
    if (typeof(enableAutoResize) == 'boolean') this.enableAutoResize(enableAutoResize);
    if (typeof(enableZoom)) this.enableZoom(enableZoom);
    if (typeof(nameField) == 'string') this.properties.nameField = nameField;
    if (typeof(valueField) == 'string') this.properties.valueField = valueField;
    this.setInitialZoom(initialZoom);
    
    this._refreshComponents();
  }

  getProperties(){
    var properties = {
      'innerWidth': this._innerWidth,
      'innerHeight': this._innerHeight,
      'width': this._width,
      'height': this._height,
      'backgroundColor': this._backgroundColor,
      'colors': this._colors,
      'enableZoom': this._enableZoom,
      'enableTooltip': this._enableTooltip,
      'minWidth': this._minWidth,
      'maxWidth': this._maxWidth,
      'subPlotField': this._subPlotField,
      'actionOnClick': this._actionOnClick,
      'uniqueName': this._uniqueName,

      'initialPosition': this._initialPosition,
      'currentZoom': this._currentZoom,
      'currentPosition': this._currentPosition,
      'autoResize': this.properties.enableAutoResize,
      'enableAutoResize': this.properties.enableAutoResize,

      // Migrated Properties
      'margin': this.properties.margin,
      'disableSaveButtons': this.properties.disableSaveButtons,
      'fontFamily': this.properties.fontFamily,
      'fontSize': this.properties.fontSize,
      'valueField': this.properties.valueField,
      'nameField': this.properties.nameField,
      'initialZoom': this.properties.initialZoom,
    }
    return properties;
  }

  setPlotData(plotData){
    if (plotData == null) return;
    this._plotData = plotData;
  }

  setSubPlotProperties(props = {}){
    this._subPlotProperties = props;
  }

  getPlotData(){
    return this._plotData
  }

  refresh(){
    const data = this.getPlotData()
    this.plot(data)

    if (this.tooltipChart != null && this.tooltipSubPlot != null){
      const tooltipProps = this.tooltipChartProps;
      this.enableTooltipChart('basiclinechart', tooltipProps);
    }
  }

  plot(data){
    console.log('Plot Method is undefined for ',this.constructor.name);
  }

  hideLegend(hide){
    if (typeof(hide) != 'boolean') return;
    this._hideLegend = hide;
  }

  setLegend(legends){
    this._legends = legends;
    this._initLegend();
  }

  /**
   * Creates a Legend for each group
   * @param legends A list of Strings that contains the label of each group
   */
  _initLegend(){
    const legends = this._legends;
    const width = this._width;
    if (legends == null || Array.isArray(legends)) return;
    const groups = Object.keys(this._groupToColorIndex);
    const thisPlot = this;
    
    const {outerSVG} = this.getComponents();
    const { fontFamily, fontSize } = this.getProperties();
    var legendFontSize = fontSize + 2;

    const legendSquareSize = legendFontSize + 5;
    const spaceBetweenLegends = 2;

    const legendContainer = outerSVG.selectAll('g.legend').data([null]).enter().append('g').attr('class','legend');

    if (this._hideLegend === true){
      legendContainer.style('display','none');
    } else {
      legendContainer.style('display','unset');
    }

    const legendBackground = legendContainer.selectAll('g.legend-background')
    .data([null]).enter()
    .append('g')
    .attr('class','legend-background');

    var legend = function(svg){
    const g = svg
      .attr('transform', `translate(${width},0)`)
      .style('text-anchor', 'end')
      .selectAll('g.legendTitle')
      .data(groups)
      .join('g')
      .attr('class','legendTitle')
      .attr('transform', (d, i) => `translate(0,${i * (legendSquareSize + spaceBetweenLegends)})`);

    g.append('rect')
      .attr('x', -legendSquareSize)
      .attr('width', legendSquareSize)
      .attr('height', legendSquareSize)
      .attr('fill', function(d){return thisPlot.getColor(d)});
      
    g.append('text')
      .attr('x', -(legendSquareSize + 5))
      .style('font-size', legendFontSize + 'px')
      .style('font-family', fontFamily)
      .attr('y', 9.5)
      .attr('dy', '0.35em')
      .text(function(l){return legends[l]})
      .attr('fill', 'black');
    }

    legendContainer.call(legend);

    // Calculate location of the background
    const textNodes = legendContainer.selectAll('text').nodes();
    var longestWidth = 0;
    for(var i=0; i < textNodes.length; i++){
      const node = textNodes[i];
      const nodeWidth = node.getBoundingClientRect().width;
      if (nodeWidth > longestWidth) longestWidth = nodeWidth;
    }

    const legendWidth = longestWidth + legendSquareSize + 20;
    const legendHeight = (legendSquareSize + spaceBetweenLegends)*groups.length;

    // Add background
    legendBackground
    .attr('transform',`translate(-${legendWidth},0)`)
    .append('rect')
    .attr('fill','#e0e0e0e0')
    .attr('height',legendHeight)
    .attr('width',legendWidth);

  }

  enableTooltip(enable){
    const oldValue = this._enableTooltip;
    if (enable === true || enable === false){
      this._enableTooltip = enable;
      if (oldValue != enable){
        if (enable){
          this._createTooltip();
        } else {
          this.tooltip.cleanSVG();
          this.tooltip.getComponents().div.remove();
          this.tooltip = null;
        }
      }
    }
  }

  enableZoom(enable){
    if (enable === true || enable === false){
      this._enableZoom = enable;
      this._refreshComponents();
    }
  }

  enableAutoResize(enable){
    if (enable === true || enable === false){
      this.properties.enableAutoResize = enable;
    }
    this._refreshComponents();
  }

  setActionOnClick(action){
    if (action == null) return;
    if (typeof(action) != 'string') return;
    action = action.toLowerCase();
    this._actionOnClick = action;
  }

  enableTooltipChart(plotType, props={}){
    if (plotType == null) return;
    if (typeof(plotType) != "string") return;
    var {outerSVG} = this.getComponents();
    const tooltipId = this.plotId + '_tooltip_chart';
    const tooltipSVG = outerSVG.selectAll('svg#' + tooltipId).data([null]).enter()
      .append('svg')
      .attr('class','tooltipChart')
      .attr('id', tooltipId);

    this.tooltipChartProps = props;

    const {
      padding = 20
    } = props;

    const tooltip = new wordplot.Tooltip(tooltipId, props);
    this.tooltipChart = tooltip;
    const tooltipWidth = this._width - (2 * padding);
    const tooltipHeight = this._height - (2 * padding);
    tooltip.setSize(tooltipWidth,tooltipHeight);
    tooltip.hide();

    const funcAfterHide = () => {
      if (this.tooltipChart == null) return;
      const {innerSVG} = this.getComponents();
      innerSVG.style('opacity', 1)
    }

    const funcAfterShow = () => {
      if (this.tooltipChart == null) return;
      const {innerSVG} = this.getComponents();
      innerSVG.style('opacity', 0.2)
    }

    tooltip.executeFunctionAfterHide(funcAfterHide);

    tooltip.executeFunctionAfterShowing(funcAfterShow);

    // It's important to specify that the chart will be contained in a SVG, not in a DIV
    props['parentHtmlTag'] = 'svg';

    switch (plotType) {
      case "basiclinechart":
        props['parentHtmlTag'] = 'svg';
        const tooltipId = this.plotId + '_tooltip_chart';
        this.tooltipSubPlot = new wordplot.line.BasicLineChart(tooltipId, props);
        break;
      default:
        console.log(`TooltipChart is not implemented for plotType = ${plotType}`);
        break;
    }
  }

  _showTooltipChart(){
    const tooltip = this.tooltipChart;
    if (this.tooltipChart == null) return;
    this.tooltipChart.show();
    const {innerSVG} = this.getComponents();
    innerSVG.style('opacity', 0.2);
    const tooltipSVG = tooltip.getComponents().outerSVG;
    tooltipSVG.style('opacity', 1);
  }

  _hideTooltipChart(){
    if (this.tooltipChart == null) return;
    this.tooltipChart.hide();
    const {innerSVG} = this.getComponents();
    innerSVG.style('opacity', 1)
  }

  getColor(group){
    if (group >= this._colors.length){
      while(this._colors.length <= group){
        var color = Math.floor(Math.random() * 16777216).toString(16);
        color = '#000000'.slice(0, - color.length) + color;
        this._colors.push(color);
      }
    }
    return this._colors[group];
  }

  setScale(scale){
    switch (scale) {
      case 'log':
      case 'linear':
      case 'sqrt':
      case 'pow':
      case 'flat':
        this._scale = scale;
        break;
      default:
        console.log('Error executing setColorScale: Valid Scales [ log, linear, sqrt, pow, flat ]');
        break;
    }
  }

  getScale(domain, range){
    switch(this._scale){
      case 'log':
        var scale = d3.scaleSymlog();
        break;
      case 'flat':
      case 'linear':
        var scale = d3.scaleLinear();
        break;
      case 'sqrt':
        var scale = d3.scaleSqrt();
        break;
      case 'pow':
        var scale = d3.scalePow();
        break;
    }
    scale.domain(domain).nice()
    .range(range);
    return scale;
  }

  _scaleValue(value){
    switch(this._scale){
      case 'log':
        value = Math.log(value);
        return Math.max(value, 0.001)
      case 'linear':
        return value;
      case 'sqrt':
        return Math.sqrt(value);
      case 'pow':
        return Math.pow(value,2);
      case 'flat':
        return 1;
      default:
        console.log('Invalid Color scale -> ', this._scale);
        return value;
    }
  }
      
  setColors(colors){
    if (Array.isArray(colors)){
      this._colors = colors;
    } else {
      console.log('Error executing setColors(colors), colors must be an Array of strings')
    }
  }

  setSize(width, height){
    if (width > 0) this._width = width;
    if (height > 0) this._height = height;
    this._recalculateInnerSize();
  }

  cleanSVG(){
    const {outerSVG} = this.getComponents();
    outerSVG.remove();
  }

  restartSVG(){
    this.cleanSVG();
    this._initContainer();
  }

  setMargin(margin){
    if (margin == null) return;
    if (margin.constructor !== Object) return;

    var m = this.properties.margin;
    if (margin.hasOwnProperty('top')) m.top = margin.top;
    if (margin.hasOwnProperty('bottom')) m.bottom = margin.bottom;
    if (margin.hasOwnProperty('left')) m.left = margin.left;
    if (margin.hasOwnProperty('right')) m.right = margin.right;
    this._recalculateInnerSize();
  }

  _recalculateInnerSize(){
      var {margin, width, height} = this.getProperties();
      this._innerWidth = width - margin.left - margin.right;
      this._innerHeight = height - margin.top - margin.bottom;
      this._refreshComponents();
  }

  _setTextProperties(text){
      var {fontSize, fontFamily} = this.getProperties();
      text
      .style('font-size', fontSize + 'px')
      .style('font-family', fontFamily);
  }

  setBackgroundColor(color){
    this._backgroundColor = color;
    const {background} = this.getComponents();
    background.attr('fill', this._backgroundColor);
  }

  getComponents(){
    var div = d3.select( this._parentHtmlTag + '#' + this.plotId);
    
    const titleId = this.plotId + "_title";
    var titleSVG = div.select('svg#' + titleId);
    
    var outerSVG = div.select('svg.outerSVG');
    var innerSVG = outerSVG.select('svg.innerSVG');
    var background = innerSVG.select('rect.background');
    var container = innerSVG.select('g.container');
    return {div, outerSVG, innerSVG, container, background, titleSVG};
  }

  _initContainer(){
    var div = d3.select( this._parentHtmlTag + '#' + this.plotId);
    if (div.empty()){
      console.log('Could not find a ' + this._parentHtmlTag + ' tag with id=' + this.plotId);
      return;
    }

    const {
      width, fontFamily, backgroundColor,
    } = this.getProperties();

    const TITLE_FONT_SIZE = '24px';
    const TITLE_HEIGHT = '30px';

    const titleId = this.plotId + "_title";
    const titleSVG = div.selectAll("svg#"+titleId)
    .data([null]).enter()
    .append("svg")
    .attr("id", titleId)
    .style('width', width)
    .style('height', TITLE_HEIGHT)
    .style('display','none');

    titleSVG.selectAll('text').data([null]).enter()
    .append('text')
    .attr('y',TITLE_FONT_SIZE)
    .attr('x','50%')
    .style('font-family', fontFamily)
    .style('font-size',TITLE_FONT_SIZE)
    .style('text-anchor','middle')
    .style('text-transform', 'capitalize');

    var expansorSVG = div.selectAll('svg.expansor').data([null]).enter().append('svg').attr('class','expansor')
    expansorSVG.style('width','100%').style('height','0px');
    var outerSVG = div.selectAll('svg.outerSVG').data([null]).enter().append('svg').attr('class','outerSVG');
    var innerSVG = outerSVG.selectAll('svg.innerSVG').data([null]).enter().append('svg').attr('class','innerSVG');
    var background = innerSVG.selectAll('rect.background').data([null]).enter().append('rect')
    .attr('class','background')
    .attr('fill',backgroundColor)
    .attr('width','100%').attr('height','100%');
    var container = innerSVG.selectAll('g.container').data([null]).enter().append('g').attr('class','container');

    this._refreshComponents();

    if (this._enableTooltip === true){
      this._createTooltip();
    }

    this._drawButtons();
  }

  _createTooltip(){
    const {container} = this.getComponents();
    const tooltipId = this.plotId + '_tooltip';
    container.selectAll('svg#' + tooltipId).data([null]).enter()
    .append('svg')
    .attr('class','tooltip')
    .attr('id', tooltipId);

    const {
      fontFamily, fontSize
    } = this.getProperties();

    const tooltipProps = {
      fontSize: fontSize,
      fontFamily: fontFamily
    }

    if (this.tooltip === null){
      this.tooltip = new wordplot.Tooltip(tooltipId, tooltipProps);
    } else {
      this.tooltip.restartSVG();
    }

    this.tooltip.hide();
  }

  _refreshComponents(){
    const {div, outerSVG, innerSVG, container, background} = this.getComponents();
    const {
      width, height, innerWidth, innerHeight, margin, backgroundColor,
      initialPosition, initialZoom,
      maxWidth, minWidth, autoResize,
    } = this.getProperties();

    outerSVG.attr('viewBox', `0 0 ${width} ${height}`);

    background.attr('fill', backgroundColor);

    innerSVG
      .attr('width', innerWidth)
      .attr('height', innerHeight)
      .attr('x', margin.left)
      .attr('y', margin.top);

    const thisPlot = this;
    
    const [initialX,initialY] = initialPosition;

    const zoom = d3.zoom();

    if (this._enableZoom){
      innerSVG.call(zoom.on('zoom' , function(event){
        container.attr('transform', event.transform);
        const x = event.transform.x;
        const y = event.transform.y;
        thisPlot._currentZoom = event.transform.k;
        thisPlot._currentPosition = [x,y];
      }));
    } else {
      innerSVG.call(zoom.on('zoom', null));
    }

    innerSVG.call(zoom.transform, d3.zoomIdentity.translate(initialX, initialY).scale(initialZoom));

    div.style('height', height + 'px')
    .style('width', width + 'px');

    if (autoResize === true){
      div.style('max-width', maxWidth)
      .style('min-width', minWidth)
      .style('height', 'unset')
      .style('width', '100%')
    }

    this._initLegend();
  }

  /**
   * 
   * @param {dictionary} buttonInfo 
   * @param {function} onClick 
   */
  addButton(buttonInfo={}, onClick){
    if (buttonInfo == null) var buttonInfo = {};
    var buttonInfo = Object.assign({
      x:0,
      y:0,
      width:100,
      height:30,
      label:'Button',
      backgroundColor: 'gray'
    }, buttonInfo);

    buttonInfo.onClick = onClick;

    const {outerSVG} = this.getComponents();
    this._buttons.push(buttonInfo);
    this._drawButtons();
  }

  _drawButtons(){
    const buttons = this._buttons;
    const { outerSVG } = this.getComponents();
    var button = outerSVG.selectAll('rect.button')
      .data(buttons).enter()
      .append('svg')
      .attr('class','button')
      .attr('width', d => d.width)
      .attr('height', d => d.height)
      .attr('x', d => d.x)
      .attr('y', d => d.y)

    button.append('rect')
      .attr('class', 'buttonBackground')
      .attr('fill', d => d.backgroundColor)
      .attr('width','100%')
      .attr('height','100%')
      .on('click', (event,data) => {data.onClick()});
      
    button.append('text')
      .attr('class','buttonText')
      .text(d => d.label)
      .attr('y', '50%')
      .attr('x','50%')
      .style('text-anchor','middle')
      .style('dominant-baseline','middle')
      .on('click', (event,data) => {data.onClick()});

      button.style('cursor','pointer')
  }

  showEmbeddedVisualizationFromUrl(url, plotType, uniqueName=null){
    d3.json(url).then( json => this.showEmbeddedVisualization(json, plotType, uniqueName))
  }

  showEmbeddedVisualization(json, plotType, uniqueName=null){
    const plotId = this.plotId;
    var subPlot = null;
    var plotType = plotType.toLowerCase();
    var props = this.getProperties();
    props['uniqueName'] = uniqueName;

    // Inherit properties has been disabled
    // These props must be added ONLY if the type of the parent and the child visualization is the same
    /** 
    var filteredProps = {
      margin : props.margin,
      nameField : props.nameField,
      valueField: props.valueField,
    }
    */
    
    // Removing the filtered Props
    delete props.nameField
    delete props.valueField
    delete props.margin
    
    // Removing props that must not be taken into account
    delete props.enableAutoResize
    delete props.enableTooltip
    delete props.enableZoom
    delete props.hideNumber

    // Set custom properties
    props = wordplot.helpers.mergeDictionaries(props, this._subPlotProperties);

    // Build plot depending on the plotType
    switch (plotType) {
      case 'hexagon':
      case 'square':
        var subPlot = wordplot.wordmap.buildWordMap(plotId, json, plotType, props)
        break;
      case 'topbarchart':
      case 'barchart':
      case 'basicbarchart':
      case 'barchartgroup':
        var subPlot = wordplot.bars.buildBarChart(plotId, json, plotType, props)
        break;
      case 'basiclinechart':
        var subPlot = new wordplot.line.BasicLineChart(plotId, props);
        subPlot.plot(json);
        break;
      case 'linechart':
      case 'top20':
      case 'barchart':
      default:
        console.log("ShowEmbeddedVisualization is not implemented for plotType = " + plotType)
        return;
    }
    
    if (subPlot == null) return;
    
    // Inherit properties has been disabled
    // Setup margin as Superplot if their plotTypes are the same
    /**
    if (subPlot.constructor == this.constructor){
      subPlot.setProperties(filteredProps)
    }
    */

    // Setup superplot and back button
    this._setSubPlot(subPlot);
    subPlot._setSuperPlot(this);
    subPlot.refresh();
    const backButtonProperties = { label: '⏎  Back'}
    subPlot.addButton(backButtonProperties, ()=>{
      this._setSubPlot(null);
      subPlot._backToSuperPlot();
    })
  }

  _backToSuperPlot(){
    if (!this.hasOwnProperty('_superPlot')) return;
    var superPlotType = this._superPlot.constructor
    switch (superPlotType) {
      case wordplot.wordmap.HexagonMap:
        this._backToSuperPlotMap()
        break;
      default:
        console.log('BackToSuperPlot is not implemented for Plot Type ' + superPlotType)
        break;
    }
  }

  _backToSuperPlotMap(){
    if (!this.hasOwnProperty('_superPlot')) return;
    const superPlot = this._superPlot;
    const data = superPlot.graphToMap.getLocatedNodes()
    superPlot.plot(data)
  }

  _setSubPlot(subPlot){
    this._subPlot = subPlot;
  }

  _setSuperPlot(superPlot){
    this._superPlot = superPlot;
  }

  setNameField(nameField){
    this.properties.nameField = nameField;
  }

  setValueField(valueField){
    this.properties.valueField = valueField;
  }

  getStorableComponent(){
    var { outerSVG } = this.getComponents();
    return outerSVG;
  }

  showMessage(message){
    this.cleanSVG()
    const {div} = this.getComponents()
    div.append("text")
    .text(message)
    .attr('id','loadingText')
    .attr("x",0)
    .attr("y",30)
  }

  removeElementByName(elementName){
    const data = this.getPlotData();
    const { nameField } = this.getProperties();
    const index = data.findIndex(d => d[nameField] == elementName);
    
    if (index < 0){
      console.log('removeElementByName() : Element not found');
      return;
    }

    const removedElement = data.splice(index,1)[0];
    this._removedElements.push(removedElement);
    this.refresh();
  }


  setTitle(title){
    if (title == null || typeof(title) != "string") return;

    title = title.replace(/_/g,' ');

    this._title = title;
    this._showTitle();
  }

  _showTitle(){
    const title = this._title;
    const {titleSVG} = this.getComponents();
    titleSVG.style('display','unset');
    titleSVG.select('text').text(title);
  }

}

class AxisVisualization extends Visualization{
  constructor(plotId, props={}){
    super(plotId, props);
  }

  addAxis(axis, position){
    const {margin, innerHeight} = this.getProperties();

    const { outerSVG } = this.getComponents();
    switch (position) {
      case 'bottom':
        var gAxis = outerSVG.selectAll('g.axisBottom').data([null]).enter()
          .append('g')
          .attr('class','axisBottom')
          .attr('transform', `translate(${margin.left},${innerHeight + margin.top})`)
          .call(axis);
        var axisText = gAxis.selectAll('text')
        this._setTextProperties( axisText );
        break;
      case 'left':
        var gAxis = outerSVG.selectAll('g.axisLeft').data([null]).enter()
          .append('g')
          .attr('class','axisLeft')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .call(axis);
        var axisText = gAxis.selectAll('text')
        this._setTextProperties( axisText );
        break;
      case 'right':
      case 'top':
        console.log(`Error executing addAxis(axis,position) Position ${position} not implemented yet`);
        return;
      default:
        console.log('Error executing addAxis(axis,position) Supported Positions: [bottom, left]');
        return;
    }

    return gAxis;
  }

  wrapAxis(position, length){
    const {outerSVG} = this.getComponents();
    var axisId = '';
    switch (position) {
      case 'left':
        axisId = 'axisLeft';
        break;
      case 'bottom':
        axisId = 'axisBottom';
        break;
      default:
        console.log('Error executing addAxis(axis,position) Supported Positions: [bottom, left]');
        break;
    }
    const gAxis = outerSVG.selectAll(`g.${axisId}`);
    const axisText = gAxis.selectAll('text');
    axisText.call(wordplot.helpers.wrapLines, length);
  }
}

function buildVisualization(plotId, json, plotType, props={}){
  plotType = plotType.toLowerCase();
  switch (plotType) {
    case 'hexagon':
    case 'square':
      const dataFormat = wordplot.json.detectDataType(json);
      if (dataFormat == 'mapseries')
        return wordplot.wordmap.buildMapSeries(plotId, json, plotType, props);
      else
        return wordplot.wordmap.buildWordMap(plotId, json, plotType, props);
    case 'barchart':
    case 'barchartgroup':
    case 'basicbarchart':
    case 'topbarchart':
      return wordplot.bars.buildBarChart(plotId, json, plotType, props);
    case 'linechart':
    case 'linechartgroup':
      return wordplot.line.buildLineChart(plotId, json, plotType, props);
    case 'top20':
      return wordplot.top.buildTopChart(plotId, json, props);
    default:
      console.log('Error at BuildVisualization(): Invalid plotType',plotType)
      break;
  }
}var helpers = (function(){

function getSplittedText(text, width, textObject) {
  const splittedText = [];
  var originalWords = text.split(/\s+/).reverse();
  var words = [];
  originalWords.forEach(d => words.push(d.charAt(0).toUpperCase() + d.slice(1)));
  var word = null;
  var line = [];
  var tspan = textObject.append("tspan")
      .attr("dy", 0 + "em")
      .attr('id','_TEMPORAL');
  while (word = words.pop()) {
    line.push(word);
    tspan.text(line.join(" "));
    if (tspan.node().getComputedTextLength() > width){
      if (line.length > 1){
        line.pop();
        splittedText.push(line.join(' '));
        line = [word];
        tspan.text('');
      }
      if (tspan.node().getComputedTextLength() > width && line.length === 1) {   // Only one word
        var length = word.length;
        var i = length - 1;
        line.pop();
        do {
          var subWord = line.join(' ') + ' ' + word.substring(0, i) + '-';
          var remain = word.substring(i, length);
          tspan.text(subWord);
          i--;
        } while( tspan.node().getComputedTextLength() > width && i > 0);
        words.push(remain);
        splittedText.push(subWord);
        line = [];
        tspan.text('');
      }
    }
  }
  if (line.length > 0){
    splittedText.push(line.join(' '));
  }
  textObject.select('tspan#_TEMPORAL').remove();
  return splittedText;
}

function wrapLines(text, width) {
  text.each(function() {
    var text = d3.select(this);
    var originalWords = text.text().split(/\s+/).reverse();
    var words = [];
    originalWords.forEach(d => words.push(d.charAt(0).toUpperCase() + d.slice(1)));
    var word = null;
    var line = [];
    var lineHeight = 1.1; // ems
    //var y = text.attr("0%");
    var x = text.attr("x");
    if (x == null) x = '0px';
    var tspan = text.text(null).append("tspan")
        .attr("x", x)
        //.attr("y", y)
        .attr("dy", 0 + "em");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width){
        if (line.length > 1){
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan")
          .attr("x", x)
          //.attr("y", y)
          .attr("dy", lineHeight + "em").text(word);
        }
        if (tspan.node().getComputedTextLength() > width && line.length === 1) {   // Only one word
          var length = word.length;
          var i = length - 1;
          line.pop();
          do {
            var subWord = line.join(' ') + ' ' + word.substring(0, i) + '-';
            var remain = word.substring(i, length);
            tspan.text(subWord);
            i--;
          } while( tspan.node().getComputedTextLength() > width && i > 0);
          words.push(remain);
          line = [];
          tspan = text.append("tspan")
          .attr("x", x)
          //.attr("y", y)
          .attr("dy", lineHeight + "em").text('');
        }
      }
    }
  });
}

function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }
  if (hex.length != 6) {
    console.log("Invalid Hex Color:",hex)
      //throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
  return (r * 0.299 + g * 0.587 + b * 0.114) > 170
    ? '#000000'
    : '#FFFFFF';
}

function increaseBrightness(hex, percent){
  if (percent === 100){
    percent = 99 
  }
  
  hex = hex.replace(/^\s*#|\s*$/g, '');

  var r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);

  var nr = ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1),
      ng = ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1),
      nb = ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  
  var newHex = "#" + nr + ng + nb;
  return newHex
}

function sortArrayOfObjects(array, fieldName){
  if (array.length === 0) return array;
  var sorted = array.sort((a,b) =>  b[fieldName]-a[fieldName]);
  return sorted;
}

/**
 * Inserts splitted textContent inside textObject based on maxWidth
 * @param {d3 Text Selection} textObject 
 * @param {String} textContent 
 * @param {Number} fontSize 
 * @param {Number} maxWidth
 */
function readjustFontSize(textObject, textContent, fontSize, maxWidth){
  textObject.style('font-size', fontSize + 'px')
      .html(textContent);
  textObject.call(wordplot.helpers.wrapLines, maxWidth);
}

function mergeDictionaries(baseDict, newValues){
  var merge = {};
  Object.assign(merge, baseDict);
  Object.assign(merge, newValues);
  return merge;
}
return {
  increaseBrightness,
  invertColor,
  wrapLines,
  sortArrayOfObjects,
  getSplittedText,
  readjustFontSize,
  mergeDictionaries
}})();class TopBarChartGroup{
	constructor(plotId, json, props={}){
        const {
            elemsPerGroup = 20
        } = props;
        this.elemsPerGroup = elemsPerGroup;
        this.barChart = new wordplot.bars.TopBarChart(plotId, elemsPerGroup, props);
        this.plotId = plotId;
        this.data = json.data;
        this.cleanSVG();
        this.createDropDownMenu(this.data);
        this.refreshGraphic();
    }
    
    setColors(colors){
        this.barChart.setColors(colors);
    }

    getComponents(){
        return this.barChart.getComponents();
    }

    getStorableComponent(){
        if (this.barChart == null) return null;
        return this.barChart.getStorableComponent();
    }

    setProperties(props){
        this.barChart.setProperties(props);
        this.refreshGraphic();
    }

    setMargin(margin){
        this.barChart.setMargin(margin);
    }

    updateGroups(){
        var nElements = this.data.length
        var nGroups = Math.ceil(nElements / this.elemsPerGroup);
        var groupList = document.getElementById('groupList');
        for (var i=0; i<nGroups; i++){
            var start = 1 + i * this.elemsPerGroup;
            var end = (i + 1) * this.elemsPerGroup;
            var end = Math.min(end, nElements);
            var groupName = 'Top ' + start + '-' + end;
            var groupValue = i;  // Index of the group
            var group = document.createElement('option');
            group.value = groupValue;
            group.text = groupName;
            groupList.appendChild(group);
        }
    }
    
    createDropDownMenu(){
        var This = this;
        const { fontFamily, fontSize } = this.barChart.getProperties();
        const {div} = this.barChart.getComponents()
        div.insert('br',':first-child').attr('id','groupList');
        div.insert('select',':first-child')
            .attr('id','groupList')
            .style('font-family',fontFamily)
            .style('font-size',fontSize)
            .on('change', function(d){This.refreshGraphic()});
        this.updateGroups();
    }

    refreshGraphic(){
        var i = Number(document.getElementById('groupList').value);
        var start = i * this.elemsPerGroup;
        var end = (i + 1) * this.elemsPerGroup;
        var elems = this.data.slice(start, end);
        this.barChart.plot(elems);
    }

    refresh(){
        this.refreshGraphic()
    }
    
    cleanSVG(){
        d3.selectAll('#groupList').remove();
        this.barChart.cleanSVG();
    }
}var bars = ( function(){

class BasicBarChart extends AxisVisualization{
  constructor(plotId, props={}){
    const DEFAULT_BASICBARCHART_PROPS = {
      width: 900,
      height: 900,
      padding: 0.2,
      orientation: 'horizontal',
      margin: {left:200,right:10},
      actionOnClick: null
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_BASICBARCHART_PROPS, props);
    super(plotId, props);
    this._initProperties(props);
  }

  _initProperties(props){
    super._initProperties(props);
    const {
      orientation,
      padding
    } = props;
    this.setOrientation(orientation);
    this.setPadding(padding);
    super._initProperties(props)
  }

  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('orientation')) this.setOrientation(props['orientation']);
    if (props.hasOwnProperty('padding')) this.setPadding(props['padding']);
  }

  setOrientation(orientation){
    this.orientation = orientation;
    switch (orientation){
      case 'vertical':
        this.plot = function(){console.log('plot() function not implemented yet for vertical BasicBarChart')};
        break;
      case 'horizontal':
        this.plot = this.horizontalPlot;
        break;
      default:
        this.plot = function(){console.log('Error: Valid Orientations [ vertical, horizontal ]')};
        break;
    }
  }

  setPadding(p){
    if (p >= 0 && p < 1) this._padding = p;
  }

  horizontalPlot(data){
    this.setPlotData(data);
    this.restartSVG();
    var thisPlot = this;

    var { 
      margin, innerWidth, innerHeight, nameField, valueField,
    } = this.getProperties();
    
    const { outerSVG, container } = this.getComponents();
    var svg = container;
            
    var y = d3.scaleBand()
    .domain(data.map(function (d){return d[nameField]}))
    .range([0, innerHeight])
    .padding(thisPlot._padding)

    var xDomain = [0, d3.max(data, d => Math.max(d[valueField]))];
    var xRange = [0, innerWidth - 30];
    var x = this.getScale(xDomain, xRange);

    var onClickFunction = () => {}
    switch (this._actionOnClick) {
      case 'remove':
        onClickFunction = (action,data) => {this.removeElementByName(data[nameField])}
        break;
    }

    svg.selectAll('rect').data(data).enter().append('rect')
      .attr('id','bar')
      .attr('concept',function(d){return d[nameField]})
      .attr('x', function() { return 0 })
      .attr('y', function(d,j) { return y(d[nameField]) })
      .attr('width', function(d) { return x(d[valueField]) })
      .attr('height', function(){return y.bandwidth()})
      .attr('fill',function(d,j){return thisPlot.getColor(j)})
      .on('click', onClickFunction)
      .on('mouseover', function(e,d){
        var thisConcept = d[nameField];
        d3.selectAll('rect#bar')
        .attr('opacity', function(f){
          return (f[nameField] == thisConcept)?1:0.1
        })

        d3.selectAll('text#barValue')
        .attr('opacity', function(f){
          return (f[nameField] == thisConcept)?1:0.1
        }).style('font-weight', function(f){
          return (f[nameField] == thisConcept)?'bold':'normal'
        });

        d3.selectAll('text#barName')
        .attr('opacity', function(f){
          return (f == thisConcept)?1:0.1
        }).style('font-weight', function(f){
          return (f == thisConcept)?'bold':'normal'
        });
      })
      .on('mouseout', function(e,d){
        d3.selectAll('rect#bar')
        .attr('opacity',1)
        d3.selectAll('text#barValue')
        .attr('opacity',1)
        .style('font-weight','normal')
        d3.selectAll('text#barName')
        .attr('opacity',1)
        .style('font-weight','normal')
      });

    var barValues = outerSVG.selectAll('text.barValue').data(data).enter().append('text')
        .attr('class','barValue')
        .attr('concept',function(d){return d[nameField]})
        .attr('y', function (d,j){return y(d[nameField]) + y.bandwidth()*0.6 + margin.top})
        .attr('x', function (d){return x(Math.max(d[valueField],0)) + 3 + margin.left})
        .text(function (d){return d[valueField];})
        .style('fill',function(d,j){return 'black'});
    this._setTextProperties(barValues);

    var yAxis = d3.axisLeft(y).tickSizeInner(10).tickSizeOuter(0);
    var gAxisY = this.addAxis(yAxis, 'left');
    this.wrapAxis('left', margin.left - 13 - 3);
  }
}

class BarChart extends AxisVisualization{
  constructor(plotId, props={}){
    super(plotId, props);
    const {
      orientation = 'horizontal',
      padding = 0.2,
      valuesField = 'demand',
      width = 600,
      height = 600
    } = props;
    this._orientation = orientation;
    this.setPadding(padding);
    this.setValuesField(valuesField);
    this.setSize(width, height);
    this.enableZoom(false);

    switch (orientation){
      case 'vertical':
        this.setMargin({top: 0, right: 10, bottom: 40, left: 0});
        this.plot = this.verticalPlot;
        break;
      case 'horizontal':
        this.setMargin({top: 0, right: 50, bottom: 10, left: 100});
        this.plot = this.horizontalPlot
        break;
      default:
        this.plot = function(){console.log('Error executing BarChart.plot(): Valid Orientations [ vertical, horizontal ]')};
        break;
    }
  }

  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('padding')) this.setPadding(props['padding'])
    if (props.hasOwnProperty('valuesField')) this.setValuesField(props['valuesField'])
  }

  setPadding(p){
    if (p >= 0 && p < 1) this._padding = p;
  }

  setValuesField(vf){
    this._valuesField = vf;
  }

  verticalPlot(data, legends=[]){
    this.setPlotData(data);
    this.restartSVG();
    if (data.length == 0)return;
    const thisPlot = this;
    const valuesField = this._valuesField;

    var { 
      margin, width, height, innerWidth, innerHeight,
      fontFamily, fontSize, nameField
    } = this.getProperties();

    const {outerSVG, container} = this.getComponents();
    const svg = container;

    var x0 = d3.scaleBand()
    .rangeRound([0,innerWidth])
    .domain(data.map(function (d){return d[nameField]}))
    .padding(thisPlot._padding);

    // Values
    var x1 = d3.scaleBand()
    .domain(d3.range(data[0][valuesField].length))
    .range([0, x0.bandwidth()])
  
    var yDomain = [ 0, d3.max(data, d => Math.max(Math.max.apply(null, d[valuesField])))];
    var yRange =  [ innerHeight, 30 ];
    var y = this.getScale(yDomain, yRange);
  
    svg.selectAll('g').data(data).enter().append('g')
    .each(function(item){
      var g = d3.select(this);
      g.selectAll('rect')
      .data(item[valuesField])
      .enter().append('rect')
      .attr('x', function(value,j) { return x0(item[nameField]) + x1(j) })
      .attr('y', function(value) { return y(value) })
      .attr('width', function() { return x1.bandwidth() })
      .attr('height', function(value){return Math.abs(y(0) - y(value))})
      .attr('fill',function(d,j){return thisPlot.getColor(j)})
  
      var barLabels = g.selectAll('text.label')
      .data(item[valuesField])
      .enter().append('text').attr('class','label')
      .attr('x', function (value,j){return x0(item[nameField]) + x1(j) + x1.bandwidth()/2})
      .attr('y', function (value){return y(Math.max(value,0)) - 4;})
      .text(function (value){return value;})
      .style('fill','black')
      .style('text-anchor','middle');
      thisPlot._setTextProperties(barLabels);
    });
  
    var xAxis = d3.axisBottom(x0).tickSizeInner(10).tickSizeOuter(0)
    this.addAxis(xAxis, 'bottom');
  
    // Legend
    if (legends == null || legends.length == 0) legends = this._legends;
    var legend = function(svg){
        const g = svg
            .attr('transform', `translate(${width},0)`)
            .style('text-anchor', 'end')
            .style('font-family', fontFamily)
            .style('font-size', fontSize)
          .selectAll('g')
          .data(legends)
          .join('g')
            .attr('transform', (d, i) => `translate(0,${i * 20})`);
      
        g.append('rect')
            .attr('x', -19)
            .attr('width', 19)
            .attr('height', 19)
            .attr('fill', function(d,i){return thisPlot.getColor(i)});
      
        g.append('text')
            .attr('x', -24)
            .attr('y', 9.5)
            .attr('dy', '0.35em')
            .text(function(l){return l});
      }
  
      outerSVG.append('g').call(legend);
  }

  horizontalPlot(data, legends){
    this.setPlotData(data);
    this.restartSVG();
    if (data.length == 0)return;
    const thisPlot = this;
    const valuesField = this._valuesField;
    
    var { 
      margin, width, height, innerWidth, innerHeight,
      fontFamily, fontSize, nameField,
    } = this.getProperties();
    
    const {outerSVG, container} = this.getComponents();
    const svg = container;

    const y0 = d3.scaleBand()
              .rangeRound([0,innerHeight])
              .domain(data.map(function (d){return d[nameField]}))
              .padding(thisPlot._padding);

    // Values
    const y1 = d3.scaleBand()
    .domain(d3.range(data[0][valuesField].length))
    .range([0, y0.bandwidth()])

    var xDomain = [0, d3.max(data, d => Math.max(Math.max.apply(null, d[valuesField])))];
    var xRange = [0, innerWidth - 40];
    var x = this.getScale(xDomain, xRange);

    svg.selectAll('g').data(data).enter().append('g')
    .each(function(item){
      var g = d3.select(this);
      g.selectAll('rect')
      .data(item[valuesField])
      .enter().append('rect')
      .attr('x', function() { return 0 })
      .attr('y', function(value,j) { return y0(item[nameField]) + y1(j) })
      .attr('width', function(value) { return Math.abs(x(value)) })
      .attr('height', function(value,j){return y1.bandwidth()})
      .attr('fill',function(d,j){return thisPlot.getColor(j)})

      var barLabels = g.selectAll('text.label')
      .data(item[valuesField])
      .enter().append('text').attr('class','label')
      .attr('y', function (value,j){return y0(item[nameField]) + y1(j) + y1.bandwidth()*0.7})
      .attr('x', function (value){return x(Math.max(value,0)) + 3;})
      .text(function (value){return value;})
      .style('fill',function(d,j){return 'black'});
      thisPlot._setTextProperties(barLabels);
    });

    var yAxis = d3.axisLeft(y0).tickSizeInner(10).tickSizeOuter(0);

    this.addAxis(yAxis, 'left');
    this.wrapAxis('left', margin.left - 13 - 3);

    // Legend
    if (legends == null || legends.length == 0) legends = this._legends;
    var legend = function(svg){
      const g = svg
          .attr('transform', `translate(${width},0)`)
          .style('text-anchor', 'end')
          .style('font-family', fontFamily)
          .style('font-size', fontSize + 'px')
        .selectAll('g')
        .data(legends)
        .join('g')
          .attr('transform', (d, i) => `translate(0,${i * 20})`);
    
      g.append('rect')
          .attr('x', -19)
          .attr('width', 19)
          .attr('height', 19)
          .attr('fill', function(d,i){return thisPlot.getColor(i)});
    
      g.append('text')
          .attr('x', -24)
          .attr('y', 9.5)
          .attr('dy', '0.35em')
          .text(function(l){return l});
    }
    outerSVG.append('g').call(legend);
  }
}

class TopBarChart extends BasicBarChart{
  constructor(plotId, nTop=20, props={}){
    const DEFAULT_TOPBARCHART_PROPS = {
      valueField: 'relevancy'
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_TOPBARCHART_PROPS, props);
    super(plotId,props);
    this.nTop = nTop
    this._parentPlot = this.plot
    this.plot = this.topChartPlot
  }
 
  // Plot method is override after super() call in constructor
  topChartPlot(data){
    this.setPlotData(data)
    var topData = this.getTopResults(data)
    this._parentPlot(topData)
    this.setPlotData(data)
  }

  getTopResults(data){
    if (data.length == 0) return data;
    var top = this.nTop
    var {valueField} = this.getProperties()
    var sorted = data.sort(function(a,b) {
        return b[valueField] - a[valueField]
    });
    return sorted.slice(0,top)
  }

  setNumberTop(nTop){
    this.nTop = nTop
    this.refresh()
  }

  refresh(){
    const data = this.getPlotData()
    this.plot(data)
  }
}

class BarChartGroup{
	constructor(plotId, data, legend, props={}){
    const {
      elemsPerGroup = 3
    } = props;
		this.elemsPerGroup = elemsPerGroup;
		this.barChart = new BarChart(plotId, props);
		this.plotId = plotId;
    this.data = data;
    this.legend = legend;
    this.cleanSVG();
		this.createDropDownMenu(data);
		this.refreshGraphic();
  }

  getComponents(){
    return this.barChart.getComponents();
  }

  getStorableComponent(){
    if (this.barChart == null) return null;
    return this.barChart.getStorableComponent();
  }

  setProperties(props){
    this.barChart.setProperties(props);
    this.refreshGraphic();
  }

  setMargin(margin){
    this.barChart.setMargin(margin);
  }

	updateGroups(){
	  var nElements = this.data.length
	  var nGroups = Math.ceil(nElements / this.elemsPerGroup);
	  var groupList = document.getElementById('groupList');
	  for (var i=0; i<nGroups; i++){
	  	var start = 1 + i * this.elemsPerGroup;
	  	var end = (i + 1) * this.elemsPerGroup;
	  	var end = Math.min(end, nElements);
	  	var groupName = 'Top ' + start + '-' + end;
	  	var groupValue = i;  // Index of the group
	  	var group = document.createElement('option');
	  	group.value = groupValue;
	  	group.text = groupName;
	  	groupList.appendChild(group);
	  }
	}
  
  createDropDownMenu(){
		const This = this;
		const {div} = this.barChart.getComponents();
    const { fontFamily, fontSize } = This.barChart.getProperties();
		div.insert('br',':first-child').attr('id','groupList');
		div.insert('select',':first-child')
			.attr('id','groupList')
			.style('font-family', fontFamily)
			.style('font-size', fontSize)
			.on('change', function(d){This.refreshGraphic()});
		this.updateGroups();
	}

	refreshGraphic(){
		var i = Number(document.getElementById('groupList').value);
  	var start = i * this.elemsPerGroup;
  	var end = (i + 1) * this.elemsPerGroup;
		var elems = this.data.slice(start, end);
		this.barChart.plot(elems, this.legend);
  }

  refresh(){
    this.refreshGraphic()
  }
  
  cleanSVG(){
    d3.selectAll('#groupList').remove();
    this.barChart.cleanSVG();
  }
}

class BarChartDataProcessor{
  constructor(props={}){
    const {
      valuesField = 'demand',
      nameField = 'displayname',
    } = props;

    this.valuesField = valuesField;
    this._nameField = nameField;
  }

  processData(data, yearColors={}){
    if (data.length == 0){
      var legend = []
      var colors = []
      var processedData = []
      return {legend,colors,processedData}
    }
    var processedData = this._processItems(data);
    var colors = this._processColors(data, yearColors);
    var legend = this._processLegend(data);
    return {legend, colors, processedData};
  }

  _processItems(data){
    const nameField = this._nameField;
    const valuesField = this.valuesField;
    var processedData = [];
    data.forEach(function(d){
      var elem = {};
      elem[nameField] = d[nameField];
      var values = []
      for (var j=d[valuesField].length-1 ; j>=0; j--){
        values.push(d[valuesField][j].value);
      }
      elem[valuesField] = values;
      processedData.push(elem);
    })
    return processedData;
  }

  _processLegend(data){
    var legend = [];
    const valuesField = this.valuesField;
    if (data.length > 0){
      var elem = data[0];
      for (var j=elem[valuesField].length-1 ; j>=0; j--){
        var d = elem[valuesField][j];
        var year = d.year;
        var month = d.month;
        var date = this._buildDateStr(year,month);
        legend.push(date);
      }
    }
    return legend;
  }

  _buildDateStr(year,month){
    var date = `${year}/${month}/01`
    date = new Date(date);
    return date.toLocaleString('en', { month: 'short', year:'numeric' });
  }

  _processColors(data, yearColors=null){
    var colors = [];
    const valuesField = this.valuesField;
    if (data.length > 0){
      if (yearColors == null) yearColors = {};
      var elem = data[0]
      for (var j=elem[valuesField].length-1 ; j>=0; j--){
        var d = elem[valuesField][j];
        var year = d.year;
        if (!yearColors.hasOwnProperty(year)){
          var yColor = Math.floor(Math.random() * 16777216).toString(16);
          yColor = '#000000'.slice(0, - yColor.length) + yColor;
          yearColors[year] = yColor;
        }
        var month = d.month;
        var color = wordplot.helpers.increaseBrightness(yearColors[year],month/14*100);
        colors.push(color);
      }
    }
    return colors;
  }
}


function buildBarChart(plotId, json, plotType, props={}){
  var plotType = plotType.toLowerCase();
  var json = wordplot.json.normalizeStatisticsJson(json);

  // Verify plotType
  if (!['barchart','barchartgroup','basicbarchart','topbarchart'].includes(plotType)){
    console.log('Error at BuildBarChart() invalid plotType ' + plotType);
    return null;
  }

    const dataFormat = wordplot.json.detectDataType(json);
    if (dataFormat == 'mindmap'){
      return buildBarChartFromMindMap(plotId, json, props);
    }

  //Verify List of Values
  if (['barchart','barchartgroup'].includes(plotType)){
    try {
      var valuesField = (props.hasOwnProperty('valuesField'))? props.valuesField : 'demand'
      const valuesOfFirstRegister = json.data[0][valuesField]
      if (valuesOfFirstRegister == null) throw new Error();
    } catch (error) {
      console.log('Error at BuildBarChart(): Results don\'t contain a parameter called', valuesField,'. They are required for plotType =',plotType);
      return null;
    }
  }

  var plot = null

  // Build plot depending on plotType
  if (plotType == 'barchart'){
    var dataProcessor = new BarChartDataProcessor(props);
    const { legend, colors , processedData } = dataProcessor.processData(json.data);
    plot = new BarChart(plotId, props);
    plot.setLegend(legend)
    plot.setColors(colors)
    plot.setPlotData(processedData);
  } else if (plotType == 'barchartgroup'){
    var dataProcessor = new BarChartDataProcessor(props);
    const { legend, colors , processedData } = dataProcessor.processData(json.data);
    plot = new BarChartGroup(plotId, processedData, legend, props=props);
    //plot.setPlotData(processedData);
    props.colors = colors;
    plot.setProperties(props)
  } else if (plotType == 'basicbarchart'){
    plot = new BasicBarChart(plotId, props);
    plot.setPlotData(json.data);
  } else if (plotType == 'topbarchart'){
    plot = new TopBarChart(plotId, 20, props)
    plot.setPlotData(json.data);
  }
  return plot
}

function buildBarChartFromMindMap(plotId, json, props){
  if (props == null) props = {};
  const {
    elemsPerGroup = 20,
  } = props;
  json = wordplot.json.normalizeMindMapJson(json);
  var nodes = json.nodes;
  nodes = nodes.sort((a,b)=>b.value - a.value)
  const data = wordplot.json.nodesToStatistics(nodes);
  props.valueField = 'value'

  const plot = new wordplot.bars.TopBarChartGroup(plotId, data, props);

  // Set Colors
  const color = '#08233B';
  const colors = [];
  const minPercent = 0;
  const maxPercent = 80;
  for (var i=0; i < elemsPerGroup; i++){
    const percent = minPercent + ((maxPercent - minPercent)/elemsPerGroup*i)
    const newColor = wordplot.helpers.increaseBrightness(color,percent);
    colors.push(newColor);
  }

  plot.setColors(colors);
  plot.refresh();
  //////////////

  return plot;
}

return {
    BarChart,
    BarChartGroup,
    BasicBarChart,
    BarChartDataProcessor,
    TopBarChart,
    TopBarChartGroup,
    buildBarChart
}
})();function initLogo(plot){
    var { outerSVG } = plot.getComponents();
    const { width , height } = plot.getProperties();

    const imageSrc = 'https://megatron.headai.com/lib/headai-logo-without-name-black-transparent.png';

    const sizeImage = 80;
    const xImage = width - sizeImage;
    const yImage = height - sizeImage;

    const textHeight = 20;
    const textSize = '13px';


    const logoContainer =  outerSVG
    .selectAll('svg.headaiLogo').data([null]).enter()
    .append('svg').attr('class','headaiLogo')
    .attr('width',sizeImage)
    .attr('height',sizeImage)
    .attr('x',xImage)
    .attr('y',yImage);

    // Logo Image
    var foreignImage = logoContainer.append('foreignObject')
    .attr('class','foreignImage')
    .attr('x',0)
    .attr('y',0)
    .attr('width',sizeImage)
    .attr('height',sizeImage)
    .node();

    var imageLogo = document.createElement('img');
    imageLogo.setAttribute('width',sizeImage);
    imageLogo.setAttribute('height',sizeImage);
    imageLogo.setAttribute('src',imageSrc);
    foreignImage.appendChild(imageLogo);

    const copyrightText = '© Headai';

    var foreignText = logoContainer.append('foreignObject')
    .attr('class','foreignText')
    .attr('x',0)
    .attr('y',sizeImage - textHeight)
    .attr('width',sizeImage)
    .attr('height',textHeight)
    .node();

    var textLogo = document.createElement('p');
    textLogo.setAttribute('width',sizeImage);
    textLogo.innerHTML = copyrightText;
    textLogo.style.textAlign = 'center';
    textLogo.style.margin = 0;
    textLogo.style.fontFamily = 'sans-serif';
    textLogo.style.fontSize = textSize;
    textLogo.style.fontWeight = 'normal';
    textLogo.style.pointerEvents = 'none';
    foreignText.appendChild(textLogo);
} 
const save = (()=>{
    function exportPng(plot){
        _saveVisualization(plot,'png');
    }
    
    function exportSvg(plot){
        _saveVisualization(plot,'svg');
    }
    
    function _saveVisualization(plot, format){
        format = format.toLowerCase();
        var saveFunction = ()=>{};
    
        if (format == 'png'){
            saveFunction = saveSvgAsPng;
        } else if (format == 'svg'){
            saveFunction = saveSvg;
        } else {
            console.log(`Error at saveVisualization() -> incorrect format ${format}`);
            return;
        }
    
        const currentPlot = plot;
        const components = currentPlot.getComponents();
        if (components.hasOwnProperty('outerSVG')) var internalButtons = components.outerSVG.selectAll('svg.button');
        else var internalButtons = d3.select();
        internalButtons.style('display','none');
        var svgToPlot = currentPlot.getStorableComponent();
    
        const translateBackup = svgToPlot.attr('transform');
        svgToPlot.attr('transform','translate(0,0) scale(1)');
    
        if (currentPlot._uniqueName != null){
          var fileName = `${currentPlot._uniqueName}.${format}`;
        } else {
          var fileName = `plot.${format}`;
        }
        
        saveFunction(svgToPlot.node(),fileName)
        .then( () => internalButtons.style('display','unset'))
        .then( () => {
            svgToPlot.attr('transform',translateBackup);
        });
    }

    return {
        exportSvg,
        exportPng,
    }

})();/**
 * @abstract class
 */
class WordMap extends Visualization{
  constructor(mapType, plotId, props={}){
    const DEFAULT_MAP_PROPS = {
      enableZoom: true,
      hideNumber: true,
      valueFieldToShow: 'value',
    }

    props = wordplot.helpers.mergeDictionaries(DEFAULT_MAP_PROPS, props);

    super(plotId, props);
    this.mapType = mapType;
    if (this.constructor == WordMap) {
      throw new Error("WordMap is an Abstract class, can't be instantiated.");
    }
    this.graphToMap = null;
    this._obtainTooltipData = null;
    this._groupToColorIndex = {};
    this._centralNodeId = null;
    this.centerCameraAround = null;
    this._actionOnClickFunction = ()=>{};
  }

  _initProperties(props){
    super._initProperties(props);

    this.properties = {
      ...this.properties,
      // Extra Properties
      defaultCamera : true,
    }

    this._showActionButtons = true;

    var {
      sourceField = 'sources',
      categoryField = 'group',
      mouseOverDisabled = false,
      showActionButtons = true,
      centerNode = null,
      centerCameraAround = null,
      hideNumber,

      // Validated
      valueFieldToShow,
      initialZoom,
    } = props;

    this._categoryField = categoryField;
    this._sourceField = sourceField;
    this._mouseOverDisabled = false;
    this.disableMouseOver(mouseOverDisabled);
    this.showActionButtons(showActionButtons);
    this.setCentralNode(centerNode);
    this.hideNumber(hideNumber);

    const propsVerifiedByType = {
      initialZoom, valueFieldToShow, centerCameraAround,
    };
    
    this.setProperties(propsVerifiedByType);
  }

  setProperties(props){
    if (props == null) return;
    if (typeof(props) !== "object") return details;
    
    super.setProperties(props);

    var requiresDataRefresh = false;

    const { initialZoom, centerCameraAround } = props;
    
    if (typeof(props.valueFieldToShow) == "string"){
      this.valueFieldToShow = props.valueFieldToShow;
      props.hideNumber = false;
    }

    if (props.hasOwnProperty('categoryField')){
      this._categoryField = props['categoryField'];
      this._initColorIndices();
    }

    if (typeof(initialZoom) == 'number' && initialZoom > 0){
      this.properties.defaultCamera = false;
    }

    if (props.hasOwnProperty('sourceField')) this._sourceField = props['sourceField'];
    if (props.hasOwnProperty('colors')) this._initColorIndices();
    if (props.hasOwnProperty('showActionButtons')) this.showActionButtons(props.showActionButtons);
    if (props.hasOwnProperty('mouseOverDisabled')) this.disableMouseOver(props.mouseOverDisabled);
    if (props.hasOwnProperty('hideNumber')) this.hideNumber(props['hideNumber']);
    if (props.hasOwnProperty('centerNode')){
      this.setCentralNode(props['centerNode']);
    }

    if (typeof(centerCameraAround) == "string"){
      this.setCenterCameraAround(centerCameraAround);
    }

    if (this.mindmap != null){
      const { reprocess, changes } = this.mindmap.setProperties(props);
      if (reprocess === true) requiresDataRefresh = true;
    }

    if (this.graphToMap != null){
      const graphChanges = this.graphToMap.setProperties(props);
      if (graphChanges.centerNode == null) requiresDataRefresh = true;
    }

    if (requiresDataRefresh === true){
      this.refreshData();
      return;
    } else {
      // Insert any extra operation
    }
  }

  setCenterCameraAround(centerCameraAround){
    if (typeof(centerCameraAround) != "string") return;
    if (this.mindmap == null){ this.centerCameraAround = centerCameraAround; return; }
    if (!this.mindmap.hasLabel(centerCameraAround)) return;

    this.centerCameraAround = centerCameraAround;
  }


  /**
   * Receives a list of Numeric identifiers for the existing groups in the given data, and
   * assigns one color to each
   * @param {*} groups 
   */
  _initColorIndicesAux(groups){
    if (!Array.isArray(groups)) return;
    const groupsInt = [];
    // Sometimes, when JSON is loaded, some groups are parsed by javascript as Strings, for that reason it's
    // necessary to convert them always
    groups.forEach(d => groupsInt.push(Number.parseInt(d)));
    const sortedGroups = groupsInt.sort(function(a, b){return a-b});
    for (var i=0; i < sortedGroups.length; i++){
      const group = sortedGroups[i];
      this._groupToColorIndex[group] = i;
      // If index is greater than existing colors, Generates new Random color
      if (i >= this._colors.length){
        var color = Math.floor(Math.random() * 16777216).toString(16);
        var color = '#000000'.slice(0, - color.length) + color;
        this._colors.push(color);
      }
    }
  }

  _initColorIndices(){
    if (this.graphToMap == null) return;
    this._groupToColorIndex = {};
    const { categoryField } = this.getProperties();
    const groups = [];
    this.graphToMap.getLocatedNodes().forEach( function(d){
      const group = d[categoryField];
      if (!groups.includes(group)) groups.push(group)
    });
    this._initColorIndicesAux(groups);
  }
  
  getColor(group){
    const groupAlreadyRegistered = this._groupToColorIndex.hasOwnProperty(group);
    const index = Object.keys(this._groupToColorIndex).length;
    const needsMoreColors = index > this._colors.length;
    if (needsMoreColors || !groupAlreadyRegistered){
      // Generate Random color
      var color = Math.floor(Math.random() * 16777216).toString(16);
      var color = '#000000'.slice(0, - color.length) + color;
      this._colors.push(color);
    } if (!groupAlreadyRegistered){
      this._groupToColorIndex[group] = index;
    }
    const colorIndex = this._groupToColorIndex[group];
    return this._colors[colorIndex];
  }

  /******************** Action Buttons ********************/
  showActionButtons(enable){
    if (typeof(enable) != "boolean") return;
    this._showActionButtons = enable;
    wordplot.wordmap.interaction.initializeButtons(this);
  }
  /*********************************************************/

  setCentralNode(centerLabel){
    if (typeof(centerLabel) != 'string') return;

    const centerInfo = this.mindmap.getNodeInfoByLabel(centerLabel);
    const centerId = centerInfo['id'];
    this._centralNodeId = centerId;
  }

  disableMouseOver(disable){
    if (typeof(disable) != "boolean") return;
    this._mouseOverDisabled = disable;
  }

  refresh(){
    super.refresh();
  }

  getProperties(){
    var properties = {
      'hideNumber': this._hideNumber,
      'categoryField': this._categoryField,
      'colors': this._colors,
      'actionButtons': this._actionButtons,
      'centerCameraAround': this.centerCameraAround,
      'sourceField': this._sourceField,
    }
    var properties = Object.assign(super.getProperties(), properties)
    return properties;
  }

  hideNumber(hide){
    if (hide === true || hide === false) this._hideNumber = hide;
  }

  setGraphToMap(graphToMap){
    if (graphToMap == null) return;

    this.graphToMap = graphToMap;
    this.mindmap = graphToMap.mindmap;
    this._initColorIndices();
  }

  _refreshCenter(nodeId){
    if (this.graphToMap == null){
      console.log("WordMap doesn't have a well defined graphToMap processor");
      return;
    }
    this._centralNodeId = nodeId;
    this.graphToMap.locateNodesAround(nodeId);
    const newNodes = this.graphToMap.getLocatedNodes();
    this.plot(newNodes);
  }

  _showTooltipInfo(nodeId){
    //const explains = [ {"title": "Electronic Waste Recycling Mode and Control Measures in China Based on PEST and SWOT", "url":"https://doaj.org/article/30f0029cb6fe4d7ca85348db4d1825a1"},{"title": "Assessing the Differential Effects of Peer Tutoring for Tutors and Tutee","url": "https://doaj.org/article/30f0029cb6fe4d7ca85348db4d1825a1" }];
    if (this._enableTooltip === false) return;

    var nodeInfo = this.mindmap.getNodeInfoById(nodeId);
    var nodeSVG = this.getComponents().container.select(`svg.concept[nodeId="${nodeId}"]`);
    if (!nodeInfo.hasOwnProperty('tooltipInfo')) return;
    
    //var label = nodeInfo.label;
    //var label = label.replace(/_/g,',');
    //var explainParams = {context:datasource,year:timeframe,text:label};
    //var explainUrl = buildExplainUrl(explainParams);
    //showExplaination([{url:explainUrl,title:explainUrl}]);
    const tooltip = this.tooltip;

    var explains = nodeInfo.tooltipInfo;

    tooltip.restartSVG();
    explains.forEach(e => {
      tooltip.addLink(e.url, e.title)
      tooltip.addLine('\n');
    });
    
    const x = Number(nodeSVG.attr('x')) - (tooltip._width - Number(nodeSVG.attr('width')) )/2;
    const y = Number(nodeSVG.attr('y')) - (tooltip._height) + 15;
    tooltip.move(x,y);

    this.tooltip.show();
  }

  /**
   * The given function must receive a d3js event object, which contains
   * the clicked element.
   * @param {*} f A function that receiver a d3js event
   */
  setFunctionToObtainTooltipData(f){
    if (typeof(f) != 'function') return;
    this._obtainTooltipData = f;
  }

  //@override
  getStorableComponent(){
    if (this.graphToMap != null){
      var recenteredNodes = this.graphToMap.getLocatedNodes();
      this.plot(recenteredNodes);
    } else {
      this.refresh();
    }

    const { container } = this.getComponents();
    const svgToPlot = container;
    return svgToPlot;
  }

  setInitialPosition(x,y){
    if (typeof(x) !== 'number' || typeof(y) !== 'number') return;
    if (isNaN(x) || isNaN(y)) return;
    this._initialPosition = [x,y];
  }

  _highlightAllNodes(){
    const {innerSVG} = this.getComponents();
    const allNodes = innerSVG.selectAll('svg.concept');
    allNodes.style('opacity','1').style('font-weight','normal');
    allNodes.selectAll('line').style('opacity','1');
  }

  _unHighlightAllNodes(){
    const {innerSVG} = this.getComponents();

    const allNodes = innerSVG.selectAll('svg.concept');    
    allNodes.style('opacity','0.3').style('font-weight','normal');
    allNodes.selectAll('line').style('opacity','0.3');
  }

  _highlightListOfNodes(nodes){
    const {innerSVG} = this.getComponents();
    const This = this;
    const categoryField = this._categoryField;

    const allNodes = this.mindmap.getData().nodes;
    var maxValue = allNodes[0].value
    var maxValue = this._scaleValue(maxValue);
    
    if (!Array.isArray(nodes)) return;
    nodes.forEach(function(nId){
      const nodeInfo = This.graphToMap.mindmap.getNodeInfoById(nId);
      const nodeSVG = innerSVG.select(`svg.concept[nodeId="${nId}"]`);
      nodeSVG.style('opacity','1');
      nodeSVG.selectAll('line').style('opacity','1');

      if (nId == This._centralNodeId) return;

      var value = This._scaleValue(nodeInfo['value']);
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var percentBrightness = Math.max(percentBrightness,0);
      var color = This.getColor(nodeInfo[categoryField]),
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      nodeSVG.select('path').attr('fill',color);
    });
  }

  _highlightNode(nodeId){
    if (nodeId == null) return;
    if (this.graphToMap == null) return;

    const neighbors = this.mindmap.getNeighborsById(nodeId);
    const {innerSVG} = this.getComponents();

    const allNodes = innerSVG.selectAll('svg.concept');    
    allNodes.style('opacity','0.3').style('font-weight','normal');
    allNodes.selectAll('line').style('opacity','0.3');

    const mainNode = innerSVG.select(`svg.concept[nodeId="${nodeId}"]`);
    mainNode.style('font-weight','bold');
    this._highlightListOfNodes([nodeId].concat(neighbors));
  }

  refreshData(){
    this.graphToMap.locateNodes();
    const newPlotData = this.graphToMap.getLocatedNodes();
    this.setPlotData(newPlotData);
    this.refresh();
  }

  //@override
  removeElementByName(elementName){
    this.mindmap.removeElementByLabel(elementName);
    this.refreshData();
  }

  removeListOfElementsByName(listOfElements){
    if (!Array.isArray(listOfElements)) return;
    if (listOfElements.length == 0) return; // Prevents executing unnecesary operations

    this.mindmap.removeListOfElementsByName(listOfElements);
    this.refreshData();
  }

  removeElementById(elementId){
    this.mindmap.removeElementById(elementId);
    this.graphToMap.locateNodes();
    const newPlotData = this.graphToMap.getLocatedNodes();
    this.setPlotData(newPlotData);
    this.refresh();
  }

  translateVisualization(x,y,scale){
    const zoom = d3.zoom();
    const transform = `translate(${x},${y}) scale(${scale})`;
    this.getComponents().container.attr('transform',transform);
    this.getComponents().innerSVG.call(zoom.transform, d3.zoomIdentity.translate(x, y).scale(scale))
  }

  addZoomButtons(){
    const zoomButtonSize = 30
    const separation = 5
    const zoomInButtonInfo = {
      x: separation + (1 * zoomButtonSize),
      y: 0,
      width: zoomButtonSize,
      height: zoomButtonSize,
      label: '➕',
      backgroundColor: 'white',
    }

    const zoomIn =  () => {
      const increaseFactor = 0.2
      const [x,y] = this._currentPosition;
      const scale = this._currentZoom + increaseFactor;
      this.translateVisualization(x,y,scale);
      this._currentPosition = [x,y];
      this._currentZoom = scale;
    }

    this.addButton(zoomInButtonInfo, zoomIn);

    // Zoom Out

    const buttonInfo = {
      x: 0,
      y: 0,
      width: zoomButtonSize,
      height: zoomButtonSize,
      label: '➖',
      backgroundColor: 'white',
    }

    const zoomOut = () => {
      const increaseFactor = 0.2
      const [x,y] = this._currentPosition;
      const scale = this._currentZoom - increaseFactor
      this.translateVisualization(x,y,scale);
      this._currentPosition = [x,y];
      this._currentZoom = scale;
    }

    this.addButton(buttonInfo, zoomOut);
  }

  setActionOnClick(action){
    if (typeof(action) != 'string') return;
    if (action.length == 0) return;
    action = action.toLowerCase();
    wordplot.wordmap.interaction.changeOnClickAction(this, action);
    const {container} = this.getComponents();
    var concepts = container.selectAll('svg.concept');
    concepts.on('click', this._actionOnClickFunction);
  }
}

function buildWordMap(plotId, json, plotType='hexagon', props={}){
  const originalJson = json;
  var plotType = plotType.toLowerCase();

  if (props == null || props.constructor.name != 'Object'){
    props = {}
  }

  if (!['hexagon','square'].includes(plotType)){
    console.log('Error at BuildWordMap() invalid plotType ' + plotType)
    return null
  }

  const isWideMap = (props.hasOwnProperty('onlyNearestNeighbours') && props['onlyNearestNeighbours'] === true)

  json = wordplot.json.normalizeMindMapJson(json);

  // Build MindMap
  const mindmap = new wordplot.wordmap.MindMap(json, props);

  var processor;
  if (isWideMap === true){
    processor = new wordplot.graph.WideGraphToMap(mindmap, plotType, props);
  } else {
    processor = new wordplot.graph.GraphToMap(mindmap, plotType, props);
  }
  
  const locatedNodes = processor.getLocatedNodes();
  var plot = null;
  if (plotType == 'hexagon'){
    plot = new wordplot.wordmap.HexagonMap(plotId);
  } else if (plotType == 'square'){
    plot = new wordplot.wordmap.SquareMap(plotId);
  } else {
    console.log('Error at BuildWordMap() Unexpected plot type:',plotType)
  }

  plot.setGraphToMap(processor);
  plot.setProperties(props);
  plot.setPlotData(locatedNodes);
  plot.setLegend(json.legends);

  if (wordplot.json.isMapWithSignals(json)){
    const signalsProps = {
     'actionOnClick':'showvalues',
     'enableTooltipChart': true,
    }
    plot.setProperties(signalsProps);

    wordplot.wordmap.configureMapWithSignals(plot);

    if (json.hasOwnProperty('info') && json.info.hasOwnProperty('timeLabels')){
      const tooltipProps = { 'timeLabels' : json.info.timeLabels };
      plot.enableTooltipChart('basiclinechart', tooltipProps);
    } else if (!props.hasOwnProperty('tooltipChartProps')) {
      console.log('Warning: The JSON contains information about signals but doesn\'t provide time labels. For this reason, the embedded linechart has been disabled');
    }
  }

  return plot
}

function plotLegend(plotId, legends, colors, props={}){
  const json = {data:{
      nodes:[],
      edges:[],
      legends:legends,
  }}
  const plot = wordplot.wordmap.buildWordMap(plotId,json,"hexagon",props);
  plot.setColors(colors);
  plot.setLegend(legends);
  
  const groups = Object.keys(legends);
  const groupToColorIndex = {}
  for(var i=0; i < groups.length; i++){
      const group = groups[i];
      groupToColorIndex[group] = i;
  }
  plot._groupToColorIndex = groupToColorIndex;
  plot.refresh();
  return plot
}

function configureMapWithSignals(plot){
  var colors = [
    "#001773", // (1) Emerging
    "#6987ff", // (2) Constantly Increasing
    "#0090b8", // (3) Increasing in Last Map
    "#666666", // (4) Constant Values
    "#c4c4c4", // (5) Constant in Last Map
    "#c79f00", // (6) Constantly Dicreasing
    "#ed5300", // (7) Decreasing in the last Map
    "#990000", // (8) Disappearing
  ]
  
  plot.setColors(colors);
  
  var groupToColorIndexAux = {
    "1":0, "2":1, "3":2, "4":3, "5":4, "6":5, "7":6, "8":7,
  }
  
  plot._groupToColorIndex = groupToColorIndexAux;

  const props = {
    'actionOnClick': 'showvalues',
    'enableTooltipChart': true,
    'scale': 'flat',
  }

  plot.setProperties(props);
  plot.refresh();
}

function setSDGColorsInMap(plot){
  var colors = [
      "#E5243B",  // (1) No Poverty  - RED
      "#DDA63A",  // (2) Zero Hunger - MUSTARD
      "#4C9F38",  // (3) Good Health and Well-Being - KELLY GREEN
      "#C5192D",  // (4) Quality Education - DARK RED
      "#FF3A21",  // (5) Gender Equality - RED ORANGE
      "#26BDE2",  // (6) Clean Water and Sanitation - BRIGHT BLUE
      "#FCC30B",  // (7) Affordable and Clean Energy - YELLOW
      "#A21942",  // (8) Decent Work and Economic Growth - BURGUNDY RED
      "#FD6925",  // (9) Industry, Innovation and Infrastructure - ORANGE
      "#DD1367",  // (10) Reduced Inequalities - MAGENTA
      "#FD9D24",  // (11) Sustainable Cities and Communities - GOLDEN YELLOW
      "#BF8B2E",  // (12) Responsible consumption and Production - DARK MUSTARD
      "#3F7E44",  // (13) Climate Action - DARK GREEN
      "#0A97D9",  // (14) Life bellow water - BLUE
      "#56C02B",  // (15) Life on land - LIME GREEN
      "#00689D",  // (16) Peace, Justice and Strong Institutions - ROYAL BLUE
      "#19486A",  // (17) Partnerships for the goals - NAVY BLUE
  ]

  plot.setColors(colors);

  var groupToColorIndexAux = {
      "1":0, "2":1, "3":2, "4":3, "5":4, "6":5, "7":6, "8":7, "9":8, "10":9,
      "11":10, "12":11, "13":12, "14":13, "15":14, "16":15, "17":16,
  }

  var groupToColorIndex = plot._groupToColorIndex;

  Object.keys(groupToColorIndex).forEach( group => {
      if (!groupToColorIndexAux.hasOwnProperty(group)){
          console.log(`ERROR at setSDGColorsInMap() : Group ${group} is not part of SDG Data`);
          return;
      }
      groupToColorIndex[group] = groupToColorIndexAux[group];
  })

  plot._groupToColorIndex = groupToColorIndex;
  var legend = {
      1:"No Poverty",
      2:"Zero Hunger",
      3:"Good Health and Well-Being",
      4:"Quality Education",
      5:"Gender Equality",
      6:"Clean Water and Sanitation",
      7:"Affordable and Clean Energy",
      8:"Decent Work and Economic Growth",
      9:"Industry, Innovation and Infrastructure",
      10:"Reduced Inequalities",
      11:"Sustainable Cities and Communities",
      12:"Responsible consumption and Production",
      13:"Climate Action",
      14:"Life bellow water",
      15:"Life on land",
      16:"Peace, Justice and Strong Institutions",
      17:"Partnerships for the goals",
  }
  plot.setLegend(legend);
}

function centerMap(plot){
  const {div} = plot.getComponents();
  const concepts = div.selectAll('svg.concept').nodes();
  if (concepts.length == 0) return;

  // Give initial values
  var xMin = concepts[0].getAttribute('x');
  var yMin = concepts[0].getAttribute('y');
  var xMax = xMin;
  var yMax = yMin;
  // Find min and max
  for (var i=0; i < concepts.length; i++){
    const concept = concepts[i];
    const x = Number.parseFloat(concept.getAttribute('x'));
    const y = Number.parseFloat(concept.getAttribute('y'));
    if (x < xMin) xMin = x
    if (y < yMin) yMin = y
    if (x > xMax) xMax = x
    if (y > yMax) yMax = y
  }

  const figureSize = concepts[0].hasAttribute('width')? Number.parseFloat(concepts[0].getAttribute('width')) : 0;
  const radius = figureSize / 2;

  xMin -= radius;
  xMax += radius;

  //yMin -= radius;
  //yMax += radius;

  const width = xMax - xMin;
  //const height = yMax - yMin;

  const scale = plot._width / width;

  var initialX = (-xMin - radius) * scale;
  var initialY = (-yMin) * scale;

  plot.setInitialPosition(initialX, initialY);
  plot.setInitialZoom(scale);
  plot.translateVisualization(initialX,initialY,scale);
}

function enableRelevancyMode(plot){
  const DEFAULT_RELEVANCY_COLORS = ['#cc3232','#db7b2b','#e7b416','#99c140','#2dc937'];

  const props = {
    'scale':'flat',
    'categoryField':'weight',
    'colors': DEFAULT_RELEVANCY_COLORS
  }
  plot.setProperties(props);

  var groupToColorIndex = { "1":0, "2":1, "3":2, "4":3, "5":4}
  plot._groupToColorIndex = groupToColorIndex;

  const legend = {'1':'Weight 1','2':'Weight 2','3':'Weight 3','4':'Weight 4','5':'Weight 5'};
  plot.setLegend(legend);
}

function setColorsToMindMap(plot){
  if (plot == null) return;
  const colors = generateColorsForPlot(plot);
  plot.setColors(colors);
}

function generateColorsForPlot(plot){
  if (plot == null) return [];
  const colors = plot._colors;
  const groupField = plot._categoryField;
  const categoryCount = {};
  var newColors = [];

  const categories = Object.keys(plot._groupToColorIndex)
  categories.forEach( group => {
      categoryCount[group] = 0;
  });

  plot._plotData.forEach(node => {
      const group = node[groupField];
      if (!categoryCount.hasOwnProperty(group)){console.log(`Warning at SetColors() : Group '${group}' was not correctly identified`); return;}
      categoryCount[group] += 1;
  });

  const nCategories = categories.length;
  if (nCategories == 1){
      newColors = ['#08233B'];
      return newColors;
  } else if (nCategories == 2){
      const nNodesCat1 = categoryCount[categories[0]];
      const nNodesCat2 = categoryCount[categories[1]];
      if (nNodesCat1 == 1 && nNodesCat2 >= 1 ||
          nNodesCat1 >= 1 && nNodesCat2 == 1){
          newColors = ['#11A1F3','#ee5e0c'];
          return newColors;
      } else if (nNodesCat1 == 0 || nNodesCat2 == 0){
          newColors = ['#08233B','#08233B'];
          return newColors
      } else {
          newColors = ['#4DC3F9', '#E59476'];
      }
  } else if (nCategories == 3){
      newColors = [ "#58C69A", "#E59476", "#4DC3F9" ];
      return newColors;
  }
  return newColors;
}class HexagonMap extends WordMap{
  constructor(plotId, props = {}){
    const DEFAULT_HEXAGONMAP_PROPS = {
      strokeWidth: 3,
      strokeColor: '#000000',
      hexagonRadius: 70,
      spaceBetweenHexagons: 0.5,
      width: 800,
      height: 600,
      enableZoom: true,
      nameField: 'label',
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_HEXAGONMAP_PROPS, props);
    super('hexagon', plotId, props);
    this._initProperties(props);
  }

  _initProperties(props){
    super._initProperties(props);
    const {
      strokeWidth,
      strokeColor,
      hexagonRadius,
      spaceBetweenHexagons,
      width,
      height
     } = props

     this.setStrokeWidth(strokeWidth);
     this.setStrokeColor(strokeColor);
     this.setHexagonRadius(hexagonRadius);
     this.setSpaceBetween(spaceBetweenHexagons);
     this.setSize(width, height);
  }

  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('strokeWidth')) this.setStrokeWidth(props['strokeWidth']);
    if (props.hasOwnProperty('strokeColor')) this.setStrokeColor(props['strokeColor']);
    if (props.hasOwnProperty('hexagonRadius')) this.setHexagonRadius(props['hexagonRadius']);
    if (props.hasOwnProperty('spaceBetweenHexagons')) this.setSpaceBetween(props['spaceBetweenHexagons']);
  }

  getProperties(){
    var properties = {
      'strokeWidth': this._strokeWidth,
      'strokeColor': this._strokeColor,
      'hexagonRadius': this._hexaRadius,
      'spaceBetweenHexagons': this._spaceBetweenHexa
    }
    var properties = Object.assign(super.getProperties(), properties)
    return properties;
  }

  setStrokeWidth(sw){
    if (sw > 0) this._strokeWidth = sw;
  }

  setSpaceBetween(space){
    if (space > 0) this._spaceBetweenHexa = space;
  }

  setHexagonRadius(radius){
    if (radius > 0) this._hexaRadius = radius;
  }

  setStrokeColor(color){
    this._strokeColor = color;
  }

  scaleBrightness(value, maxValue, minBright=0, maxBright=80){
    var value = this._scaleValue(value);
    var maxValue = this._scaleValue(maxValue);
    var brightness = 1 - (value/maxValue);
    var percentBrightness = (brightness * (maxBright - minBright)) + minBright;
    return percentBrightness;
  }

  centerCamera(label, zoom){    
    if (typeof(zoom) !== 'number') return;
    if (this.properties.defaultCamera == true) return;
    if (typeof(label) !== 'string' || label.length == 0) label = this.graphToMap.getProperties().centerNode;

    if (!this.mindmap.hasLabel(label)){
      console.log(`Error at centerCamera() : Label "${label}" was not found`);
      this.properties.defaultCamera = true;
      this.properties.centerCameraAround = null;
      return;
    }

    var concept = this.mindmap.getNodeInfoByLabel(label);

    const radius = this._hexaRadius
    const hexaWidth = radius * Math.sqrt(3);
    const hexaHeight = radius * 2;

    var xIndex = concept['x'];
    var yIndex = concept['y'];

    var xConcept = this.getCenter(radius,yIndex,xIndex)[0];
    xConcept = xConcept - hexaWidth/2;

    var yConcept = this.getCenter(radius,yIndex,xIndex)[1];
    yConcept = yConcept - hexaHeight/2

    var x = this._width/2/zoom - hexaWidth/2 - xConcept;
    x = x * zoom;
    var y = this._height/2/zoom - hexaHeight/2 - yConcept;
    y = y * zoom;

    this.setInitialZoom(zoom);
    this.setInitialPosition(x,y);
  }

  plot(data){
    var {
      centerCameraAround, initialZoom, categoryField, nameField
    } = this.getProperties();

    if (this.properties.defaultCamera === false){
      this.centerCamera(centerCameraAround, initialZoom);
    }

    this.restartSVG();
    var This = this;
    this.setPlotData(data);
    if (data.length == 0) return;
    // Initial Geometrical Calculations
    var hexaRadius = this._hexaRadius;
    var hexaHeight = hexaRadius * 2;        // Calculates Width of the Hexagons with specified radius
    var hexaWidth = hexaRadius * Math.sqrt(3);      // Calculates Width of the Hexagons ...
    var rows = Math.max.apply(Math, data.map(function(o) { return o.y; })) + 1;
    var cols = Math.max.apply(Math, data.map(function(o) { return o.x; })) + 1;
    var height = (rows + 1/3) * 3/2 * hexaRadius;    // Calculates height of the window with given rows and radius
    var width = (cols + 1/2) * Math.sqrt(3) * hexaRadius; // Calculates width of ...

    const {container} = this.getComponents();

    var svg = container;
    var hexbin = d3.hexbin()
      .radius(hexaRadius)
      .extent([[0, 0], [width, height]]);
    
    var concepts = svg.selectAll('svg.concept')
      .data(data).enter()
      .append('svg')
      .attr('class','concept')
      .attr('nodeId', d => d.id)
      .attr('height',hexaHeight).attr('width',hexaWidth)
      .attr('x', function (d) { 
        var cx = This.getCenter(hexaRadius,d.y,d.x)[0];
        return cx - hexaWidth/2
      })
      .attr('y', function (d) { 
        var cy = This.getCenter(hexaRadius,d.y,d.x)[1];
        return cy - hexaHeight/2
      })

    concepts.on("mouseover", function(d){
      if (This._mouseOverDisabled === true) return;
      if (['highlight'].includes(This._actionOnClick)) return;
      var nodeId = this.getAttribute('nodeId');
      This._highlightNode(nodeId);
    });

    concepts.on("mouseout", function(d){
      if (This._mouseOverDisabled === true) return;
      if (['highlight'].includes(This._actionOnClick)) return;
      if (This._enableTooltip === true) This.tooltip.hide();
      var nodeId = this.getAttribute('nodeId');
      This._unHighlightNode(nodeId);
    });

    concepts.on('click', this._actionOnClickFunction);

    if (this._showActionButtons === true) wordplot.wordmap.interaction.initializeButtons(this);

    concepts.on('contextmenu', function(event){
      event.preventDefault();
      var nodeId = this.getAttribute('nodeId');
      var nodeInfo = This.graphToMap.mindmap.getNodeInfoById(nodeId);
      if ( !nodeInfo.hasOwnProperty('tooltipInfo')  && This._obtainTooltipData != null ) This._obtainTooltipData(event);
      This._showTooltipInfo(nodeId);
    });
    
    var maxValue = data.sort( 
      function(a, b) {return parseFloat(b['value']) - parseFloat(a['value']);})[0]['value']
    
    var hexagons = concepts.append('path').attr('d', hexbin.hexagon(hexaRadius - This._spaceBetweenHexa))
      .attr('transform',  function (d){
      return 'translate(' + hexaWidth/2 + ',' + hexaHeight/2 + ')'
    })
    
    hexagons.attr('fill', function(d){
      var value = d.value;
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var color = This.getColor(d[categoryField]),
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      return color
    });
    
    var text = concepts.append('text')
    .attr('class','textInside')
    .attr('x','50%')
    .attr('y','40%')
    .style('pointer-events','none')
    .attr('text-anchor','middle')
    .attr('dominant-baseline','middle')
    .attr('fill',function(d){
      var value = d.value;
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var color = This.getColor(d[categoryField]),
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      color = wordplot.helpers.invertColor(color);
      return color;
    }).text(function(d){
      var line = d[nameField];
      const conceptHasField = d.hasOwnProperty(This.valueFieldToShow);
      const isNumerical = !isNaN(d[This.valueFieldToShow]);
      if (!This._hideNumber && conceptHasField && isNumerical) line = line +'\t('+ d[This.valueFieldToShow] + ')';
      return line.replace(/_/g,' ');
    });

    this._setTextProperties(text);

    text.call(wordplot.helpers.wrapLines, hexaWidth);

    text.each(function(d){
      var text = d3.select(this)
      var nLines = text.selectAll('tspan').size();
      if (nLines >= 5) text.attr('y','25%');
      if (nLines == 4) text.attr('y','35%');
      if (nLines == 1) text.attr('y','45%');
    });

    this.drawBorders(svg, hexaRadius);

    if (this._enableTooltip === true){
      this.tooltip.getComponents().div.raise();
    }

    this._boldNode(this._centralNodeId);

    wordplot.logo.initLogo(this);

    if (this.properties.defaultCamera === true){
      wordplot.wordmap.centerMap(this);
    }
  }

  getCenter(hexaRadius, row, col){
    var x = hexaRadius * col * Math.sqrt(3)
    //Offset each uneven row by half of a 'hex-width' to the right
    if(row % 2 === 1) x += (hexaRadius * Math.sqrt(3))/2
    var y = hexaRadius * row * 1.5
    return [x+hexaRadius,y+hexaRadius];
  }

  drawBorders(){
    const r = this._hexaRadius;
    const {container} = this.getComponents();
    const svg = container;
    var h = 2*r;
    var w = Math.sqrt(3) * r;

    // Space to draw Lines around hexagons
    var s = 0
    // Vertices
    var v = {
      a: [  0+s , r/2  ],
      b: [  w/2 ,  0  ],
      c: [  w-s , r/2  ],
      d: [  w-s ,3*r/2  ],
      e: [  w/2 , 2*r  ],
      f: [  0+s  ,3*r/2]
    }

    var hexagonEdges = {
      s1: {from: v.a , to: v.b},
      s2: {from: v.b , to: v.c},
      s3: {from: v.c , to: v.d},
      s4: {from: v.d , to: v.e},
      s5: {from: v.e , to: v.f},
      s6: {from: v.f , to: v.a},
    }

    var sides = ['s1','s2','s3','s4','s5','s6']
    for(var i=0; i < sides.length; i++){
      var side = sides[i];
      var filteredConcepts = svg.selectAll('svg.concept')
      this.drawBorder(filteredConcepts, hexagonEdges[side], side)
    }
  }

  drawBorder(concepts, coords, side){
    var x1 = coords['from'][0];
    var  y1 = coords['from'][1];
    var  x2 = coords['to'][0];
    var  y2 = coords['to'][1];

    concepts
      .filter(function(d){return d.hasOwnProperty(side) && d[side] === true; })
      .append('line')
      .style('stroke', this._strokeColor)
      .style('stroke-width', this._strokeWidth)
      .attr('x1', x1)
      .attr('y1', y1)
      .attr('x2', x2)
      .attr('y2', y2); 
  }

  _boldNode(nodeId){
    if (nodeId == null) return;
    if (this.graphToMap == null) return;
    if (!this.mindmap.hasId(nodeId)) return;

    const { categoryField } = this.getProperties();

    const { innerSVG } = this.getComponents();
    const { fontSize, nameField } = this.getProperties();

    const nodeSVG = innerSVG.select(`svg.concept[nodeId="${nodeId}"]`);
    nodeSVG.style('opacity','1');
    nodeSVG.selectAll('line').style('opacity','1');
    
    const nodeInfo = this.graphToMap.mindmap.getNodeInfoById(nodeId);
    const color = this.getColor(nodeInfo[categoryField]);
    const textColor = wordplot.helpers.invertColor(color);

    const lightest = "#A0A0A0";
    const darkest = "#000000";
    const colorTransition = `${color};${lightest};${color};${darkest};${color}`

    const animate = nodeSVG.select('path').selectAll('animate').data([null]).enter().append('animate')
    animate.attr('attributeName','fill')
      .attr('values',colorTransition)
      .attr('repeatCount','indefinite')
      .attr('dur','4s');

    const nodeText = nodeSVG.select('text');
    nodeText.attr('fill', textColor);

    const textObject = nodeSVG.select('text');
    textObject.style('font-weight','bold');

    const textContent = nodeInfo[nameField].replace(/_/g,' ');
    const maxWidth = this._hexaRadius * Math.sqrt(3);
    // maxWidth is multiplied by 0.9 to adjust the text even more. getComputedTextLength function 
    // doesn't consider font-weight to compute the textLength.
    wordplot.helpers.readjustFontSize(textObject, textContent, fontSize, maxWidth * 0.9);
  }

  _unHighlightNode(nodeId){
    if (this.graphToMap == null) return;
    const This = this;
    const neighbors = this.mindmap.getNeighborsById(nodeId);
    const {innerSVG} = this.getComponents();
    const allNodes = innerSVG.selectAll('svg.concept');

    const { nodes } = this.mindmap.getData();
    if (nodes.length == 0) return;

    var maxValue = nodes[0].value;
    var maxValue = this._scaleValue(maxValue);

    allNodes.style('opacity','1').style('font-weight','normal');
    allNodes.selectAll('line').style('opacity','1');

    const { categoryField } = this.getProperties();

    [nodeId].concat(neighbors).forEach((nodeId)=>{
      const nodeInfo = This.mindmap.getNodeInfoById(nodeId);
      const nodeSVG = innerSVG.select(`svg.concept[nodeId="${nodeId}"]`);
      var value = This._scaleValue(nodeInfo['value']);
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var color = This.getColor(nodeInfo[categoryField]);
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      nodeSVG.select('path').attr('fill',color);
    });

    this._boldNode(this._centralNodeId);
  }
}

class HexagonGraphProcessor extends GraphProcessor{
  constructor(){
    super('hexagon');
    this.adjacentCoordinatesOrder = ['s3','s6','s5','s4','s1','s2'];
  }

  getSurroundingIds(center,matrix){
    var ids = {};
    var coords = this.getNeighborsCoordinates(center)
    var sidesOrder = this.adjacentCoordinatesOrder;
  
    for (var i=0; i<coords.length; i++){
      var c = coords[i],    // c = [cx,cy]
          x = c[0],
          y = c[1];
  
      if (x >= 0 && y >=0 && y < matrix.length && x < matrix[0].length){
        var id = matrix[y][x];
        if (id != -1){
          var label = sidesOrder[i]
          ids[label] = id;    // E.j. ids['s1'] == ID
        }
      }
    }
    return ids;
  }

  getNeighborsCoordinates(center){
    var cx = center[0],
        cy = center[1],
        adjacentCoords = [],
        coords = {};
  
    if(cy % 2 == 0){
      coords['s1'] = [cx-1,cy-1];
      coords['s2'] = [cx,cy-1];
      coords['s3'] = [cx+1,cy];
      coords['s4'] = [cx,cy+1];
      coords['s5'] = [cx-1,cy+1];
      coords['s6'] = [cx-1,cy];
    } else {
      coords['s1'] = [cx,cy-1];
      coords['s2'] = [cx+1,cy-1];
      coords['s3'] = [cx+1,cy];
      coords['s4'] = [cx+1,cy+1];
      coords['s5'] = [cx,cy+1];
      coords['s6'] = [cx-1,cy];
    }
  
    for (var i=0; i< this.adjacentCoordinatesOrder.length; i++){
      var side = this.adjacentCoordinatesOrder[i],
          coor = coords[side]
      adjacentCoords.push(coor)
    }
  
    return adjacentCoords
  }
}
  class SquareMap extends WordMap{
  constructor(plotId, props = {}){
    const DEFAULT_SQUAREMAP_PROPS = {
      strokeWidth: 3,
      strokeColor: '#000000',
      squareSize: 100,
      spaceBetweenSquares: 0,
      hideNumber: true,
      width: 800,
      height: 600,
      enableZoom: true,
      nameField: 'label',
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_SQUAREMAP_PROPS, props);
    super('square', plotId, props);
    this._initProperties(props);
  }

  _initProperties(props){
    super._initProperties(props);
    const {
      strokeColor,
      squareSize,
      spaceBetweenSquares,
      hideNumber,
      width,
      height,
    } = props
     
    this.setStrokeColor(strokeColor);
    this.setSquareSize(squareSize);
    this.setSpaceBetween(spaceBetweenSquares);
    this.hideNumber(hideNumber);
    this.setSize(width, height);
  }

  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('hideNumber')) this.hideNumber(props['hideNumber']);
    if (props.hasOwnProperty('strokeColor')) this.setStrokeColor(props['strokeColor']);
    if (props.hasOwnProperty('squareSize')) this.setSquareSize(props['squareSize']);
    if (props.hasOwnProperty('spaceBetweenSquares')) this.setSpaceBetween(props['spaceBetweenSquares']);
  }

  setStrokeColor(color){
    this._strokeColor = color;
  }

  hideNumber(hide){
    if (hide === true || hide === false) this._hideNumber = hide;
  }

  setSpaceBetween(space){
    if (space >= 0) this._spaceBetweenSquares = space;
  }

  setSquareSize(size){
    if (size > 0) this._squareSize = size;
  }

  scaleBrightness(value, maxValue, minBright=0, maxBright=80){
    var value = this._scaleValue(value);
    var maxValue = this._scaleValue(maxValue);
    var brightness = 1 - (value/maxValue);
    var percentBrightness = (brightness * (maxBright - minBright)) + minBright;
    return percentBrightness;
  }

  centerCamera(label, zoom){    
    if (typeof(zoom) != 'number') return;
    if (this.properties.defaultCamera == true) return;
    if (typeof(label) != 'string' || label.length == 0) label = this.graphToMap.getProperties().centerNode;
    
    if (this.mindmap.hasLabel(concept)){
      console.log(`Error at centerCamera() : Label "${label}" was not found`);
      this.properties.defaultCamera = true;
      this.properties.centerCameraAround = null;
      return;
    }

    var concept = this.mindmap.getNodeInfoByLabel(label);

    var figSize = this._squareSize;
    var spaceBetween = this._spaceBetweenSquares;

    var xIndex = concept['x'];
    var yIndex = concept['y'];

    var xConcept = xIndex * (figSize + spaceBetween)
    var yConcept = yIndex * (figSize + spaceBetween)

    var x = this._width/2/zoom - figSize/2 - xConcept;
    x = x * zoom;
    var y = this._height/2/zoom - figSize/2 - yConcept;
    y = y * zoom;

    this.setInitialZoom(zoom);
    this.setInitialPosition(x,y);
  }


  plot(data) {
    const {
      centerCameraAround, initialZoom, categoryField, nameField
    } = this.getProperties();

    if (this.properties.defaultCamera === false){
      this.centerCamera(centerCameraAround, initialZoom);
    }

    const squareCurvature = 10;
    this.restartSVG();
    var This = this;
    var spaceBetween = this._spaceBetweenSquares;
    var squareSize = this._squareSize;
    const {container} = this.getComponents();

    var svg = container;
      
    var concepts = svg.selectAll("svg.concept")
      .data(data)
      .enter()
      .append("svg")
      .attr('class','concept')
      .attr('nodeId', d => d.id)
      .attr("height",squareSize)
      .attr("width",squareSize)
      .attr("x", function (d) { return d.x*(squareSize + spaceBetween) })
      .attr("y", function (d) { return d.y*(squareSize + spaceBetween) });

    concepts.on("mouseover", function(d){
      if (['highlight'].includes(This._actionOnClick)) return;
      var nodeId = this.getAttribute('nodeId');
      This._highlightNode(nodeId);
    });

    concepts.on("mouseout", function(d){
      if (['highlight'].includes(This._actionOnClick)) return;
      if (This._enableTooltip === true) This.tooltip.hide();
      var nodeId = this.getAttribute('nodeId');
      This._unHighlightNode(nodeId);
    });

    concepts.on('click', this._actionOnClickFunction);

    if (this._showActionButtons === true) wordplot.wordmap.interaction.initializeButtons(this);

    concepts.on('contextmenu', function(event){
      event.preventDefault();
      var nodeId = this.getAttribute('nodeId');
      var nodeInfo = This.graphToMap.mindmap.getNodeInfoById(nodeId);
      if ( !nodeInfo.hasOwnProperty('tooltipInfo')  && This._obtainTooltipData != null ) This._obtainTooltipData(event);
      This._showTooltipInfo(nodeId);
    });

    this.drawBorders();
    
    var maxValue = data.sort( 
    function(a, b) {return parseFloat(b['value']) - parseFloat(a['value']);})[0]['value']
      
    concepts.append("rect").attr("x","1%").attr("y","1%")
      .attr("height","98%").attr("width","98%")
      .attr("ry", squareCurvature)
      .attr("rx",squareCurvature)
      .attr("class","conceptSquare")
      .attr("fill", function(d){
        var value = d.value;
        var percentBrightness = This.scaleBrightness(value, maxValue);
        var color = This.getColor(d[categoryField]),
        color = wordplot.helpers.increaseBrightness(color, percentBrightness);
        return color
      })
    
    var text = concepts.append("text")
    .attr('class','textInside')
    .attr("x","50%")
    .attr("y","50%")
    .style('pointer-events','none')
    .attr("text-anchor","middle")
    .attr("dominant-baseline","middle")
    .attr("fill",function(d){
      var value = d.value;
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var color = This.getColor(d[categoryField]),
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      color = wordplot.helpers.invertColor(color);
      return color;
    }).text(function(d){
      var line = d[nameField];
      const conceptHasField = d.hasOwnProperty(This.valueFieldToShow);
      const isNumerical = !isNaN(d[This.valueFieldToShow]);
      if (!This._hideNumber && conceptHasField && isNumerical) line = line +'\t('+ d[This.valueFieldToShow] + ')';
      return line.replace(/_/g,' ');
    });

    this._setTextProperties(text);

    text.call(wordplot.helpers.wrapLines, This._squareSize);

    text.each(function(d){
      var text = d3.select(this)
      This._adjustTextHeight(text);
    });

    if (this._enableTooltip === true){
      this.tooltip.getComponents().div.raise();
    }

    this._boldNode(this._centralNodeId);
    
    wordplot.logo.initLogo(this);

    if (this.properties.defaultCamera === true){
      wordplot.wordmap.centerMap(this);
    }
  }

  _adjustTextHeight(text){
    var nLines = text.selectAll('tspan').size();
    if (nLines >= 6) text.attr('y','1em');
    if (nLines == 5) text.attr('y','15%');
    if (nLines == 4) text.attr('y','30%');
    if (nLines == 3) text.attr('y','35%');
    if (nLines == 2) text.attr('y','45%');
    if (nLines == 1) text.attr('y','50%');
  }

  drawBorders(){
    const { container } = this.getComponents();
    var concepts = container.selectAll('svg.concept')
    var sides = ['s1','s2','s3','s4','s5','s6','s7','s8'];
    for(var i=0; i < sides.length; i++){
      var side = sides[i];
      var filteredConcepts = concepts.filter(function(d){return d.hasOwnProperty(side) && d[side] === true; });
      this.drawBorder(filteredConcepts, side);
    }
  }

  drawBorder(concepts, side){
    const curvature = 0;
    switch (side) {
      case 's1':  // Up
        concepts.append('rect').attr('width','80%').attr('height','10%').attr('x','10%').attr('fill',this._strokeColor)
        break;
      case 's2':  // Right
        concepts.append('rect').attr('width','10%').attr('height','80%').attr('x','90%').attr('y','10%').attr('fill',this._strokeColor)
        break;
      case 's3': // Down
        concepts.append('rect').attr('width','80%').attr('height','10%').attr('x','10%').attr('y','90%').attr('fill',this._strokeColor)
        break;
      case 's4': // Left
        concepts.append('rect').attr('width','10%').attr('height','80%').attr('y','10%').attr('fill',this._strokeColor)
        break;
      case 's5': // Left Up
        concepts.append('rect').attr('width','10%').attr('height','10%').attr('rx',0).attr('ry',0).attr('fill',this._strokeColor)
        .attr('rx',curvature).attr('ry',curvature)
        break;
      case 's6': // Right Up
        concepts.append('rect').attr('width','10%').attr('height','10%').attr('x','90%').attr('rx',0).attr('ry',0).attr('fill',this._strokeColor)
        .attr('rx',curvature).attr('ry',curvature)
        break;
      case 's7': // Right Down
        concepts.append('rect').attr('width','10%').attr('height','10%').attr('y','90%').attr('x','90%').attr('fill',this._strokeColor)
        .attr('rx',curvature).attr('ry',curvature)
        break;
      case 's8': // Left Down
        concepts.append('rect').attr('width','10%').attr('height','10%').attr('y','90%').attr('fill',this._strokeColor)
        .attr('rx',curvature).attr('ry',curvature)
        break;
    }
  }

  _boldNode(nodeId){
    if (nodeId == null) return;
    if (this.graphToMap == null) return;
    if (!this.mindmap.hasId(nodeId)) return;
    
    const { categoryField } = this.getProperties();

    const { innerSVG } = this.getComponents();
    const { fontSize } = this.getProperties();

    const nodeSVG = innerSVG.select(`svg.concept[nodeId="${nodeId}"]`);
    nodeSVG.style('opacity','1');
    nodeSVG.selectAll('line').style('opacity','1');
    
    const nodeInfo = this.mindmap.getNodeInfoById(nodeId);
    const color = this.getColor(nodeInfo[categoryField]);
    const textColor = wordplot.helpers.invertColor(color);
    var colorAnimation = "#000000";
    if (textColor === colorAnimation){
      var colorAnimation = "#FFFFFF";
    }

    const animate = nodeSVG.select('rect.conceptSquare').selectAll('animate').data([null]).enter().append('animate')
    animate.attr('attributeName','fill')
      .attr('values',`${color};${colorAnimation};${color}`)
      .attr('repeatCount','indefinite')
      .attr('dur','3s');

    const nodeText = nodeSVG.select('text');
    nodeText.attr('fill', textColor);

    const textObject = nodeSVG.select('text');
    textObject.style('font-weight','bold');

    const textContent = nodeInfo.label.replace(/_/g,' ');
    const maxWidth = this._squareSize;
    // maxWidth is multiplied by 0.9 to adjust the text even more. getComputedTextLength function 
    // doesn't consider font-weight to compute the textLength.
    wordplot.helpers.readjustFontSize(textObject, textContent, fontSize, maxWidth * 0.9);
    this._adjustTextHeight(textObject);
  }

  _unHighlightNode(nodeId){
    if (this.graphToMap == null) return;
    const This = this;
    const neighbors = this.mindmap.getNeighborsById(nodeId);
    const {innerSVG} = this.getComponents();
    const allNodes = innerSVG.selectAll('svg.concept');

    const { nodes } = this.graphToMap.mindmap.getData();
    if (nodes.length == 0) return;

    var maxValue = nodes[0].value;
    var maxValue = this._scaleValue(maxValue);

    allNodes.style('opacity','1').style('font-weight','normal');
    allNodes.selectAll('line').style('opacity','1');
    allNodes.selectAll('rect').style('opacity','1');

    const { categoryField } = this.getProperties();

    [nodeId].concat(neighbors).forEach(function(nodeId){
      const nodeInfo = This.mindmap.getNodeInfoById(nodeId);
      const nodeSVG = innerSVG.select(`svg.concept[nodeId="${nodeId}"]`);
      var value = This._scaleValue(nodeInfo['value']);
      var percentBrightness = This.scaleBrightness(value, maxValue);
      var color = This.getColor(nodeInfo[categoryField]),
      color = wordplot.helpers.increaseBrightness(color, percentBrightness);
      nodeSVG.select('rect.conceptSquare').attr('fill',color);
    });

    this._boldNode(this._centralNodeId);
  }
}

class SquareGraphProcessor extends GraphProcessor{
  constructor(){
    super('square');
    this.adjacentCoordinatesOrder = ['s2','s3','s4','s1','s7','s6','s8','s5'];
  }

  getNeighborsCoordinates(center){
    const x = center[0];
    const y = center[1];
    const neighborCoordinates = [];
  
    const coordinates = {
      s1: [x,y-1],
      s2: [x+1,y],
      s3: [x,y+1],
      s4: [x-1,y],
      s5: [x-1,y-1],
      s6: [x+1,y-1],
      s7: [x+1,y+1],
      s8: [x-1,y+1]
    }
    
    for (var i=0; i < this.adjacentCoordinatesOrder.length; i++){
      const side = this.adjacentCoordinatesOrder[i];
      const coordinate = coordinates[side];
      neighborCoordinates.push(coordinate);
    }

    return neighborCoordinates;
  }

  getSurroundingIds(center,matrix){
    var ids = {};
    var coords = this.getNeighborsCoordinates(center)
    var sidesOrder = this.adjacentCoordinatesOrder;
  
    for (var i=0; i<coords.length; i++){
      var c = coords[i],    // c = [cx,cy]
          x = c[0],
          y = c[1];
  
      if (x >= 0 && y >=0 && y < matrix.length && x < matrix[0].length){
        var id = matrix[y][x];
        if (id != -1){
          var label = sidesOrder[i]
          ids[label] = id;    // E.j. ids['s1'] == ID
        }
      }
    }
    return ids;
  }
}
class BasicLineChart extends AxisVisualization{
  constructor(plotId, props={}){
    const DEFAULT_PROPS = {
      strokeWidth: 3.5,          // Width of the Lines
      confidenceOpacity: 0.3,    // Opacity of the confidence areas
      boxOpacity: 0.7,           // Opacity of the InfoBox of each point
      boxSize: [80,40],          // [width, height] of the InfoBox
      lineOpacity: 0.9,
      valuesField: 'demand',
      errorField: 'error_factor',
      detailBoxParent: 'body',
      width: 700,
      height: 500,
      margin: {top: 40, right: 30, bottom: 20, left: 60}
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_PROPS, props);
    super(plotId, props);
    this._initProperties(props);
  }

  _initProperties(props){
    super._initProperties(props);
    this.timeLabels = [];

    const {
      strokeWidth,
      confidenceOpacity,
      boxOpacity,
      lineOpacity,
      boxSize,
      valuesField,
      errorField,
      detailBoxParent,
      timeLabels,
    } = props
    this.setStrokeWidth(strokeWidth);
    this._confidenceOpacity = confidenceOpacity;
    this._boxOpacity = boxOpacity;
    this._lineOpacity = lineOpacity;
    this._boxSize = boxSize;
    this.setValuesField(valuesField);
    this.setErrorField(errorField);
    //this.setDetailBoxParent(detailBoxParent);
    //this._detailBoxId = 'detailBox' + '_' + this.plotId;
    this.setTimeLabels(timeLabels);
  }
  
  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('strokeWidth')) this.setStrokeWidth(props['strokeWidth']);
    if (props.hasOwnProperty('confidenceOpacity')) this._confidenceOpacity = props['confidenceOpacity'];
    if (props.hasOwnProperty('boxOpacity')) this._boxOpacity = props['boxOpacity'];
    if (props.hasOwnProperty('boxSize')) this.setBoxSize(props['boxSize']);
    if (props.hasOwnProperty('lineOpacity')) this._lineOpacity = props['lineOpacity'];
    if (props.hasOwnProperty('valuesField')) this.setValuesField(props['valuesField']);
    if (props.hasOwnProperty('nameField')) this.setNameField(props['nameField']);
    if (props.hasOwnProperty('errorField')) this.setErrorField(props['errorField']);
    //if (props.hasOwnProperty('detailBoxParent')) this.setDetailBoxParent(props['detailBoxParent']);
    if (props.hasOwnProperty('timeLabels')) this.setTimeLabels(props['timeLabels']);
  }

  setTimeLabels(labels){
    if (labels == null || !Array.isArray(labels) || labels.length == 0) return;
    this.timeLabels = labels;
  }

  cleanSVG(){
    super.cleanSVG();
    //this._destoyDetailBox();

  }

  _initContainer(){
    super._initContainer();
  }

  setStrokeWidth(width){
    if ( !isNaN(width) && width > 0 ) this._strokeWidth = width;
  }

  setErrorField(name){
    this._errorField = name;
  }

  setValuesField(name){
    this._valuesField = name;
  }

  setBoxSize(width, height){
    if (width >= 0 && height >= 0){
      this._boxSize = [width, height];
      this._detailBox.style('height',height)
      .style('width',width)
    }
  }
  
  _hideCircles(){
    const { container } = this.getComponents();
    var circles = container.selectAll('circle.tipcircle');
    circles.style('opacity',0);
  }

  _restoreSeries(){
    const { outerSVG , container } = this.getComponents();
    outerSVG.selectAll('g[id=legend]').style('opacity',1);
    container.selectAll('path[id=line]')
      .style('stroke-width', this._strokeWidth + 'px')
      .style('opacity', this._lineOpacity)
    container.selectAll('path[id=confidence]')
      .style('opacity', this._confidenceOpacity);
  }

  _highlightSeries(concept){
    var thisPlot = this;
    const { outerSVG , container } = this.getComponents();
    container.selectAll('path#line')
      .style('stroke-width',function(){return (this.getAttribute('concept') === concept)? '6px': thisPlot._strokeWidth})
      .style('opacity',function(){return (this.getAttribute('concept') === concept)? 1: 0.1})
    container.selectAll('path[id=confidence]')
      .style('opacity',function(a){return (this.getAttribute('concept') === concept)? 0.5: 0.05});
    outerSVG.selectAll('g[id=legend]')
      .style('opacity',function(a){return (this.getAttribute('concept') === concept)? 1: 0.05});
  }

  _highlightCircles(concept){
    const { container } = this.getComponents();
    var circles = container.selectAll('circle.tipcircle');
    circles.filter(function(){return this.getAttribute('concept') == concept}).style('opacity',0.6);
  }

  /**
   *  size = [width, height]
   */
  plot(data,labels=null, title=null){
    this.restartSVG();

    if (data == null || !Array.isArray(data) || data.length == 0){
      this.showMessage('Error at SimpleLineChart.plot() : input data is invalid');
      return;
    }

    if (data.length == 0){
      this.showMessage('Empty data');
      return;
    }
    
    this.setTimeLabels(labels);
    labels = this.timeLabels;

    if (labels == null || !Array.isArray(labels) || labels.length < data.length){
      console.log(`Warning at SimpleLineChart.plot() : ignoring labels ${labels} -> invalid format`);
      labels = []
      for (var i=0; i < data.length; i++){
        labels.push(i);
      }
    }

    this._plotData = data;
    //this._createDetailBox();
    var thisPlot = this;

    var strokeWidth = this._strokeWidth;
    var confidenceOpacity = this._confidenceOpacity;
    var valuesField = this._valuesField;

    var {
      width, height, innerWidth, innerHeight, margin,
      nameField,
    } = this.getProperties();

    var detailBox = this._detailBox;
    
    const { outerSVG , container } = this.getComponents();
    var svg = container;
    var mainSVG = outerSVG;

    // Initialization of Domain and Range in X and Y
    var xDomain = labels.slice(0,data.length);
    var xRange = []
    for (var i=0; i < xDomain.length; i++){
      var xPos = innerWidth / (xDomain.length - 1) * i;
      xRange.push(xPos);
    }

    var yDomain = [Math.min.apply(null,data), Math.max.apply(null,data)];
    var yRange = [innerHeight, 0];
    // End

    var x = d3.scaleOrdinal()
      .domain(xDomain)
      .range(xRange);

    var y = d3.scaleLinear()
      .domain(yDomain)
      .range(yRange);

    svg.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('id','line')
        .attr('stroke', 'black')
        .attr('d', d3.line()
          .x(function(d,i) { return x(labels[i]) })
          .y(function(d) { return y(d) })
          )
        .attr('stroke-width', strokeWidth + 'px')
        .attr('opacity', thisPlot._lineOpacity);

    svg.selectAll('circle')
      .data(data).enter()
      .append('circle')
      .attr('value',function(d){return d.value})
      .attr('class','tipcircle')
      .attr('cx', function(d,i){return x(labels[i]) })
      .attr('cy',function(d){return y(d)})
      .attr('r',8)
      .style('opacity', 1)
      .style('fill',"black");

    var yAxis = d3.axisLeft(y).ticks(5);

    var xAxis = d3.axisBottom()
    .tickFormat(d3.format('.0f'))
    .scale(x);
        
    this.addAxis(yAxis, 'left');

    this.addAxis(xAxis,'bottom');
  }
}
var line = ( function(){

class LineChart extends AxisVisualization{
	constructor(plotId, props={}){
		const DEFAULT_PROPS = {
      strokeWidth: 3.5,          // Width of the Lines
      confidenceOpacity: 0.3,    // Opacity of the confidence areas
      boxOpacity: 0.7,           // Opacity of the InfoBox of each point
      boxSize: [80,40],          // [width, height] of the InfoBox
      lineOpacity: 0.9,
      valuesField: 'demand',
      nameField: 'displayname',
      errorField: 'error_factor',
      detailBoxParent: 'body',
      width: 700,
      height: 500,
      margin: {top: 10, right: 30, bottom: 20, left: 60}
    }
    props = wordplot.helpers.mergeDictionaries(DEFAULT_PROPS, props);
    super(plotId, props);
    this._initProperties(props);
  }

  _initProperties(props){
    super._initProperties(props);
    const {
      strokeWidth,
      confidenceOpacity,
      boxOpacity,
      lineOpacity,
      boxSize,
      valuesField,
      errorField,
      detailBoxParent
    } = props
    //this.setSize(width,height);
    //this.enableZoom(false);
    //this.setMargin(margin);
    this.setStrokeWidth(strokeWidth);
    this._confidenceOpacity = confidenceOpacity;
    this._boxOpacity = boxOpacity;
    this._lineOpacity = lineOpacity;
    this._boxSize = boxSize;
    this.setValuesField(valuesField);
    this.setErrorField(errorField);
    this.setDetailBoxParent(detailBoxParent);
    this._detailBoxId = 'detailBox' + '_' + this.plotId;
  }
  
  setProperties(props){
    super.setProperties(props);
    if (props.hasOwnProperty('strokeWidth')) this.setStrokeWidth(props['strokeWidth']);
    if (props.hasOwnProperty('confidenceOpacity')) this._confidenceOpacity = props['confidenceOpacity'];
    if (props.hasOwnProperty('boxOpacity')) this._boxOpacity = props['boxOpacity'];
    if (props.hasOwnProperty('boxSize')) this.setBoxSize(props['boxSize']);
    if (props.hasOwnProperty('lineOpacity')) this._lineOpacity = props['lineOpacity'];
    if (props.hasOwnProperty('valuesField')) this.setValuesField(props['valuesField']);
    if (props.hasOwnProperty('nameField')) this.setNameField(props['nameField']);
    if (props.hasOwnProperty('errorField')) this.setErrorField(props['errorField']);
    if (props.hasOwnProperty('detailBoxParent')) this.setDetailBoxParent(props['detailBoxParent']);
  }

  cleanSVG(){
    super.cleanSVG();
    this._destoyDetailBox();

  }

  _initContainer(){
    super._initContainer();
  }

	setStrokeWidth(width){
		if ( !isNaN(width) && width > 0 ) this._strokeWidth = width;
	}

	_createDetailBox(){
    const { fontFamily, fontSize } = this.getProperties();

    var detailBox = d3.select(this._detailBoxParent).selectAll('#' + this._detailBoxId)
    .data([null]).enter()
    .append('div')
	  .attr('id',this._detailBoxId)
	  .attr('class', 'detailBox')
	  .style('opacity', 0)
	  .style('font-size', fontSize + 'px')
	  .style('font-family', fontFamily)
	  .style('font-weight', 'bold')
	  .style('width', this._boxSize[0] + 'px')
	  .style('height', this._boxSize[1] + 'px')
	  .style('text-align','center')
	  .style('position','absolute')
	  .style('border-radius', '6px')
    .style('border','0px')
    .style('display', 'inline')
	  .style('pointer-events','none')
	  .style('line-height',1.3);
	  this._detailBox = detailBox;
	}

	_destoyDetailBox(){
		d3.selectAll('div#' + this._detailBoxId).remove();
	}

	setDetailBoxParent(p){
		this._detailBoxParent = p;
	}

	setErrorField(name){
		this._errorField = name;
	}

	setValuesField(name){
		this._valuesField = name;
	}

	setBoxSize(width, height){
		if (width >= 0 && height >= 0){
			this._boxSize = [width, height];
			this._detailBox.style('height',height)
			.style('width',width)
		}
	}

	buildDate(year,month){
		var date = `${year}/${month}/01`
		date = new Date(date);
		return date;
  }
  
  _hideCircles(){
    const { container } = this.getComponents();
    var circles = container.selectAll('circle.tipcircle');
    circles.style('opacity',0);
  }

  _restoreSeries(){
    const { outerSVG , container } = this.getComponents();
    outerSVG.selectAll('g[id=legend]').style('opacity',1);
    container.selectAll('path[id=line]')
      .style('stroke-width', this._strokeWidth + 'px')
      .style('opacity', this._lineOpacity)
    container.selectAll('path[id=confidence]')
      .style('opacity', this._confidenceOpacity);
  }

  _highlightSeries(concept){
    var thisPlot = this;
    const { outerSVG , container } = this.getComponents();
    container.selectAll('path#line')
      .style('stroke-width',function(){return (this.getAttribute('concept') === concept)? '6px': thisPlot._strokeWidth})
      .style('opacity',function(){return (this.getAttribute('concept') === concept)? 1: 0.1})
    container.selectAll('path[id=confidence]')
      .style('opacity',function(a){return (this.getAttribute('concept') === concept)? 0.5: 0.05});
    outerSVG.selectAll('g[id=legend]')
      .style('opacity',function(a){return (this.getAttribute('concept') === concept)? 1: 0.05});
  }

  _highlightCircles(concept){
    const { container } = this.getComponents();
    var circles = container.selectAll('circle.tipcircle');
    circles.filter(function(){return this.getAttribute('concept') == concept}).style('opacity',0.6);
  }

	/**
	 *  size = [width, height]
	 */
	plot(data){
    this.restartSVG();
	  this._createDetailBox();
    var thisPlot = this;

    var strokeWidth = this._strokeWidth;
    var confidenceOpacity = this._confidenceOpacity;

    const { nameField } = this.getProperties();
    var valuesField = this._valuesField;
    var errorField = this._errorField;

    var {
      width, height, innerWidth, innerHeight,
      margin,
    } = this.getProperties();

    var detailBox = this._detailBox;
    
    const { outerSVG , container } = this.getComponents();
    var svg = container;
    var mainSVG = outerSVG;
	  var x = d3.scaleTime()
		  .domain([
		    d3.min(data, function(c) { return d3.min(c[valuesField], function(v) { return thisPlot.buildDate(v.year, v.month); }); }),
	    	d3.max(data, function(c) { return d3.max(c[valuesField], function(v) { return thisPlot.buildDate(v.year, v.month); }); })
		  ])
	  	  .range([0, innerWidth]);

	  var y = d3.scaleLinear()
	    .domain([Math.min(0, d3.min(data,function(c){ return d3.min(c[valuesField], function(v){ return Number(v.value)})})),
				 d3.max(data,function(c){ return d3.max(c[valuesField], function(v){ 
          var value = Number(v.value)
          if (v.hasOwnProperty(errorField)) value += Number(v[errorField]);
          return value;
          })})])
	    .range([innerHeight, 0]);

	  svg.selectAll('g').data(data).enter().append('g')
	  .each(function(item, index){
        // Show confidence interval
      d3.select(this).append('path')
      .datum(item[valuesField])
      .attr('fill', thisPlot.getColor(index))
      .attr('stroke', 'none')
      .style('opacity',confidenceOpacity)
      .attr('id','confidence')
      .attr('concept',item[nameField])
      .attr('d', d3.area()
        .x(function(d) { return x(thisPlot.buildDate(d.year, d.month)) })
        .y0(function(d) {
          var error = (d.hasOwnProperty(errorField))?d[errorField]:0;
          var n = y(Number(d.value) + Number(error)); return n })
        .y1(function(d) {
          var error = (d.hasOwnProperty(errorField))?d[errorField]:0;
          var n = y(Number(d.value) - Number(error)); return n })
      )
	  }).each(function(item, index){
			d3.select(this).append('path')
		      .datum(item[valuesField])
		      .attr('fill', 'none')
		      .attr('id','line')
		      .attr('stroke', thisPlot.getColor(index))
		      .attr('concept',item[nameField])
		      .attr('d', d3.line()
		        .x(function(d) { return x(thisPlot.buildDate(d.year, d.month)) })
		        .y(function(d) { return y(d.value) })
		        )
          .attr('stroke-width', strokeWidth + 'px')
          .attr('opacity', thisPlot._lineOpacity)
		      .on('mouseover', function(b){
            var concept = this.getAttribute('concept')
            thisPlot._highlightSeries(concept);
		      })
		      .on('mouseout', function(d){
            thisPlot._restoreSeries();
		      })
	  }).each(function(item, index){
      d3.select(this).selectAll('circle')
        .data(item[valuesField]).enter()
        .append('circle')
        .attr('value',function(d){return d.value})
        .attr('class','tipcircle')
        .attr('concept',item[nameField])
        .attr('cx', function(d){return x(thisPlot.buildDate(d.year, d.month)) })
        .attr('cy',function(d){return y(d.value)})
        .attr('r',8)
        .style('opacity', 0)
        .style('fill',thisPlot.getColor(index))
        .attr ('title', item[nameField])
        .on('mouseover', function(){
          var concept = this.getAttribute('concept');
          thisPlot._highlightCircles(concept);
          thisPlot._highlightSeries(concept);
          detailBox.style('opacity', thisPlot._boxOpacity);
        })
        .on('mousemove', function(d){
          var year = this.__data__.year;
          var month = this.__data__.month;
          var value = this.__data__.value;
          var date = thisPlot.buildDate(year,month).toLocaleString('en', { month: 'short', year:'numeric' })					
          detailBox.html(function(){return `${date} <br> ${value}`;})
            .style('color', wordplot.helpers.invertColor(thisPlot.getColor(index)))
            .style('left', (window.event.pageX) + 'px')
            .style('top', (window.event.pageY) - 50 + 'px')
            .style('background-color',thisPlot.getColor(index))
        })
        .on('mouseout', function(){
          detailBox.style('opacity',0);
          thisPlot._restoreSeries();
          thisPlot._hideCircles();
        });
    })

	  var xAxis = d3.axisBottom()
      .scale(x)
      .tickFormat(d3.timeFormat('%b %Y'))
      .ticks(6);

    var yAxis = d3.axisLeft(y).ticks(5);
        
    this.addAxis(xAxis, 'bottom');
    this.addAxis(yAxis, 'left');

	  // Legend
	  var legend = function(svg){
      const g = svg
      .attr('transform', `translate(${width},0)`)
      .attr('text-anchor', 'end')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .attr('concept',function(d,i){return d[nameField]})
      .attr('id','legend');
	    
      g.append('rect')
        .attr('x', -19)
        .attr('width', 19)
			  .attr('height', 19)
			  .attr('fill', function(d,i){return thisPlot.getColor(i)})
        .on('mouseover', function(e,d){
          var concept = d[nameField];
          thisPlot._highlightSeries(concept);
			  })
			  .on('mouseout', function(d){
          thisPlot._restoreSeries();
			  });
	    
      var legendText = g.append('text')
        .attr('x', -24)
        .attr('y', 9.5)
        .attr('dy', '0.35em')
        .text(function(l){return l[nameField]});

        thisPlot._setTextProperties(legendText);
	    }
	    mainSVG.append('g').call(legend);
	}
}

class LineChartGroup{
	constructor(plotId, data, props={}){
    const {elemsPerGroup} = props;
    this.data = data;
		this.elemsPerGroup = elemsPerGroup;
		this.lineChart = new LineChart(plotId, props);
		this.plotId = plotId;
		this.cleanSVG();
		this.createDropDownMenu(data);
		this.refreshGraphic();
  }
  
  setProperties(props){
    this.lineChart.setProperties(props);
    this.refreshGraphic();
  }

	setNameField(name){
		this.lineChart.properties.nameField = name;
		this.refreshGraphic();
	}

	setDetailBoxParent(p){
		this.lineChart.setDetailBoxParent(p);
  }
  
  getComponents(){
    return this.lineChart.getComponents();
  }

	updateGroups(){
	  var nElements = this.data.length
	  var nGroups = Math.ceil(nElements / this.elemsPerGroup);
	  var groupList = document.getElementById('groupList');
	  for (var i=0; i<nGroups; i++){
	  	var start = 1 + i * this.elemsPerGroup;
	  	var end = (i + 1) * this.elemsPerGroup;
	  	var end = Math.min(end, nElements);
	  	var groupName = 'Top ' + start + '-' + end;
	  	var groupValue = i;  // Index of the group
	  	var group = document.createElement('option');
	  	group.value = groupValue;
	  	group.text = groupName;
	  	groupList.appendChild(group);
	  }
	}

	createDropDownMenu(){
		var This = this;
    const { fontFamily , fontSize } = This.lineChart.getProperties();
		const {div} = this.lineChart.getComponents()
		div.insert('br',':first-child').attr('id','groupList');
		div.insert('select',':first-child')
			.attr('id','groupList')
			.style('font-family',fontFamily)
			.style('font-size',fontSize)
			.on('change', function(d){This.refreshGraphic()});
		this.updateGroups();
	}

  refresh(){
    this.refreshGraphic();
  }

  getStorableComponent(){
    return this.lineChart.getStorableComponent();
  }

	refreshGraphic(){
		var i = Number(document.getElementById('groupList').value);
  	var start = i * this.elemsPerGroup;
    var end = (i + 1) * this.elemsPerGroup;
		var elems = this.data.slice(start, end);
		this.lineChart.plot(elems);
  }

	cleanSVG(){
		d3.selectAll('#groupList').remove();
		this.lineChart.cleanSVG();
	}
}

function buildLineChart(plotId, json, plotType, props={}){
  var plotType = plotType.toLowerCase();
  var json = wordplot.json.normalizeStatisticsJson(json);
  var plot = null;

  // Verify plotType
  if (!['linechart','linechartgroup'].includes(plotType)){
    console.log('Error at BuildLineChart() invalid plotType ' + plotType);
    return null;
  }

  if (plotType == 'linechart'){
    json = wordplot.json.normalizeStatisticsJson(json);
    plot = new LineChart(plotId, props);
    plot.setPlotData(json.data)
  } else if (plotType == 'linechartgroup'){
    plot = new LineChartGroup(plotId, json.data, props);
  }
  return plot;
}

return {
    LineChart,
    LineChartGroup,
    BasicLineChart,
    buildLineChart,
}
})();var top = ( function(){

class TopChart {
    constructor(plotId, data, props){
        this.plotId = plotId;
        this._topChartId = 'topChart';
        this._miniTopChartId = 'miniTopChart';
        this._detailChartId = 'detailChart';
        this._data = data;
        this._initContainers();
        var orientation = 'horizontal';
        this._topChart = new wordplot.bars.BasicBarChart(this._topChartId, {orientation});
        this._miniTopChart = new wordplot.bars.BasicBarChart(this._miniTopChartId, {orientation});
        this._detailChart = new wordplot.line.LineChart(this._detailChartId);
        this.setNameField('name');
        this.setValuesField('values');
        this.setProperties(props);
        this.refresh();
    }

    setProperties(props){
        if (props == null) return;
        if (props.constructur == Object) return;

        const {
            width,
            height,
            maxWidth,
            minWidth,
        } = props;

        const commonProperties = {
            width,
        };

        const responsiveMode = (maxWidth != null && minWidth != null);

        if (responsiveMode){
            commonProperties['maxWidth'] = null;
            commonProperties['minWidth'] = null;
        }

        this._setCommonProperties(commonProperties);

        if (responsiveMode) this.setSize(minWidth, maxWidth);

        /** */

        const topChartProps = {
            height,
            //margin: {left:200}
        }
        
        const detailChartProps = {
            height: height - (height / this._data.length),
            margin: {left: 60, top:0, right:0, bottom: 40},
        }
    /** 
        miniTopChartProps = {
        margin: {left:200, bottom:0, top:0, right:30}
        }**/

        
        this.setTopChartProperties(topChartProps);
        this.setDetailChartProperties(detailChartProps);
          //plot.setMiniTopChartProperties(miniTopChartProps);
    }

    refresh(){
        this.plotTopChart();
    }

    setTopChartProperties(props){
        this._topChart.setProperties(props);
        this.plotTopChart();
    }

    setSize(minWidth, maxWidth){
        const {container} = this.getComponents();
        container.style('min-width',minWidth)
        .style('max-width',maxWidth)
        .style('width','100%')
        .style('height','unset')

        this._topChart.enableAutoResize(true);
        this._detailChart.enableAutoResize(true);
        this._miniTopChart.enableAutoResize(true);
        this.refresh();
    }

    setDetailChartProperties(props){
        this._detailChart.setProperties(props);
        this.refresh();
    }

    setMiniTopChartProperties(props){
        this._miniTopChart.setProperties(props);
        this.refresh();
    }

    _setCommonProperties(props){
        this._miniTopChart.setProperties(props);
        this._detailChart.setProperties(props);
        this._topChart.setProperties(props);
        this.refresh();
    }

    cleanSVG(){
		var svg = d3.select('div#'+this.plotId)
		svg.selectAll('*').remove()
	}

    setNameField(name){
        this._topChart.setNameField(name);
        this._detailChart.setNameField(name);
        this._miniTopChart.setNameField(name);
        this.refresh();
    }

    setValuesField(name){
        this._detailChart.setValuesField(name);
        this.refresh()
    }

    setValueField(name){
        this._topChart.setValueField(name);
        this._detailChart.setValueField(name);
        this._miniTopChart.setValueField(name);
        this.refresh()
    }

    getComponents(){
        var container = d3.select('div#' + this.plotId);
        var divDetailChart = container.select('div#' + this._detailChartId);
        var divMiniTopChart = container.select('div#' + this._miniTopChartId);
        var divTopChart = container.select('div#' + this._topChartId);
        return {container, divDetailChart, divTopChart, divMiniTopChart}
    }

    _initContainers(){
        const container = d3.select('div#'+this.plotId);
        container
        .selectAll('div#' + this._topChartId)
        .data([null]).enter()
        .append('div')
        .attr('id',this._topChartId);

        container
        .selectAll('div#' + this._miniTopChartId)
        .data([null]).enter()
        .append('div')
        .attr('id',this._miniTopChartId);

        container.selectAll('br.spaceBetween')
        .data([null]).enter()
        .append('br').attr('class','spaceBetween');
        
        container
        .selectAll('div#' + this._detailChartId)
        .data([null]).enter()
        .append('div')
        .attr('id',this._detailChartId);
    }

    _resetTopChart(){
        const {divTopChart} = this.getComponents();
        divTopChart.style('width',0)
        .style('height',0);
        this._topChart.cleanSVG();
    }

    _resetDetailChart(){
        const {divDetailChart} = this.getComponents();
        divDetailChart.style('width',0)
        .style('height',0);
        this._detailChart.cleanSVG();
    }

    _resetMiniTopChart(){
        const {divMiniTopChart} = this.getComponents();
        divMiniTopChart.style('width',0)
        .style('height',0);
        this._miniTopChart.cleanSVG();
    }

    _setClickActionRectangles(state='inactive'){
        var thisPlot = this;
        d3.selectAll('rect[id=bar]')
        .attr('state',state)
        .on('click',function(event,item){
            var thisRect = d3.select(this);
            var color = thisRect.attr('fill');
            thisPlot._detailChart.setColors([color]);
            thisPlot._miniTopChart.setColors([color]);
            if (thisRect.attr('state') == 'active'){
                thisPlot.plotTopChart();

            } else {
                thisRect.attr('active', 'inactive');
                thisPlot.plotMiniTopChart(item);
                thisPlot.plotDetailChart([item]);
            }
        });
    }

    plotDetailChart(data){
        this._detailChart.plot(data);
    }

    plotMiniTopChart(item){
        this._resetTopChart();
        var smallData = [item];
        var width = this._topChart._width;
        var height = this._topChart._height / this._data.length;
        this._miniTopChart.setSize(width, height);
        this._miniTopChart.plot(smallData);
        this._setClickActionRectangles('active');
    }

    plotTopChart(){
        this._resetDetailChart();
        this._resetMiniTopChart();
        this._topChart.plot(this._data);
        this._setClickActionRectangles();
    }

    getStorableComponent(){
        var {div, outerSVG} = this._topChart.getComponents();
        if (div.style('width') == '0px'){
          var {outerSVG} = this._detailChart.getComponents();
          var svgToPlot = outerSVG;
        } else {
          var svgToPlot = outerSVG;
        }
        return svgToPlot
    }
}

function processData(json){
    var data = json;
    if (json.hasOwnProperty('data')){
        data = json.data;
    }

    var processedData = [];
    data.forEach(function(d){
      var item = {};
      item.name = d.displayname;
      item.value = d.demand.filter(i => i.origin == 'data').map(i => i.value).reduce((prev, next) => Number(prev) + Number(next));
      item.values = d.demand;
      processedData.push(item);
    });

    processedData = wordplot.helpers.sortArrayOfObjects(processedData, 'value');
    return processedData;
}

function buildTopChart(plotId, json, props){
    var processedData = processData(json);
    const plot = new wordplot.top.TopChart(plotId, processedData, props);
    return plot;
}

return {
    TopChart,
    processData,
    buildTopChart,
}

})();var web = ( function(){
  function downloadObjectAsJsonFile(object, fileName="data.json"){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(object));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", fileName);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  function getParamsFromCurrentUrl(){
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    urlParams.forEach((value,key) => {
      params[key] = value;
    })
    return params;
  }

  return {
    downloadObjectAsJsonFile,
    getParamsFromCurrentUrl
  }
})();class Form{
    constructor(plotId, formInfo){
      this.plotId = plotId;
      this._formInfo = formInfo;
      this._addCSS();
      this._buildForm();
      this._buildParamToType();
    }

    getComponents(){
      const div = d3.select('div#' + this.plotId);
      var components = {div};
      Object.keys(this.paramToType).forEach(param => {
        const paramDiv = div.select('div#' + param);
        components[param] = paramDiv;
      })
      return components;
    }
  
    _buildParamToType(){
      var paramToType = {};
      this._formInfo.forEach(d => paramToType[ d.identifier ] = d.type)
      this.paramToType = paramToType;
    }

    _addCSS(){
      if (d3.selectAll('style.form_css').size() !== 0) return;
      d3.select('body').append('style').attr('class','form_css')
      .html(
      '.sameLine::after{' +
      'content:"\\a";' +
      'white-space: pre !important }');
      d3.select('body').append('style').attr('class','form_css')
      .html(
      '.tooltip_title{' +
      'visibility: hidden;' + 
      'position: absolute;' +
      'width: 130px;' +
      'background-color: #555;' +
      'color: #fff;' +
      'text-align: center;' +
      'padding: 5px 0;' +
      'font-size: 12px;' +
      'border-radius: 6px;' +
      'z-index: 1;' +
      'opacity: 0;' +
      'transition: opacity 0.6s;' +
      '}');
    }
  
    _buildForm(){
      const formInfo = this._formInfo;
      const container = d3.select('#' + this.plotId);
      const div = container.selectAll('div')
        .data(formInfo)
        .enter()
        .append('div')
        .attr('id', param => param.identifier)
        .attr('type', param => param.type )
        .style('display', function(d){
          if (d.hasOwnProperty('hidden') && d.hidden === true){
            return 'none'
          } else {
            return 'unset';
          }
        });
  
      const titles = div.append('h3')
        .text(param => param.title)
        .style('margin-top','1em')
        .style('margin-bottom','0.7em');

      const tooltipDiv = titles.filter(d => d.hasOwnProperty('tooltip')).append('div')
        .text('❔')
        .style('position','relative')
        .style('display','inline-block')
        .style('border-bottom','1px dotted #ccc')
        .style('color','#006080')
        .on('mouseover', function(){d3.select(this).select('span').style('opacity',1).style('visibility','visible')})
        .on('mouseout', function(){d3.select(this).select('span').style('opacity',0).style('visibility','hidden')})
      
      tooltipDiv.append('span')
        .attr('class','tooltip_title')
        .text( d => d.tooltip);
  
      const textParams = div.filter(d => d.type == 'text');
      textParams.append('input')
      .style('width','unset')
      .attr('placeholder', data => data.hasOwnProperty('placeholder')? data.placeholder : null );

      const passwordParams = div.filter(d => d.type == 'password');
      passwordParams.append('input')
      .attr('type','password')
      .attr('autocomplete','off')
      .style('width','unset');
  
      const checkBoxParams = div.filter(d => d.type == 'checkbox');
      this._addOptionsToCheckbox(checkBoxParams);
  
      const selectParams = div.filter(d => d.type == 'select');
      this._addOptionsToSelect(selectParams);
    }
  
    _addOptionsToCheckbox(div){
      const thisForm = this;
      const paramDiv = div.selectAll('div.sameLine')
        .data(d => d.options)
        .enter()
        .append('div')
        .attr('class','sameLine')
        .style('display','inline');

      const checkBox = paramDiv.append('input')
        .attr('type','checkbox')
        .attr('value',d => d.value)
        .style('display','inline !important')
        .style('margin-right','5px');

      checkBox.on("click", function(e,data){thisForm._boxClicked(this,data,thisForm)});
      const title = paramDiv.append('div').text(d => d.label).style('display','inline');
      title.on("click", function(e,data){thisForm._titleClicked(this,data,thisForm)});
    }
  
    _addOptionsToSelect(div){
      const thisForm = this;
      const selectDiv = div.append('select')
        .style('width','unset');
  
      selectDiv.selectAll('option')
        .data(d => d.options)
        .enter()
        .append('option')
        .attr('value', d => d.value)
        .text(d => d.label);
    }
  
    _titleClicked(element, data){
      const optionDiv = d3.select(element.parentNode);
      const checkBox = optionDiv.select('input').node();
      checkBox.checked = !checkBox.checked;
      const paramDiv = d3.select(element.parentNode.parentNode);
      this._onlyOneCheckBox(checkBox, data, paramDiv);
    }
  
    _boxClicked(element, data){
      const paramDiv = d3.select(element.parentNode.parentNode);
      this._onlyOneCheckBox(element, data, paramDiv);
    }
  
    _onlyOneCheckBox(element, data, paramDiv){
      const thisForm = this;
      const options = paramDiv.selectAll('input').filter(d => d.value != data.value);
      options.each( function(d){ thisForm._hideParameters(d.hasOwnProperty('show')?d.show:[])});
      options.nodes().forEach( option => option.checked = false);
      const paramsToShow = (data.hasOwnProperty('show'))? data.show : []
      if (element.checked === true){
        this._showParameters(paramsToShow);
      } else {
        this._hideParameters(paramsToShow);
      }
    }
  
    _showParameters(params){
      const thisForm = this;
      params.forEach(function(paramId){
        d3.select('div#' + paramId).style('display','unset');
        const selectedParamInfo = thisForm._getInfoSelectedValue(paramId);
        if (selectedParamInfo != null){
          const paramsToShow = selectedParamInfo.hasOwnProperty('show')? selectedParamInfo.show : [];
          thisForm._showParameters(paramsToShow);
        }
      });
    }
  
    _hideParameters(params){
      const thisForm = this;
      params.forEach(function(paramId){
        const selectedParamInfo = thisForm._getInfoSelectedValue(paramId);
        if (selectedParamInfo != null){
          const paramsToHide = selectedParamInfo.hasOwnProperty('show')? selectedParamInfo.show : [];
          thisForm._hideParameters(paramsToHide);
        }
        d3.select('div#' + paramId).style('display','none');
      }); 
    }
  
    getSelectedValue(parameter){
      if (!this.paramToType.hasOwnProperty(parameter)){
        console.log("Parameter " + parameter + " doesn't exist in the form");
        return '';
      }
      if (d3.select('div#' + parameter).style('display') == 'none') return '';
      const paramType = this.paramToType[parameter];
      switch(paramType){
        case 'checkbox':
          return this._getValueCheckBox(parameter);
        case 'text':
        case 'password':
          return this._getValueText(parameter);
        case 'select':
          return this._getValueSelect(parameter);
        default:
          console.log(parameter);
          console.log("Parameter Type " + paramType + " is not valid for " + parameter);
          return '';
      }
    }

    _getInfoSelectedValue(parameter){
      const paramInfo = this._formInfo.find(d => d.identifier == parameter);
      if (!this.paramToType.hasOwnProperty(parameter)) return null;
      const selectedValue = this.getSelectedValue(parameter);
      if (selectedValue == '') return {};
      const paramType = this.paramToType[parameter];
      switch (paramType) {
        case 'checkbox':
        case 'select':
          return paramInfo.options.find(d => d.value == selectedValue);
        case 'text':
        case 'password':
          return paramInfo;
      }
    }
  
    _getValueCheckBox(parameter){
      const paramDiv = d3.select('div#' + parameter);
      const nodes = paramDiv.selectAll('input').nodes();
  
      const node = nodes.find( d => d.checked === true);
      if (node == null) return "";
      return node.value;
    }
  
    _getValueSelect(parameter){
      const select = d3.select("div#" + parameter).select("select").node();
      const value = select.value;
      return value;
    }
    
    _getValueText(parameter){
      var textBox = d3.select('div#' + parameter).select('input').node();
      var value = textBox.value;
      return value;
    }
  
    getSelectedValues(){
      const selectedValues = {};
      this._formInfo.forEach( param => selectedValues[param.identifier] = this.getSelectedValue(param.identifier))
      return selectedValues;
    }
    
    setValue(param, value){
      if (!this.paramToType.hasOwnProperty(param)) return;
      const paramDiv = this.getComponents()[param];
      const paramType = this.paramToType[param];
      const paramInfo = this._formInfo.find( d => d.identifier == param);
      switch (paramType) {
        case 'checkbox':
          const currentValue = this.getSelectedValue(param);
          if (value === true && currentValue == 'true') return;
          if (value === false && currentValue == 'true') value = true;

          if (paramInfo.options.find( d => d.value == value) == null) return;
          var checkBox = paramDiv.select(`input[value=${value}]`).node();
          var info = paramInfo.options.find( d => d.value == value);
          this._titleClicked( d3.select(checkBox.parentNode).select('div').node() , info);
          break;
        case 'select':
          if (paramInfo.options.find( d => d.value == value) == null) return;
          paramDiv.select('select').node().value = value;
          break;
        case 'text':
          paramDiv.select('input').node().value = value;
          break;
        case 'password':
          paramDiv.select('input').node().value = value;
        default:
          break;
      }
    }
}var json = ( function(){

function reduceMap(json, idsToPreserve){
    json = wordplot.json.normalizeMindMapJson(json);
    const nodes = json.nodes;
    const edges = json.edges;

    var i = 0;
    var length = nodes.length;
    while (i < length){
        const node = nodes[i];
        const id = node.id;
        if (!idsToPreserve.includes(id)){
            nodes.splice(i,1);
            length--;
        } else {
            i++;
        }
    }

    var i = 0;
    var length = edges.length;
    while (i < length){
        const edge = edges[i];
        const from = edge.from;
        const to = edge.to;
        if (!idsToPreserve.includes(from) || !idsToPreserve.includes(to)){
            edges.splice(i,1);
            length--;
        } else {
            i++;
        }
    }
}

function normalizeMindMapJson(json){
    const originalJson = json;
    var nodes = []
    var edges = []
    var legends = []
    if (json.hasOwnProperty('data')) json = json.data;

    if (json.hasOwnProperty('nodes')) nodes = json.nodes;
    if (json.hasOwnProperty('edges')) edges = json.edges;
    if (json.hasOwnProperty('legends')) legends = json.legends;

    const normalized = {nodes, edges, legends}

    if (originalJson.hasOwnProperty('info')) normalized['info'] = originalJson.info;

    return normalized;
}

function normalizeStatisticsJson(json){
    var data = []

    if (json.constructor == Array){
        data = json
    } else if (json.hasOwnProperty('data')) {
        data = json.data
    } else if (json.hasOwnProperty('skills')) {
        data = json.skills
    }

    return { 'data':data }
}


function isMapWithSignals(json){
    var json = wordplot.json.normalizeMindMapJson(json);
    if (!json.hasOwnProperty('legends')) return false;
    const legends = json.legends;
    var legendsStr = JSON.stringify(legends);
    legendsStr = legendsStr.toLowerCase();

    if (legendsStr.includes('increasing') ||
        legendsStr.includes('decreasing') ||
        legendsStr.includes('emerging') ||
        legendsStr.includes('disapearing')
        ) return true;
    return false;
}

function isMapSeries(json){
    if (json == null) return false;
    if (!json.hasOwnProperty("data")) return false;

    try {
        const firstElement = json['data'][0];
        if (!firstElement.hasOwnProperty('buttonTitle')) return false;
        if (!firstElement.hasOwnProperty('title')) return false;
        return true;
    } catch (error) {
        return false;
    }

    return false;
}


function plotTypeIsCompatibleWithData(plotType, json){
    const dataFormat = wordplot.json.detectDataType(json);
    const compatibility = {
      'mindmap': ['hexagon','square','barchart'],
      'statistics': ['linechart','top20','barchartgroup'],
    }
  
    if (!compatibility.hasOwnProperty(dataFormat)) return false;
  
    const compatibleTypes = compatibility[dataFormat];
  
    const isCompatible = (compatibleTypes.includes(plotType));
    return isCompatible;
  }
  
  /**
   * 
   * @param {*} json 
   * @returns [mindmap, statistics, signals]
   */
  function detectDataType(json){
      var dataType = null;
  
      if (json == null || typeof(json) != 'object') return dataType;
  
      if (json.hasOwnProperty('data')){
          json = json.data;
      }
  
      if (isMapWithSignals(json)) return 'signals';

      if (json.hasOwnProperty('nodes')){
        return 'mindmap';
      } 
  
      if (Array.isArray(json) && json.length > 0){
        const firstElement = json[0];
        if (firstElement.hasOwnProperty('demand')) return 'statistics';
        if (firstElement.hasOwnProperty('buttonTitle')) return 'mapseries';
      }
  
      return dataType;
  }

function getCenterNode(nodes){
    const centerField = "search_center";
    var centerNode = null;

    if (!Array.isArray(nodes)) return centerNode;

    for (var i=0; i < nodes.length; i++){
        const node = nodes[i];
        if (!node.hasOwnProperty(centerField)) continue;
        if (node[centerField] == "true"){
            return node;
        }
    }
    return centerNode;
}


function jsonSupportsRelevancyMode(json){
    const data = wordplot.json.normalizeMindMapJson(json);
    try {
      const firstNode = data.nodes[0]
      if (!firstNode.hasOwnProperty('weight')){
        return false;   
      }
    } catch (error) {
      return false;
    }
    return true;
}

function removeLabelsFromMindMap(json, labelsToRemove){
    const {nodes, edges} = normalizeMindMapJson(json);
    if (!Array.isArray(labelsToRemove)) return {nodes,edges};
    if (!Array.isArray(nodes)) return {nodes,edges};
    if (!Array.isArray(edges)) return {nodes,edges};
    const idsToRemove = [];
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const label = node.label;
        const id = node.id;
        if (labelsToRemove.includes(label)) idsToRemove.push(id);
    }
    removeIdsFromMindMap(json, idsToRemove);
}

function removeIdsFromMindMap(json, idsToRemove){
    const {nodes, edges} = normalizeMindMapJson(json);
    if (!Array.isArray(idsToRemove)) return {nodes,edges};
    if (!Array.isArray(nodes)) return {nodes,edges};
    if (!Array.isArray(edges)) return {nodes,edges};

    const newNodes = [];
    const newEdges = [];

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const id = node.id;
        if (idsToRemove.includes(id)) continue;
        newNodes.push(node);
    }

    for (let i = 0; i < edges.length; i++) {
        const edge = edges[i];
        const from = edge.from;
        const to = edge.to;
        if (idsToRemove.includes(from)) continue;
        if (idsToRemove.includes(to)) continue;
        newEdges.push(edge);
    }

    if (json.data && json.data.nodes) json.data.nodes = newNodes;
    if (json.nodes) json.nodes = newNodes;

    if (json.data && json.data.edges) json.data.edges = newEdges;
    if (json.edges) json.edges = newEdges;
}

function nodesToStatistics(nodes){
    if (!Array.isArray(nodes)) return null;

    const data = [];

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const newNode = {};

        newNode['concept'] = node['label']
        newNode['displayname'] = formatLabel(node['label'])
        newNode['value'] = node['value']
        newNode['id'] = node['id']

        data.push(newNode);
        
    }


    return {data:data};
}

function formatLabel(label){
    if (typeof(label) !== 'string') return null;
    label = label.split('_').join(' ')
    label = label.split('-').join(' ')
    return label
}

return {
    normalizeMindMapJson,
    normalizeStatisticsJson,
    reduceMap,
    getCenterNode,
    
    // JSON Verification
    isMapWithSignals,
    isMapSeries,
    jsonSupportsRelevancyMode,
    detectDataType,
    plotTypeIsCompatibleWithData,

    // Remove Skills
    removeIdsFromMindMap,
    removeLabelsFromMindMap,

    nodesToStatistics,
}
})();var shrink = ( function(){

function filterAndCount(nodes, minValue, minWeight){
    var count = 0;
    nodes.forEach((node) => {
        if (node.value < minValue) return;
        const weight = Number.parseInt(node.weight);
        if (weight < minWeight) return;
        count++;
    });
    return count;
}

function findOptimalMinValue(nodes, desiredSize, minWeight){
    const filteredNodes = [];
    
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node['weight'] >= minWeight) filteredNodes.push(node);
    }

    const nFilteredNodes = filteredNodes.length;

    if (nFilteredNodes <= desiredSize) return 0;

    const lastNode = filteredNodes[nFilteredNodes - desiredSize];
    const minValue = lastNode['value']
    return minValue;
}

function findOptimalFilter(nodes, desiredSize, minWeight){
    var minValue = findOptimalMinValue(nodes, desiredSize, minWeight);
    const totalOption1 = filterAndCount(nodes, minValue, minWeight);
    const totalOption2 = filterAndCount(nodes, minValue + 1, minWeight);

    const dif1 = Math.abs(totalOption1 - desiredSize);
    const dif2 = Math.abs(totalOption2 - desiredSize);

    if (dif2 < dif1){
        var minValue = minValue + 1;
        var total = totalOption2;
        var dif = dif2;
    } else {
        var total = totalOption1;
        var dif = dif1;
    }

    const optimal = {'minValue':minValue, 'count': total, 'difference': dif};

    return optimal;
}

function sortNodes(nodes){
    const valueField = 'value';
    const sorted = [...nodes].sort((a,b) => a[valueField] - b[valueField]);
    return sorted;
}

function findOptimalFilters(nodes, desiredSize){
    if (typeof(desiredSize) !== 'number') return {};
    if (!Array.isArray(nodes) || nodes.length == 0) return {};
    if (desiredSize <= 0 ) return  {'minWeight':null, 'minValue':null, 'count':null};

    if (desiredSize >= nodes.length) return {'minWeight':null, 'minValue':null, 'count':null}

    // Ascending Sorting
    const reorganizedNodes = sortNodes(nodes);

    // Check if nodes have weights
    if (nodes[0].weight == null){
        console.log('Warning at findOptimalFilters() - Make sure that the given map contains weights, otherwise, automatic reduction won\'t work.');
        return {};
    }

    const minWeights = [1,2,3,4,5];
    var selectedMinValue = null;
    var selectedMinWeight = null;
    var selectedDifference = null;
    var selectedCount = null;

    for (var i=0; i < minWeights.length; i++){
        const minWeight = minWeights[i];
        const {minValue, count, difference} = findOptimalFilter(reorganizedNodes, desiredSize, minWeight);
        //console.log(`weight:${minWeight} value:${minValue} count:${count} difference:${difference}`);
        if (selectedMinValue == null || difference < selectedDifference){
            selectedMinValue = minValue;
            selectedMinWeight = minWeight;
            selectedDifference = difference;
            selectedCount = count;
        }
    }

    return {
        'minWeight':selectedMinWeight,
        'minValue': selectedMinValue,
        'count': selectedCount,
    }
}

return {
    findOptimalFilters
}

})();const interaction = (()=>{

function _getButtonDetails(plot){
  var details = {
    'remove':{
      id:'removeButton',
      char: '🗑️',
      hover: 'Remove clicked elements from the MindMap temporally',
      x: 1, y: 2,
      action: ()=>{plot.setActionOnClick('remove');_highlightActionButton(plot);},
    },'recenter':{
      id:'recenterButton',
      char: '🎯',
      hover: 'Recenter the concepts around the selected one',
      x: 1, y: 3,
      action: ()=>{plot.setActionOnClick('recenter');_highlightActionButton(plot);},
    },'highlight':{
      id: 'highlightButton',
      char: '💡',
      hover: 'Highlights the concepts that are related to the selected concept',
      x: 1, y:4,
      action: ()=>{plot.setActionOnClick('highlight');_highlightActionButton(plot);},
    },'source':{
      id: 'sourceButton',
      char: '🕵️',
      hover: 'See the links to the sources of the selected concept',
      x: 1, y: 5,
      action: ()=>{plot.setActionOnClick('source');_highlightActionButton(plot);},
    },'showdetails':{
      id: 'showDetailsButton',
      char: '📝',
      hover: 'Shows the attributes of the clicked concept',
      x: 1, y: 6,
      action: ()=>{plot.setActionOnClick('showdetails');_highlightActionButton(plot);},
    },'search':{
      id: 'searchButton',
      char: '🔍',
      hover: 'Search a concept',
      x: 1, y: 1,
      action: ()=>{showOrHideSearchBox(plot)},
    },'downloadMenu':{
      id: 'showDownloadMenuButton',
      char: '💾',
      hover: 'Show Download Options',
      x: 1, y: 7,
      action: ()=>{showDownloadMenu(plot)},
    },'hideDownloadMenu':{
      id: 'hideDownloadMenuButton',
      char: '🔙',
      hover: 'Back',
      background: 'none',
      x: 1, y: 1,
      action: ()=>{hideDownloadMenu(plot)},
    },'downloadPng':{
      id: 'downloadPngButton',
      char: 'PNG',
      color: 'white',
      width: 80,
      hover: 'Download MindMap as PNG',
      x: 1, y: 2,
      action: ()=>{wordplot.save.exportPng(plot)},
    },'downloadSvg':{
      id: 'downloadSvgButton',
      char: 'SVG',
      color: 'white',
      width: 80,
      hover: 'Download MindMap as SVG',
      x: 1, y: 3,
      action: ()=>{wordplot.save.exportSvg(plot)},
    }
  }

  // Disable Save Buttons
  const {disableSaveButtons} = plot.getProperties();
  if (disableSaveButtons === true){
    details.downloadMenu = undefined;
  }

  return details;
}

const MAIN_MENU_BUTTONS = [
  'showDownloadMenuButton','searchButton',
  'removeButton', 'highlightButton', 'recenterButton', 'sourceButton', 
  'showDetailsButton',
];

const DOWNLOAD_MENU_BUTTONS = ['downloadPngButton','hideDownloadMenuButton','downloadSvgButton'];


function changeOnClickAction(plot, action){
  const thisPlot = plot;
  const { nameField } = plot.getProperties();

  function showValuesOnClick(event,data){
    if (thisPlot.graphToMap == null) return;
    if (thisPlot.tooltipSubPlot == null) return;
    var nodeId = this.getAttribute('nodeId');
    var nodeInfo = thisPlot.graphToMap.mindmap.getNodeInfoById(nodeId);
    const valuesField = 'values';
    if (nodeInfo.hasOwnProperty(valuesField)){
      const json = nodeInfo[valuesField];
      const title = nodeInfo[nameField];
      thisPlot.tooltipSubPlot.plot(json);
      thisPlot.tooltipSubPlot.setTitle(title);
      thisPlot._showTooltipChart();
    }
  }
  
  function recenterOnClick(event){
    if (thisPlot.graphToMap == null) return;
    var nodeId = this.getAttribute('nodeId');
    var nodeInfo = thisPlot.graphToMap.mindmap.getNodeInfoById(nodeId);
    if (nodeInfo.hasOwnProperty(thisPlot._subPlotField)){
      var subPlotUrl = nodeInfo[thisPlot._subPlotField]
      var subPlotType = nodeInfo[thisPlot._subPlotTypeField]
      if (nodeInfo.hasOwnProperty('concept')){
        var uniqueName = nodeInfo.concept
      } else {
        var uniqueName = null
      }
      thisPlot.showEmbeddedVisualizationFromUrl(subPlotUrl, subPlotType, uniqueName);
    } else {
      thisPlot._refreshCenter(nodeId);
    }
  }
  
  const onClick = recenterOnClick;

  const removeOnClick = function(event){ 
    var nodeId = this.getAttribute('nodeId');
    var nodeId = parseInt(nodeId);
    thisPlot.removeElementById(nodeId);
  }

  const highlightOnClick = function(){
    var nodeId = this.getAttribute('nodeId');
    var nodeId = parseInt(nodeId);
    thisPlot._highlightNode(nodeId);
  }

  const sourceOnClick = function(){
    var nodeId = this.getAttribute('nodeId');
    var nodeId = parseInt(nodeId);
    _showSourceData(thisPlot, nodeId);
  }

  const showNodeDetails = function(){
    var nodeId = this.getAttribute('nodeId');
    var nodeId = parseInt(nodeId);
    _showNodeDetails(thisPlot, nodeId);
  }

  switch (action) {
    case 'showdetails':
      var actionOnClick = showNodeDetails;
      plot.enableTooltip(true);
      plot.tooltip.setMargin({top:10,bottom:10,left:10,right:10})
      break;
    case 'remove':
      var actionOnClick = removeOnClick;
      break;
    case 'showvalues':
      var actionOnClick = showValuesOnClick;
      break;
    case 'recenter':
      var actionOnClick = recenterOnClick;
      break;
    case 'highlight':
      var actionOnClick = highlightOnClick;
      break;
    case 'source':
      var actionOnClick = sourceOnClick;
      _initializeSourceTooltip(plot);
      break;
    default:
      var actionOnClick = onClick;
      break;
  }

  plot._actionOnClick = action;
  plot._actionOnClickFunction = actionOnClick;
}

function _initializeButton(plot, buttonInfo){
  if (buttonInfo == null) return;

  const {
    id, x, y, char, hover, action, color, width, background,
  } = buttonInfo;

  if (typeof(id) != 'string' || id.length == 0) return;
  if (typeof(x) != 'number' || x <= 0) return;
  if (typeof(y) != 'number' || y <= 0) return;

  const svg = plot.getComponents().outerSVG;

  var buttonWidth = 35;
  var buttonHeight = 35;

  const xPadding = 10;
  const yPadding = 10;
  const plotHeight = plot._height;
  const FONT_SIZE_BUTTON = '23px';

  if (typeof(background) == 'string'){
    var buttonColor = background;
  } else {
    var buttonColor = "#08233b";
  }

  if (typeof(width) == "number"){
    var buttonWidth = width;
  }

  const xButton = xPadding + (x-1)*(buttonWidth + xPadding);
  const yButton = plotHeight - y*(buttonHeight + yPadding);


  const button = svg.selectAll('svg#' + id).data([null]).enter()
  .append('svg').attr('id',id)
  .attr('x', xButton).attr('y',yButton)
  .attr('height',buttonHeight).attr('width',buttonWidth)
  .style('cursor','pointer');

  if (button.node() == null) return button;

  const buttonRect = button.append('rect').attr('height','100%').attr('width','100%')
  .attr('rx','10').attr('ry','10').attr('fill', buttonColor);

  const foreign = button.append('foreignObject').attr('x',0).attr('y',3)
  .attr('width','100%').attr('height','100%').node();

  var buttonText = document.createElement('text');
  foreign.appendChild(buttonText);

  buttonText = d3.select(buttonText)
  .style('text-align','center')
  .style('display','block')
  .style('font-size',FONT_SIZE_BUTTON)
  .text(char);

  if (typeof(hover) == 'string'){
    buttonText.attr('title',hover);
  }

  if (typeof(color) == 'string'){
    buttonText.style('color',color);
  }

  button.on('click', action);

  return button;
}

function getButtonsSvg(plot){
  const svg = plot.getComponents().outerSVG;

  const buttons = {};

  const svgIds = [
    'removeButton', 'highlightButton', 'recenterButton', 
    'sourceButton', 'searchButton', 'showDetailsButton',
    'showDownloadMenuButton','downloadPngButton',
    'hideDownloadMenuButton','downloadSvgButton',
    'downloadJsonButton'
  ];

  svgIds.forEach((id)=>{
    const svgButton = svg.select(`svg#${id}`);
    buttons[id] = svgButton;
  });

  const foreignIds = ['searchBox'];

  foreignIds.forEach((id)=>{
    const svgButton = svg.select(`foreignObject#${id}`);
    buttons[id] = svgButton;
  });

  return buttons;
}

function initializeButtons(plot){

  const svg = plot.getComponents().outerSVG;
  if (svg.node() == null) return;

  const buttonDetails = _getButtonDetails(plot);

  const thisPlot = plot;
  
  // Initialize Buttons
  Object.keys(buttonDetails).forEach((buttonName)=>{
    _initializeButton(plot, buttonDetails[buttonName]);
  });

  _initSearchBox(thisPlot);
  _highlightActionButton(plot);

  hideButtons(plot, DOWNLOAD_MENU_BUTTONS, 0);
}

function showDownloadMenu(plot){
  hideButtons(plot, MAIN_MENU_BUTTONS,0);
  hideSearchBox(plot);
  showButtons(plot, DOWNLOAD_MENU_BUTTONS, 500);
}

function hideDownloadMenu(plot, duration){
  if (typeof(duration) != "number") duration = 500;
  hideButtons(plot,DOWNLOAD_MENU_BUTTONS,duration);
  hideSearchBox(plot);
  showButtons(plot, MAIN_MENU_BUTTONS, duration);
}

function showOrHideSearchBox(plot){
  const {searchBox} = getButtonsSvg(plot);
  if (searchBox.style('display') != 'unset'){
    searchBox.transition().duration(500).style('display','unset');
  } else {
    // Hide
    hideSearchBox(plot);
  }
}

function showButtons(plot, buttons, duration){
  if (!Array.isArray(buttons)) return;
  const svgs = getButtonsSvg(plot);

  buttons.forEach( (buttonName) => {
    const button = svgs[buttonName];
    button.select('rect')
    button.transition().duration(duration).style('opacity',1).style('display','unset');  
  });
}

function hideButtons(plot, buttons, duration){
  if (!Array.isArray(buttons)) return;
  const svgs = getButtonsSvg(plot);

  buttons.forEach( (buttonName) => {
    const button = svgs[buttonName];
    button.transition().duration(duration).style('opacity',0).transition().style('display','none');
  });
}

function hideSearchBox(plot){
  const {searchBox} = getButtonsSvg(plot);
  searchBox.style('display','none');
  plot._highlightAllNodes();
  plot.disableMouseOver(false);
}

function _initSearchBox(plot){
  const outerSVG = plot.getComponents().outerSVG;

  const buttonSize = 35;
  const xPadding = 10;
  const yPadding = 10;
  const height = plot._height;
  const yBox = height - buttonSize - yPadding;
  const xBox = xPadding + 1 * (buttonSize + xPadding);


  const foreignObject = outerSVG.selectAll('foreignObject#searchBox')
  .data([null]).enter()
  .append('foreignObject')
  .attr('id','searchBox')
  .attr('height', buttonSize)
  .attr('width',100)
  .attr('x',xBox)
  .attr('y',yBox);
  
  const input = foreignObject.append('xhtml:input')
  .attr('type','text')
  .style('width','100%')
  .style('height','100%')
  .style('font-size',16);

  input.on('keyup', function(event){
    if (event.key == 'Escape'){
      hideSearchBox(plot);
    }
  })

  input.on('keypress',function(event){
    if (event.key != 'Enter') return;
    const searchText = this.value;
    _highlightSearch(plot,searchText);
  });

  foreignObject.style('display','none');
}

function _highlightSearch(plot,search){
  var search = search.trim();

  if (typeof(search) != "string" || search.length == 0){
    plot._highlightAllNodes();
    plot.disableMouseOver(false);
    return;
  }

  var search = search.replaceAll(' ','_');
  var search = search.toLowerCase();
  const nodes = plot._plotData;
  const filteredNodes = [];
  nodes.forEach( d=>{
      const label = d.label;
      const id = d.id;
      if (label.includes(search)){
          filteredNodes.push(id);
      }
  });
  if (filteredNodes.length == 0){
      plot._highlightAllNodes();
      plot.disableMouseOver(false);
      return;
  }
  plot.disableMouseOver(true);
  plot._unHighlightAllNodes();
  plot._highlightListOfNodes(filteredNodes);
}

function _highlightActionButton(plot){
  var defaultButtonColor = "#08233b";

  const buttons = getButtonsSvg(plot);
  const buttonsDetails = _getButtonDetails(plot);
  const action = plot._actionOnClick;
  const actionToButton = {
    'remove':'removeButton',
    'highlight':'highlightButton',
    'recenter':'recenterButton',
    'source':'sourceButton',
    'showdetails':'showDetailsButton',
  }
  const highlightColor = '#ffffff';
  if (!actionToButton.hasOwnProperty(action)) return;
  const buttonTag = actionToButton[action];

  Object.keys(actionToButton).forEach( d=>{
    const buttonId = actionToButton[d];
    const buttonInfo = buttonsDetails[d];
    
    if (buttonInfo == null || buttonId == null) return;
    
    const button = buttons[buttonId];
    if (typeof(buttonInfo.background) == 'string'){
      var buttonColor = buttonInfo.background;
    } else {
      var buttonColor = defaultButtonColor;
    }
    button.select('rect').attr('fill', buttonColor);
  })
  const button = buttons[buttonTag];
  button.select('rect').style('stroke','black').style('stroke-width',2)
  .attr('fill',highlightColor);
}

function _showSourceData(plot, nodeId){
  const page = 1;
  _showSourceDataAux(plot,nodeId, page)
}

function _showSourceDataAux(plot, nodeId, page){
  const RESULTS_PER_PAGE = 10;

  plot.tooltip.clean();
  plot.tooltip.setSize(300,50);
  const sourceField = plot._sourceField;
  const data = plot.mindmap.getNodeInfoById(nodeId);
  if (data.id != nodeId){ console.log('Warning at showSourceData() : Id is not consistant'); return;}
  if (!data.hasOwnProperty(sourceField)) return;
  const sources = data[sourceField];
  if (!Array.isArray(sources)) return;

  const startIndex = (page - 1) * RESULTS_PER_PAGE;
  const sourcesCurrentPage = sources.slice(startIndex, startIndex + RESULTS_PER_PAGE);

  for (let i = 0; i < sourcesCurrentPage.length; i++) {
    const source = sourcesCurrentPage[i];
    const url = source.url;
    const title = String(i+1+startIndex) + ") " +  source.title;
    plot.tooltip.addLink(url,title);
  }

  const tooltipText = plot.tooltip.getComponents().text;
  const buttonContainer = plot.tooltip._addOneLineInsideText(tooltipText,'');
  plot.tooltip._nLines = tooltipText.selectAll('tspan').size();
  plot.tooltip._checkResize();
  buttonContainer.attr('x','100%');
  buttonContainer.style('text-anchor','end');

  // Prev Page
  if (page > 1){
    const prevButtonText = "⬅️";
    const prevButton = buttonContainer.append('tspan').text(prevButtonText);
    prevButton.on('click', ()=>{_showSourceDataAux(plot, nodeId, page-1)}); 
    prevButton.style('cursor','pointer');
  }

  // Next Page
  const nSources = sources.length;
  var nPages = Math.floor(nSources/RESULTS_PER_PAGE)
  if (nSources % RESULTS_PER_PAGE > 0) nPages += 1;

  const pageIsLastPage = (page == nPages)
  if (pageIsLastPage == false){
    const nextButtonText = "➡️";
    const nextButton = buttonContainer.append('tspan').text(nextButtonText);
    nextButton.on('click', ()=>{_showSourceDataAux(plot, nodeId, page+1)});
    nextButton.style('cursor','pointer');
  }

  const node = d3.selectAll('svg.concept').filter(function(){
    var id = d3.select(this).attr('nodeId');
    return id == String(nodeId);
  });

  if (node.size == 0) return;
  const x = node.attr('x');
  const y = node.attr('y') - plot.tooltip._height + 0;
  plot.tooltip.move(x,y);
  plot.tooltip.show();
}

function _showNodeDetails(plot, nodeId){
  const RESULTS_PER_PAGE = 10;

  plot.tooltip.clean();
  plot.tooltip.setSize(300,50);
  const data = plot.mindmap.getNodeInfoById(nodeId);
  if (data.id != nodeId){ console.log('Warning at showSourceData() : Id is not consistant'); return;}

  const fields = [
    'id','label','value','unique_value','weight',
    'normalized_value','group',
  ];
  
  fields.forEach((field)=>{
    if (data[field] == null) return;
    const value = data[field];
    const formattedKey = formatField(field);
    plot.tooltip.addKeyValue(formattedKey,value);
  });

  const tooltipText = plot.tooltip.getComponents().text;
  const buttonContainer = plot.tooltip._addOneLineInsideText(tooltipText,'');
  plot.tooltip._nLines = tooltipText.selectAll('tspan.tooltipLine').size();
  plot.tooltip._checkResize();
  buttonContainer.attr('x','100%');
  buttonContainer.style('text-anchor','end');

  const node = d3.selectAll('svg.concept').filter(function(){
    var id = d3.select(this).attr('nodeId');
    return id == String(nodeId);
  });

  if (node.size == 0) return;
  const x = node.attr('x');
  const y = node.attr('y') - plot.tooltip._height + 0;
  plot.tooltip.move(x,y);
  plot.tooltip.show();
}

function formatField(label){
  if (typeof(label) != "string") return label;
  label = label.replaceAll('_',' ');
  label = label.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return label;
}

function _initializeSourceTooltip(plot){
  plot.enableTooltip(true);
  const tooltip = plot.tooltip;
  tooltip.setSize(300,50);
  tooltip.setMargin({'left':10, 'top':10, 'bottom':10, 'right':10});
  tooltip.setBackgroundColor('#c0c0c0');
}

return {
  changeOnClickAction,
  initializeButtons,
  getButtonsSvg,
}

})();var megatron = ( function(){

function buildTextToMindmapUrl(params){
    var output = 'json';
    var predictionType = 'linear';
    var noise = 'full';
    var city = '';
    
    const { _country, _cluster, _language, _timeMonths, _timeYears, _sizeMap, 
        _ontology, _wordType, _token, _limit, _datasource, _plotType } = params;
    
    
    if ( ['open_access_journals','theseus_database'].includes(_datasource)){
        var _time = _timeYears;
    } else {
        var _time = _timeMonths;
    }
    
    var url = `https://megatron.headai.com/TextToMindMap?` + 
    `token=${_token}&language=${_language}&ontology=${_ontology}&` +
    `search_text=${_limit}&word_type=${_wordType}&noise=${noise}&` + 
    `prediction_type=${predictionType}&cluster_name=${_cluster}&` + 
    `country=${_country}&months=${_time}&size=${_sizeMap}&` + 
    `city=${city}&output=${output}&dataset=${_datasource}`;
    return url;
}

function buildTextToStatisticsUrl(params){
    var output = 'json';
    var predictionType = 'linear';
    var noise = 'full';
  
    const { _country, _cluster, _language, _timeMonths, _timeYears, _sizeStats, 
      _ontology, _wordType, _token, _limit, _datasource, _plotType } = params;
  
    if (_plotType == 'top20'){
      var _size = 20;
    } else {
      var _size = _sizeStats;
    }
  
    if ( ['open_access_journals','theseus_database'].includes(_datasource)){
      var _time = _timeYears;
    } else {
      var _time = _timeMonths;
    }
  
    var url = `https://megatron.headai.com/TextToStatistics?` + 
    `country=${_country}&cluster_name=${_cluster}&output=${output}&language=${_language}&months=${_time}` + 
    `&size=${_size}&ontology=${_ontology}&noise=${noise}&prediction_type=${predictionType}` +
    `&word_type=${_wordType}&token=${_token}&search_text=${_limit}&dataset=${_datasource}`;
  
    return url;
}

function buildExplainUrl(params){
    const {context,year,text} = params;
    var url = `https://megatron.headai.com/mc_api?action=explain_map&type=json&context=${context}&search_year=${year}&text=${text}`;
    return url;
}

function buildStoreModifiedDataUrl(params){
  const {
    token = '',
    url = '',
    update = false,
  } = params;
  var megatronUrl = `https://megatron.headai.com/Utils?action=store_modified_data&url=${url}&update=${update}&token=${token}`;
  return megatronUrl;
}
  

return {
    buildTextToMindmapUrl,
    buildTextToStatisticsUrl,
    buildExplainUrl,
    buildStoreModifiedDataUrl,
}
})();/**
 * 
 */
class MapSeries{
    constructor(plotId, data, plotType, props={}){
        this._buttonTitleField = "buttonTitle";
        this._titleField = "title";
        this._currentMap = null;
        this._currentPromise = new Promise(function(resolve, reject) {
            resolve(true);
          });
        this._initProperties();
        this.plotId = plotId;
        this._plotData = [];
        this._plots = [];
        this.setProperties(props);
        this._plotType = plotType;
        this.setPlotData(data);
        this.initializeDiv();

        // This has to be the Last line to execute
        this.changeMap(0);
    }

    getCurrentPromise(){
        return this._currentPromise;
    }

    getProperties(){
        return this.props;
    }

    _initProperties(){
        const props = {
            'responsiveMode': false,
            'buttonsHeight' : 40,
            'buttonsMargin' : 10,
            'inactiveColor' : "#08233B",
            'activeColor' : "#11A1F3",
            'width' : 200,
            'height' : 200,
            'enableTooltipChart' : false,
            'tooltipChartProps' : {},
            'colors': ['#08233B','#08233B'],
            'hideNodes': [],
        }

        this.props = props;
    }

    _normalizeData(data){
        if (data.hasOwnProperty("data")){
            data = data.data;
        }
        return data;
    }

    setPlotData(data){
        data = this._normalizeData(data);
        if (!Array.isArray(data)) return;
        this._plotData = data;
        this._plots = [];
        for (var i=0; i < data.length; i++) this._plots.push(null);
    }

    setProperties(props={}){
        const skipProps = [
           // 'minWidth', 'maxWidth'
        ]

        if (props.hasOwnProperty('minWidth') && props.hasOwnProperty('maxWidth')){
            this.enableResponsiveMode(props.minWidth, props.maxWidth);
        }

        Object.keys(props).forEach(propName => {
            if (skipProps.includes(propName)) return;
            this.props[propName] = props[propName];
        });

        if (this._currentMap != null){
            this.getCurrentMap().setProperties(props);
        }

        if (props.hasOwnProperty('width') || props.hasOwnProperty('height')){
            this.refreshComponents();
        }
    }

    setMapUrls(mapUrls){
        if (!Array.isArray(mapUrls) || mapUrls.length == 0 || typeof(mapUrls[0]) != "string"){
            mapUrls = [];
        }
        this._mapUrls = mapUrls;
    }

    _changeTitle(mapIndex){
        const title = this._getTitle(mapIndex);
        const {titleDIV} = this.getComponents();
        titleDIV.select('h2').text(title);
    }

    _getTitle(mapIndex){
        const titleField = this._titleField;
        var title = "";

        if (typeof(mapIndex) != 'number') return title;
        if (mapIndex >= this._plotData.length || mapIndex < 0) return title;
        
        const data = this._plotData[mapIndex];
        if (!data.hasOwnProperty(titleField)) return title;

        title = data[titleField];
        return title;
    }

    _getButtonTitle(mapIndex){
        const buttonTitleField = this._buttonTitleField;
        var title = "";

        if (typeof(mapIndex) != 'number') return title;
        if (mapIndex >= this._plotData.length || mapIndex < 0) return title;
        
        const data = this._plotData[mapIndex];
        if (!data.hasOwnProperty(buttonTitleField)) return title;

        title = data[buttonTitleField];
        return title;
    }

    changeMap(mapIndex){
        if (this.hasOwnProperty('_currentMap') && this._currentMap != null){
            var currentMap = this._currentMap;
        } else {
            const id = this.plotId + "_map";
            var currentMap = new wordplot.Visualization(id, this.props);
        }
        currentMap.restartSVG();
        currentMap.showMessage('Loading...');

        const data = this._plotData;
        const urlField = "url";
        if (mapIndex >= data.length){
            this._currentMap = null;
            return;
        }

        this.highlightButton(mapIndex);

        var mapId = this.plotId + "_map";

        const thisPlot = this._plots[mapIndex];

        // If Map was loaded previously...
        if (thisPlot != null){
            // Remove Loading messages
            d3.select('div#' + mapId).selectAll('text#loadingText').remove();
            thisPlot.refresh();
            this._currentMap = thisPlot;

            if (this.props.responsiveMode === true) this._setResponsiveConfig();

            this._changeTitle(mapIndex);
            return;
        }

        const url = data[mapIndex][urlField];
        const promise = d3.json(url).then( json => {
            const plot = wordplot.wordmap.buildWordMap(mapId, json, this._plotType, this.props);
            this._plots[mapIndex] = plot;

            // Remove Loading messages
            d3.select('div#' + mapId).selectAll('text#loadingText').remove();

            // Hide Legend
            const hideLegend = data[mapIndex]['hideLegend'];
            if (hideLegend === 'true' || hideLegend === true) plot.hideLegend(true);

            const groups = Object.keys(plot._groupToColorIndex);
            const nGroups = groups.length;
            
            if (nGroups <= 2) {
                wordplot.wordmap.setColorsToMindMap(plot);
            }

            plot.refresh();
            wordplot.wordmap.centerMap(plot);
            this._currentMap = plot;

            if (this.props.enableTooltipChart === true){
                const tooltipProps = this.props.tooltipChartProps;
                plot.enableTooltipChart('basiclinechart', tooltipProps);
            }
        }).then( () => {if (this.props.responsiveMode === true) this._setResponsiveConfig();});

        this._changeTitle(mapIndex);

        this._currentPromise = promise;
        return promise;
    }

    initializeDiv(){
        const titleId = this.plotId + "_title";
        const mapId = this.plotId + "_map";
        const buttonsId = this.plotId + "_buttons";
        const div = d3.select('div#' + this.plotId);

        const title = div.append("div").attr("id", titleId);
        //title.style('width', this.props.width);
        title.selectAll('h2').data([null]).enter()
        .append('center').append('h2');

        const map = div.append("div").attr("id", mapId);
        const buttonsDiv = div.append('div').attr('id',buttonsId);

        buttonsDiv.selectAll('svg.expansor').data([null]).enter()
        .append('svg')
        .attr('class','expansor')
        .style('width','100%')
        .style('height','0px');

        const buttons = buttonsDiv.append("svg").attr("class", "buttons");
        const width = this.props.width;
        const height = this.getButtonsHeight()

        buttons.attr('viewBox',`0 0 ${width} ${height}`);
        buttons.attr('width',width);
        this.addButtons();
    }

    refreshComponents(){
        if (this.responsiveMode === true){
            this._setResponsiveConfig();
            return;
        }

        const {div, titleDIV, mapDIV, buttonsSVG, buttonsDIV} = this.getComponents();

        titleDIV.style('width', this.props.width);
        const width = this.props.width;
        buttonsSVG.attr('width',width);
    }

    getButtonsHeight(){
        const BUTTON_TITLE_HEIGHT = 30;
        const height = this.props.buttonsHeight + this.props.buttonsMargin*2 + BUTTON_TITLE_HEIGHT;
        return height;
    }

    getComponents(){
        var div = d3.select( 'div#' + this.plotId);

        const titleId = this.plotId + "_title";
        var titleDIV = div.select('div#' + titleId);

        const mapId = this.plotId + "_map";
        var mapDIV = div.select('div#' + mapId);

        const buttonsId = this.plotId + "_buttons";
        var buttonsDIV = div.select('div#' + buttonsId);

        var buttonsSVG = div.select('svg.buttons');
        return {div, titleDIV, mapDIV, buttonsSVG, buttonsDIV};
    }

    _normalizePixels(pixels){
        if (typeof(pixels) == "number") return pixels;
        if (typeof(pixels) == "string"){
            return parseFloat(pixels);
        }
        return null;
    }

    enableResponsiveMode(minWidthPixels, maxWidthPixels){
        minWidthPixels = this._normalizePixels(minWidthPixels);
        maxWidthPixels = this._normalizePixels(maxWidthPixels);

        if (typeof(minWidthPixels) != "number") return;
        if (typeof(maxWidthPixels) != "number") return;
        if (minWidthPixels <= 0) return;
        if (maxWidthPixels <= 0) return;

        this.props.minWidthPixels = minWidthPixels;
        this.props.maxWidthPixels = maxWidthPixels;
        this.props.responsiveMode = true;
        this._setResponsiveConfig();
    }

    _setResponsiveConfig(){
        if (this.props.responsiveMode !== true) return;

        const minWidthPixels = this.props.minWidthPixels
        const maxWidthPixels = this.props.maxWidthPixels

        const {div, titleDIV, mapDIV, buttonsSVG, buttonsDIV} = this.getComponents();
        titleDIV
        .style("min-width",minWidthPixels+"px")
        .style('max-width', maxWidthPixels+"px")
        .style('width',null);

        mapDIV
        .style("min-width",minWidthPixels+"px")
        .style('max-width', maxWidthPixels+"px")
        .style('width',undefined)
        .style('height',undefined);

        buttonsDIV
        .style("min-width",minWidthPixels+"px")
        .style('max-width', maxWidthPixels+"px")
        .style('width',null);

        buttonsSVG.style('width','100%');
    }

    getCurrentMap(){
        return this._currentMap;
    }

    highlightButton(index){
        const {buttonsSVG} = this.getComponents();
        const buttons = buttonsSVG.selectAll('circle');
        buttons.attr('fill', this.props.inactiveColor)
        .attr('stroke-width',3)
        .attr('stroke',null);

        const button = buttonsSVG.selectAll(`circle[index='${index}']`);
        button.attr('fill', this.props.activeColor )
        .attr('stroke',this.props.inactiveColor);
    }


    refresh(){
        if (this._currentMap == null) return;

        this.refreshComponents();
        this._currentMap.refresh();
    }

    addButtons(){
        const data = this._plotData;
        const width = this.props.width;

        const { buttonsSVG } = this.getComponents();
        const r = this.props.buttonsHeight / 2;
        const nMaps = data.length;

        const buttonsData = [];

        for (var i=0; i< nMaps; i++){
            const mapData = data[i];
            var title = "";
            if (mapData.hasOwnProperty(this._buttonTitleField)){
                title = mapData[this._buttonTitleField];
            }
            const sectionSize = (width / nMaps)
            const x = sectionSize*(i+1) - sectionSize/2;
            const buttonData = { "index": i, "x": x, "title": title };
            buttonsData.push(buttonData);
        }

        const linesData = [];

        for (var i=1; i< nMaps; i++){
            const sectionSize = (width / nMaps)
            const x1 = sectionSize*(i) - sectionSize/2;
            const x2 = sectionSize*(i+1) - sectionSize/2;
            const data = { "x1": x1, "x2": x2 };
            linesData.push(data);
        }
        
        const thisPlot = this;

        const yPos = r + this.props.buttonsMargin;

        buttonsSVG.selectAll('line').data(linesData).enter()
        .append('line')
        .attr('x1', d => d.x1)
        .attr('x2', d => d.x2)
        .attr('y1', yPos)
        .attr('y2', yPos)
        .attr('stroke','gray')
        .attr('stroke-dasharray',7);

        buttonsSVG.selectAll('circle').data(buttonsData).enter()
        .append('circle')
        .attr('r', r)
        .attr('cy', yPos)
        .attr('cx', d => d.x)
        .attr('index', d => d.index)
        .style('cursor','pointer')
        .on('click', (action,data)=>{
            const index = data.index;
            thisPlot.changeMap(index);
        });

        const BUTTON_TITLE_WIDTH = 100;
        const BUTTON_TITLE_HEIGHT = 30;

        const buttonTitles = buttonsSVG.selectAll('svg.buttonTitle').data(buttonsData).enter()
        .append('svg')
        .attr('class','buttonTitle')
        .attr('width', BUTTON_TITLE_WIDTH)
        .attr('height', BUTTON_TITLE_HEIGHT)
        .attr('x', d => d.x - BUTTON_TITLE_WIDTH/2)
        .attr('y', d => yPos + r);
        
        buttonTitles.append('text')
        .attr('y','50%')
        .attr('x','50%')
        .attr('dominant-baseline','middle')
        .attr('text-anchor','middle')
        .text( d => d.title);
        
        buttonsSVG.selectAll('svg.text').data(buttonsData)
    }
}


function buildMapSeries(plotId, json, plotType, props){
    props['actionOnClick'] = 'showvalues';
    props['enableTooltipChart'] = true;

    if (json.hasOwnProperty('info') && json.info.hasOwnProperty('timeLabels')){
        const timeLabels = json.info.timeLabels;
        props['tooltipChartProps'] = {timeLabels};
    }
    
    const plot = new wordplot.wordmap.MapSeries(plotId, json, plotType, props);
    
    //plot.enableResponsiveMode(minWidth,maxWidth);
    return plot;
}/**
 * WideGraphToMap is an implementation of GraphToMap. It has a different algorithm to place the nodes in the space.
 * This implementation gives more priority to the relations between nodes, for that reason, any pair of nodes that are
 * placed next to each other HAS to be related (the edge that conects both concepts must exist).
 * 
 * In other words, using this algorithm, there are no divisions between hexagons because they must be related in order
 * to be placed contiguously.
 */
class WideGraphToMap extends GraphToMap {
    constructor(mindmap,mapType,props={}){
        super(mindmap,mapType,props);
    }

    canPlaceNode(node, x, y, matrix){
        const nodeId = node.id;
        const neighbors = this.mindmap.getNeighborsById(nodeId);
        const location = [x,y];
        const validCoordinates = this.processor.getNeighborsCoordinates(location, matrix);
        for (var i=0; i < validCoordinates.length; i++){
            const coords = validCoordinates[i];
            const cx = coords[0];
            const cy = coords[1];
            if (cx < 0 || cy < 0) continue;
            if (cy >= matrix.length) continue;
            if (matrix.length > 0 && cx >= matrix[0].length) continue;
            const id = matrix[cy][cx];
            if (id == -1) continue;
            // If can't find the id in the list of neighbors, the new node can't be placed in that position
            if (!neighbors.includes(id)) return false;
        }

        return true;
    }

    locateChildren(nodes, nodesBeingLocated, matrix){
        var nodesForNextIteration = [];
        nodes.forEach(node => {
          const nodeId = node.id;
          var children = this.mindmap.getNeighborsById(nodeId).filter((id) => this.mindmap.getNodeInfoById(id)['isPlaced'] === false);
          const center = [node.x,node.y];
          const validCoordinates = this.processor.getValidNeighborsCoordinates(center, matrix);
          const nChildrensToPlace = Math.min(children.length , validCoordinates.length);
          for (var i=0; i < nChildrensToPlace; i++){
            const x = validCoordinates[i][0];
            const y = validCoordinates[i][1];
            var selectedChild = null;
            for (var j=0; j < children.length; j++){
                const childId = children[j];
                const child = this.mindmap.getNodeInfoById(childId);
                if (!this.canPlaceNode(child, x, y, matrix)) continue;
                // Place node and stop searching candidates for that space
                selectedChild = child;
                break;
            }

            if (selectedChild == null) return;
            const child = selectedChild;

            const childId = child.id;
            child.x = x;
            child.y = y;

            child['isPlaced'] = true;
            nodesBeingLocated.push(child);
            //children.pop(child);
            children = this.mindmap.getNeighborsById(nodeId).filter(id => this.mindmap.getNodeInfoById(id)['isPlaced'] === false);

            matrix[y][x] = childId;
            nodesForNextIteration.push(child);
          }
        });
    
        if (nodesForNextIteration.length > 0){
          this.locateChildren(nodesForNextIteration, nodesBeingLocated, matrix);
        }
    }

    /**
     * Verifies if putting matrix B over matrix A at position (bx,by) generates an overlapping of the values
     * or if both can be merged at the specified position. In other words, if values of matrix B can be placed
     * in empty spaces of matrix A. This also checks if the nodes of B are not contiguous to unrelated nodes of A
     * (edge between any contiguous pair of nodes must exist)
     * @param {*} A Matrix that is taken as the base of the overlapping
     * @param {*} B Matrix that is placed over matrix A at position (bx,by)
     * @param {*} bx 
     * @param {*} by 
     * @param {*} emptyValue By default, emptyValue is represented with a -1 in the matrix.
     * @returns 
     */
    matricesAreOverlapping(A,B,bx,by, emptyValue=-1){
        const { nodes } = this.mindmap.getData();

        const A_rows = A.length;
        if (A_rows == 0) return false;
        if (by >= A_rows) return false;
        const A_cols = A[0].length;
        if (A_cols == 0) return false;
        if (bx >= A_cols) return false;
        const B_rows = B.length;
        if (B_rows == 0) return false;
        const B_cols = B[0].length;
        if (B_cols == 0) return false;
      
        const x0 = bx;
        const x1 = Math.min(A_cols, bx + B_cols);
        const y0 = by;
        const y1 = Math.min(A_rows, by + B_rows);
      
        var B_x = 0;
        for (var A_x=x0; A_x<x1; A_x++){
          var B_y = 0;
          for (var A_y=y0; A_y<y1; A_y++){
            var A_i = A[A_y][A_x];
            var B_i = B[B_y][B_x];
            if (A_i != emptyValue && B_i != emptyValue) return true;
            B_y++;
          }
          B_x++;
        }

        // Generate Temporal overlap matrix
        const overlapMatrix = this.generateOverlapMatrix(A,B,bx,by);
        if (this.matrixHasDivisions(overlapMatrix)) return true;

        return false;
    }

    /**
     * Generates the Matrix of the intersection area of B over A at position (bx,by).
     * Empty value is represented as -1. If both A and B have a value different than -1, value B is taken.
     * The overlap matrix has a padding of 1, which means that the generated matrix shows 1 column before the overlapping,
     *   1 column after the overlapping, 1 row before and 1 row after the overlapping, if the overlapping area allows it.
     * 
     * This function only return the intersection area (overlapping area) plus extra rows and columns determined by the padding.
     * 
     * @param {Matrix} A Matrix that will be taken as the base of the overlapping
     * @param {Matrix} B Matrix that will be placed over Matrix A
     * @param {Int} bx Initial Position in x that matrix B will have over matrix B
     * @param {Int} by Initial Position in y that matrix B will have over matrix A
     * @returns 
     */
    generateOverlapMatrix(A,B,bx,by){
        // Padding is used to look n rows and n columns before the overlapping
        const padding = 1
        const A_rows = A.length;
        if (A_rows == 0) return null;
        if (by >= A_rows) return null;
        const A_cols = A[0].length;
        if (A_cols == 0) return null;
        if (bx >= A_cols) return null;
        const B_rows = B.length;
        if (B_rows == 0) return null;
        const B_cols = B[0].length;
        if (B_cols == 0) return null;

        const x0 = Math.max(bx - padding,0);
        const x1 = Math.min(A_cols, bx + B_cols + padding);
        const y0 = Math.max(by - padding, 0);
        const y1 = Math.min(A_rows, by + B_rows + padding);

        const cols = x1 - x0;
        const rows = y1 - y0;

        const newMatrix = Array(rows).fill(null).map(() => Array(cols).fill(-1));

        // Get Submatrix of A in that Space
        for (var y=0; y < rows; y++){
            for (var x=0; x < cols; x++){
                const Ax = x0 + x;
                const Ay = y0 + y;
                var value = A[Ay][Ax];
                newMatrix[y][x] = value;
            }
        }

        // Override vallues of B over A
        for(var y=0; y < B_rows; y++){
            for(var x=0; x < B_cols; x++){
                const Ax = padding + x;
                const Ay = padding + y;
                var value = B[y][x];
                if (value == -1) continue;
                if (Ay >= rows) continue;
                if (Ax >= cols) continue;
                newMatrix[Ay][Ax] = value;
            }
        }
        return newMatrix;
    }

    matrixHasDivisions(matrix){
        if (matrix == null) return false;
        for (var y = 0; y < matrix.length; y++){
            for (var x = 0; x < matrix[0].length; x++){
                const id = matrix[y][x];
                if (id == -1) continue;
                const node = this.mindmap.getNodeInfoById(id);
                if (!this.canPlaceNode(node, x, y, matrix)) return true;
            }
        }
        return false;
    }
}class Tooltip extends Visualization{
    constructor(plotId, props={}){
      super(plotId, props);
      this.enableZoom(false);
    }
  
    _initProperties(props){
      this._nLines = 0;
      if (!props.hasOwnProperty('parentHtmlTag')){
        props['parentHtmlTag'] = 'svg';
      }
      this._initialHeight = this._height;
      this._initialWidth = this._width;
      super._initProperties(props);
    }
  
    setSize(width, height, isDinamicIncrease=false){
      if (width > 0) this._width = width;
      if (height > 0) this._height = height;
      this._recalculateInnerSize();
      if (isDinamicIncrease === false){
        this._initialHeight = this._height;
        this._initialWidth = this._width;
      }
    }
  
    _checkResize(){
      const { fontSize } = this.getProperties();
      const height = this._innerHeight;
      const textHeight = this._nLines * fontSize * 1.1;
      if (textHeight > height){
        const diff = textHeight - height;
        var isDinamicIncrease = true;
        this.setSize(this._width, this._height + diff, isDinamicIncrease);
      }
    }
  
    _createTooltipBackground(){
      const {outerSVG} = this.getComponents();
      outerSVG
        .style('opacity', 1)
      .style('font-weight', 'normal')
      .style('text-align','center')
        .style('border-radius', '6px')
      .style('border','0px')
      .style('display', 'inline');
      
      const back = outerSVG.selectAll('rect.background')
      .data([null]).enter()
      .append('rect')
      .attr('class','background')
        .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', this._backgroundColor)
      .lower();
    }
  
    _initContainer(){
      this.setSize(this._initialWidth, this._initialHeight);
      super._initContainer();
      const { innerSVG , container, div} = this.getComponents();
      const { fontFamily, fontSize } = this.getProperties();
  
      div.style('transition','visibility 0.5s')
      .on('mouseover', d => this.show())
      .on('mouseout' , d => this.hide());
  
      innerSVG.select('rect.background').remove();
      this._createTooltipBackground();
      container.selectAll('text.text').data([null])
      .enter()
      .append('text')
      .attr('class','text')
      .style('width',this._innerWidth)
      .style('display','block')
      .style('font-size', fontSize + 'px')
      .style('font-family', fontFamily)
      .attr('y','1em')
      .attr('x','0%');
    }
  
    _refreshComponents(){
      super._refreshComponents();
      const {div, outerSVG, innerSVG, container, background} = this.getComponents();
      const { width, height } = this.getProperties();
      div.attr('height', height + 'px')
      .attr('width', width + 'px');
    }
  
    getComponents(){
      const components = super.getComponents();
      components.text = components.container.select('text.text');
      components.background = components.outerSVG.select('rect.background');
      return components;
    }
  
    _addOneLineInsideText(text,line){
      const x = text.attr('x');
      var lineHeight = 1.1; // ems
      if (text.selectAll('tspan.tooltipLine').size() == 0){
        lineHeight = 0;
      }
      var tspan = text.append('tspan').attr('class','tooltipLine');

      tspan.text(line)
      .attr('x',x)
      .attr('dy',lineHeight + 'em');

      return tspan;
    }
    
    _addLinesInsideText(text,lines){
      lines.forEach( line => this._addOneLineInsideText(text, line));
    }
  
    _addOneLinkInsideText(text,url,label){
      const x = text.attr('x');
      var lineHeight = 1.1; // ems
      if (text.selectAll('tspan.tooltipLine').size() == 0){
        lineHeight = 0;
      }
      const link = text.append('a')
      .append('tspan')
      .attr('class','tooltipLine')
      .text(label)
      .attr('x',x)
      .attr('dy',lineHeight + 'em');

      link.on('click', ()=>{window.open(url,"_blank")});
      link.style('cursor','pointer');

      return link;
    }
  
    _addLinksInsideText(text, urls, labels){
      var thisPlot = this;
      urls.forEach( function(url,i){ thisPlot._addOneLinkInsideText( text, url , labels[i] )});
    }
  
    move(x=null,y=null){
      const {div} = this.getComponents();
      if (x !== null) div.attr('x',x);
      if (y !== null) div.attr('y',y);
    }
  
    hide(){
      const {div} = this.getComponents();
      div.style('visibility','hidden');
      this._hideAux();
    }
  
    // Custom Steps After hidding
    _hideAux(){}
  
    executeFunctionAfterHide(func){
      if (typeof(func) != 'function') return;
      this._hideAux = func;
    }
  
    show(){
      const {div} = this.getComponents();
      div.style('visibility','unset');
      div.style('opacity','1.0');
      this._showAux();
    }
  
    // Custom Steps After Showing
    _showAux(){}
  
    executeFunctionAfterShowing(func){
      if (typeof(func) != 'function') return;
      this._showAux = func;
    }
  
    addLine(line){
      const {text} = this.getComponents();
      const width = this._innerWidth;
      var lines = wordplot.helpers.getSplittedText(line, width, text);
      if (line === '\n') var lines = [' '];
      this._addLinesInsideText(text,lines);
      this._nLines = text.selectAll('tspan.tooltipLine').size();
      this._checkResize();
    }
  
    addKeyValue(key,value){
      const {text} = this.getComponents();
      const width = this._innerWidth;
      
      const x = text.attr('x');
      var lineHeight = 1.1; // ems
      if (text.selectAll('tspan.tooltipLine').size() == 0){
        lineHeight = 0;
      }
      var tspan = text.append('tspan')
      .attr('class','tooltipLine')
      .attr('x',x)
      .attr('dy',lineHeight + 'em')
      .style('margin','20px');

      tspan.append('tspan')
      .text(`${key}: `)
      .style('font-weight','bold')

      tspan.append('tspan')
      .text(`${value}`)


      this._nLines = text.selectAll('tspan.tooltipLine').size();
      this._checkResize();
    }

    addLink(url,label){
      const {text} = this.getComponents();
      const width = this._innerWidth;
      const labels = wordplot.helpers.getSplittedText(label, width, text);
      const urls = [];
      labels.forEach( d => urls.push(url));
      this._addLinksInsideText(text,urls,labels);
      this._nLines = text.selectAll('tspan.tooltipLine').size();
      this._checkResize();
    }
  
    clean(){
      const {text} = this.getComponents();
      text.selectAll('tspan').remove();
      text.selectAll('a').remove();
      this._nLines = 0;
    }
}
class MindMap {
/**

nodes
edges
ids

neighbors { id : Array }

isNeighbor(a,b)
getNeighbors(a)
getNodeInfoById(id)
getNodeInfoByLabel(label)


*/
    constructor(json, props){
        this.originalData = JSON.parse(JSON.stringify(json));

        this.properties = {
            onlyCompounds: false,
            filterMinWeight : 0,
            filterMinValue : 0,
            filterGroups : [],
            maxNodes: null,
            valueField: 'value',
            hideNodes: [],
        }

        this._nodes = [];
        this._edges = [];
        this._neighbors = {};
        this._idToIndex = {};
        this._labelToIndex = {};

        this.setProperties(props);

        const { valueField } = this.getProperties();

        const { nodes , edges } = this.getOriginalData();
        const sortedNodes = this._sortArrayOfObjects(nodes,valueField);
        const sortedEdges = this._sortArrayOfObjects(edges,valueField);
        this.modifyOriginalData({
            nodes: sortedNodes,
            edges: sortedEdges,
        });

        this._processData();
    }

    setProperties(props){
        const details = {
            reprocess: false,
            changes: null,
        }

        if (typeof(props) !== "object") return details;

        const propsDetails = {
            filterMinWeight:{
                type:'number',
            },filterMinValue:{
                type:'number',
            },filterGroups:{
                type:'array',
            },onlyCompounds:{
                type:'boolean',
            },maxNodes:{
                type:'number',
            },valueField:{
                type:'string',
            },hideNodes:{
                type:'array',
                preprocess:'comma-separated',
            }
        };

        const oldProps = this.getProperties();
        const newProps = props;
        const changes = wordplot.properties.setProperties(propsDetails, oldProps, newProps);
        details.changes = changes;

        const refreshFields = [
            'filterMinWeight','filterMinValue','filterGroups','onlyCompounds','hideNodes','maxNodes',
        ];

        for (let i = 0; i < refreshFields.length; i++) {
            const field = refreshFields[i];
            if (changes[field] != null){
                details.reprocess = true;
                break;
            }
        }

        if (details.reprocess === true) {
            this._processData();
            details.reprocess = true;
        }

        return details;
    }

    getData(){
        return {
            nodes: this._nodes,
            edges: this._edges,
            neighbors: this._neighbors,
            idToIndex: this._idToIndex,
            labelToIndex: this._labelToIndex,
        }
    }

    getProperties(){
        return this.properties;
    }

    getOriginalData(){
        const original = this.originalData;
        
        if (original.hasOwnProperty('data')){
            var data = original.data;
        } else {
            var data = original;
        }
        
        const title = original.title;
        const nodes = data.nodes;
        const edges = data.edges;
        const legends = data.legends;
        const uniqueIdentifier = data.unique_identifier;
        const indicators = data.indicators;

        return {
            title, 
            nodes, edges, legends, uniqueIdentifier, indicators,
        };
    }

    modifyOriginalData(data){
        const {nodes,edges} = data;

        var data = this.originalData;
        if (data.hasOwnProperty(data)) data = data.data;

        if (Array.isArray(nodes)){
            data.nodes = nodes;
        }

        if (Array.isArray(edges)){
            data.edges = edges;
        }
    }

    getNeighborsById(id){
        const {neighbors} = this.getData();
        const neighborList = neighbors[id];
        if (neighborList == null) return [];
        return neighborList;
    }

    getNeighborsByLabel(label){
        const node = this.getNodeInfoByLabel(label);
        const id = node.id;
        const neighbors = this.getNeighborsById(id);
        return neighbors;
    }

    getNodeInfoById(id){
        const index = this._idToIndex[id];
        const {nodes} = this.getData();
        const node = nodes[index];
        return node;
    }

    getNodeInfoByLabel(label){
        const index = this._labelToIndex[label];
        const {nodes} = this.getData();
        const node = nodes[index];
        return node;
    }

    hasId(id){
        const { idToIndex } = this.getData();
        const has = idToIndex.hasOwnProperty(id);
        return has;
    }

    hasLabel(label){
        const { labelToIndex } = this.getData();
        const has = labelToIndex.hasOwnProperty(label);
        return has;
    }

    /** REMOVE  */
    removeElementByLabel(label){
        const { hideNodes } = this.getProperties();
        if (hideNodes.includes(label)) return;
        if (!this.hasLabel(label)) return;
        hideNodes.push(label);
        this._processData();
    }

    removeElementById(id){
        const label = this.getNodeInfoById(id).label;
        return this.removeElementByLabel(label);
    }

    removeListOfElementsByName(listOfElements){
        if (!Array.isArray(listOfElements)) return;
        
        listOfElements.forEach((label)=>{
            const { hideNodes } = this.getProperties();
            if (hideNodes.includes(label)) return;
            if (!this.hasLabel(label)) return;
            hideNodes.push(label);
        });
        this._processData();
    }


    _processData(){
        this._neighbors = {};
        this._idToIndex = {};
        this._labelToIndex = {};

        /**/
        var {nodes} = this.getOriginalData();
        const {maxNodes} = this.getProperties();
        if (typeof(maxNodes) == 'number' && Array.isArray(nodes) && nodes.length > 0){
            const {minWeight, minValue, count} = wordplot.graph.shrink.findOptimalFilters(nodes, maxNodes);
            if (count != null) console.log(`Map Shrink: Nodes:'${count}', MinWeight:'${minWeight}', MinValue:'${minValue}'`);
            this.properties.filterMinWeight = minWeight;
            this.properties.filterMinValue = minValue;
        }
         /**/

        var {nodes,edges} = this._filterData();

        this._nodes = nodes;
        this._edges = edges;

        this._processNeighbors();
        
        // IdToIndex and LabelToIndex
        for (let i = 0; i < nodes.length; i++) {
            const id = nodes[i].id;
            const label = nodes[i].label;
            this._idToIndex[id] = i;
            
            if (this.hasLabel(label)) continue;
            this._labelToIndex[label] = i;
        }

        // Neighbors List
        const neighbors = this._neighbors;
        edges.forEach((edge)=>{
            const {from,to} = edge;
            if (neighbors[from] == null){
                neighbors[from] = new Set();
            }
            if (neighbors[to] == null){
                neighbors[to] = new Set();
            }

            neighbors[from].add(to);
            neighbors[to].add(from);
        });

        // Convert Sets to Lists
        Object.keys(neighbors).forEach((id)=>{
            neighbors[id] = Array.from(neighbors[id]);
        });
    }

    _filterData(){
        const {
            filterMinValue , filterMinWeight , filterGroups, onlyCompounds, hideNodes,
        } = this.getProperties();

        var { nodes , edges } = this.getOriginalData();

        const valueFilterIsActive = (filterMinValue > 0);
        const weightFilterIsActive = (filterMinWeight > 0);
        const groupFilterIsActive = (filterGroups.length > 0);
        const hideNodesIsActive = (hideNodes.length > 0);

        const filteredNodes = [];
        const filteredEdges = [];
        const filteredIds = new Set();

        const filteredData = {
            nodes: filteredNodes,
            edges: filteredEdges,
            ids: filteredIds,
        };
    
        const groups = new Set(filterGroups);
    
        // Filter nodes
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            this._normalizeNodeInfo(node);
            const {group,id,value,label,weight} = node;
    
            if (groupFilterIsActive && !groups.has(group)) continue;
            if (valueFilterIsActive && value < filterMinValue) continue;
            if (weightFilterIsActive && weight < filterMinWeight) continue;
            if (onlyCompounds && !this._isCompound(label)) continue;
            if (hideNodesIsActive && hideNodes.includes(label)) continue;
            
            filteredNodes.push(node);
            filteredIds.add(id)
        }

        // Filter edges based on final nodes
        for (let i = 0; i < edges.length; i++) {
            const edge = edges[i];
            if (!filteredIds.has(edge.to) || !filteredIds.has(edge.from)) continue;
            filteredEdges.push(edge);
        }

        return filteredData;
    }

    _processNeighbors(){
        const { edges , idToIndex } = this.getData();

        for (let i = 0; i < edges.length; i++) {
            const {from,to} = edges[i];
            if (idToIndex[from] == null) idToIndex[from] = new Set();
            if (idToIndex[to] == null) idToIndex[to] = new Set();

            idToIndex[from].add(to);
            idToIndex[to].add(from);
        }
    }

    // Can be migrated later to a helper section

    _isCompound(label){
        if (typeof(label) !== 'string') return false;
        label = label.trim();
        const words = label.split(/[\s_]+/);
        return (words.length > 1);
    }

    _sortArrayOfObjects(array, field){
        if (array.length === 0) return array;
        var sorted = array.sort((a,b) =>  b[field]-a[field]);
        return sorted
    }

    _normalizeNodeInfo(node){
        node.group = Number.parseInt(node.group);
    }
}var version = '20.0.0';

function loadScript(url) {
    return new Promise(function(resolve, reject) {
        var script = document.createElement('script');
        script.onload = resolve;
        script.onerror = reject;
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

function loadDependency(value, url) {
    if (value === true) {
        return Promise.resolve();
    } else {
        return loadScript(url);
    }
}

const importer = {
    url: (dependency) => {
        if (dependency.isLoaded) return Promise.resolve();
        return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = dependency.url;
        script.addEventListener('load', () => resolve(script), false);
        script.addEventListener('error', () => reject(script), false);
        document.body.appendChild(script);
      });
    },
    urls: (dependencies) => {
      return Promise.all(dependencies.map(importer.url));
    }
  };

let loadPromise = null;

function load(){
    var dependencies = [
        {'isLoaded':window['d3']?d3.hexbin?true:false:false, 'url':'https://d3js.org/d3-hexbin.v0.2.min.js'},
        {'isLoaded':window['d3']?d3.select?true:false:false, 'url':'https://d3js.org/d3.v6.min.js'}
    ]

    if (loadPromise == null){
        loadPromise = importer.urls(dependencies);
    } return loadPromise;
}

return {
    Visualization,
    AxisVisualization,
    Tooltip,
    buildVisualization,
    line,
    logo: {
        initLogo,
    },
    helpers,
    properties,
    wordmap: {
        MindMap,
        WordMap,
        HexagonMap,
        SquareMap,
        buildWordMap,
        buildMapSeries,
        plotLegend,
        setSDGColorsInMap,
        configureMapWithSignals,
        centerMap,
        MapSeries,
        enableRelevancyMode,
        setColorsToMindMap,
        interaction,
    },
    top,
    graph: {
        GraphToMap,
        WideGraphToMap,
        GraphProcessor,
        HexagonGraphProcessor,
        SquareGraphProcessor,
        shrink,
    },
    forms: {Form},
    json,
    bars,
    web,
    version,
    save,
    megatron,
    load
}})();

export default wordplot;

