import React from 'react';

import { Navigate } from "react-router-dom";
import withRouter from '../components/withRouter';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';

class LogoutPage extends React.Component{
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    componentDidMount(){
        // Set JWT Globally
        this.context.setJwt(null);
    }

    render(){
        // Get Global Variable Setter to Update Token Globally
        return <Navigate to="/"/>
    }
}

// With Router passes URL info to Class Component
export default withRouter(LogoutPage);