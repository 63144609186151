import { Item, Icon, Label, Button } from "semantic-ui-react";
import { processCourse } from "../../utils/courses.utils";

import React, { useState } from "react";

import { CourseDetails } from "./CourseDetails";

export const CourseCard = ({course}) => {
    const {
        title, shortDescription, 
        newSkills, existingSkills, score, code
    } = processCourse(course);

    const [modalIsVisible, showModal] = useState(false);
    const [isSelected, setSelected] = useState(course['selected'] === true);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    const openModal = () => showModal(true);

    return (
        <>
            <CourseDetails
                showModal={showModal}
                modalIsVisible={modalIsVisible}
                course={course}
                courseIsSelected={isSelected}
                setSelected={setSelected}
            ></CourseDetails>
            <Item onClick={()=> openModal()}>
                <Item.Content>
                    <Item.Header as="a">
                        {title}
                    </Item.Header>
                    <Item.Description>
                        {shortDescription}
                    </Item.Description>
                    {/** Summary - Missing and Matching **/}
                    <Item.Meta>
                        <span>
                            <Icon color="blue" name="bookmark" />
                            {" "}{newSkills.length}{" "}
                            {"New Skills"}
                        </span>
                        <span>
                            <Icon color="orange" name="bookmark" />
                            {" "}{existingSkills.length}{" "}
                            {"Strenghtened Skills"}
                        </span>
                    </Item.Meta>
                    <Item.Meta>
                        <Label content={"Relevancy"} />
                        <Label
                            color={course.relevancy >= 0.8 ? "blue" : null}
                            circular
                            basic
                            content={score.toFixed(1)}
                        />
                    </Item.Meta>
                </Item.Content>
            </Item>
        </>
    )
}