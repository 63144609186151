import axios from "axios";

async function Login(email, password){
    const data = {email,password};
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/auth/login';
    var response = await axios.post(url, data);
    if (response == null) return null;
    const content = response.data;
    return content;
}

async function CreateAccount(data){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/auth/signup';
    var response = await axios.post(url, data);
    if (response == null) return null;
    const content = response.data;
    return content;
}

async function VerifyAccount(token){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/auth/verify';
    const params = {token};
    var response = await axios.get(url, {params});
    if (response == null) return null;
    const content = response.data;
    return content;
}

async function ResendVerificationEmail(token){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/auth/send_verification';
    const params = {token};
    var response = await axios.get(url, {params});
    if (response == null) return null;
    const content = response.data;
    return content;
}

export {
    Login,
    CreateAccount,
    VerifyAccount,
    ResendVerificationEmail,
}