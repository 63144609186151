import { Container, Grid, Icon } from "semantic-ui-react";

function Footer(){
    return (
        <Container fluid style={{backgroundColor: '#FAFAFA'}} textAlign='center'>
            <Grid stackable divided>
                <Grid.Column width={3}>
                    <a href="https://headai.com/upgrade/" target="_blank">Contact sales</a>
                </Grid.Column>
                <Grid.Column width={3}>
                <a href="https://headai.com/shop/" target="_blank">Visit Headai store</a>
                </Grid.Column>
                <Grid.Column width={3}>
                    <a href="https://headai.com/eula" target="_blank">Headai EULA</a>
                </Grid.Column>
                <Grid.Column width={3}>
                    <a href="https://headai.com/privacy_policy.html" target="_blank">Privacy Policy</a>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Icon name='copyright outline'/>Headai 2023
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default Footer;