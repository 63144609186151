import jwtServices from "./jwt.service";

function isLoggedIn(token){
    if (token == null) return false;
    if (typeof(token) !== "string") return false;
    if (token.length === 0) return false;
    if (!jwtServices.jwtIsValid(token)) return false;
    return true;
}

function accountIsVerified(token){
    if (token === null) return false;
    const decoded = jwtServices.decodeJwt(token);
    const isVerified = (decoded.verified === true);
    return isVerified;
}

const SessionServices = {
    isLoggedIn,
    accountIsVerified,
}

export default SessionServices;