import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';
import './pages/Pages.css';
// Semantic UI
import { Segment } from 'semantic-ui-react'

import NavBar from './components/navbar/NavBar';

// Pages
import CreateAccountPage from './pages/CreateAccount.page';
import DigitalTwinPage from './pages/DigitalTwin.page';
import HomePage from './pages/Home.page';
import LoginPage from './pages/Login.page';
import SelectGoalScorecardPage from './pages/SelectGoalScorecard.page';
import PageNotFound from './pages/PageNotFound.page';
import LogoutPage from './pages/Logout.page';
import VerifyAccountPage from './pages/VerifyAccount.page';
import CheckYourEmailPage from './pages/CheckYourEmail.page';
import Footer from './components/footer/Footer';

// Google Analytics
// import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import { useEffect } from 'react';
import HomeNotLoggedInPage from './pages/HomeNotLoggedIn.page';
const TRACKING_ID = "G-Z6R65TG3KY"; // OUR_TRACKING_ID

// ReactGA.initialize(TRACKING_ID, { standardImplementation: true });
ReactGA4.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <>
      <NavBar />
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/logout' element={<LogoutPage />} />
          <Route path='/signup' element={<CreateAccountPage />} />
          <Route path='/verify' element={<VerifyAccountPage />} />
          <Route path='/DigitalTwin' element={<DigitalTwinPage />} />
          <Route path='/Scorecard' element={<SelectGoalScorecardPage />} />
          <Route path='/CheckYourEmail' element={<CheckYourEmailPage />} />
          <Route path='/MainPage' element={<HomeNotLoggedInPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <Segment attached='bottom' style={{ backgroundColor: 'rgb(250,250,250)' }}>
        <Footer />
      </Segment>
    </>
  );
}

export default App;
