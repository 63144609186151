import React from 'react';
// import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router-dom";
import withRouter from '../components/withRouter';
import '../App.css';

// Semantic UI
import { Message, Form } from 'semantic-ui-react'

import {Login} from '../services/auth.service';

// Handle Logged In State - Verified Account
import SessionServices from '../services/session.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import PlatformLogo from '../components/PlatformLogo';
// import Footer from '../components/footer/Footer';

class LoginPage extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        let navbar_height = 82.75;
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight - navbar_height,
            invalidCredentials: false,
            navbar_height: navbar_height,
            emailError: false
        };
    }

    componentDidMount(){
        document.title = "Login - Headai platform";
        // ...
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = (event) => {
        let height = window.innerHeight - this.state.navbar_height;
        this.setState({width: event.target.innerWidth, height: height});
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const formValues = event.target.elements;
        const email = formValues.email.value;
        const password = formValues.password.value;
        if (email.length === 0 || email.indexOf('@') < 0) {
            this.setState({
                emailError: true,
                invalidCredentials: true
            });
        } else {
            this.setState({
                emailError: false,
                invalidCredentials: false
            });
        }
        if (!this.state.emailError) {
            Login(email,password).then( response => {
                const credentialsAreInvalid = (response === null || response.code === 'InvalidCredentials');
                this.setState({
                        invalidCredentials : credentialsAreInvalid
                });    

                if (credentialsAreInvalid || response.token == null) return;

                // Set JWT Globally
                this.context.setJwt(response.token);
            });
        }
        //event.target.reset();
    }
    render(){
        /*******      Redirect to Home if already logged in       ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt)){
            const query = new URLSearchParams(this.props.location.search);
            const path = query.get('path');
            if (path == null)
                var redirectPath = '/';        
            else
                redirectPath = `/?path=${path}`;
            return <Navigate to={redirectPath}/>
        }
        /****************************************************************/

        var message = <></>
        if (this.state.invalidCredentials === true){
            message = MessageInvalidCredentials();
        }

        return (
            <>
                <div className="Flex" style={{marginBottom: '0'}}>
                    <div className="VerticalImage" style={{ height: 'auto' }}>
                        {/* <img src={"/VerticalSideImage.jpg"}></img> */}
                    </div>
                    <div className="RightContainer" >
                        <center>
                        
                        <div className="RightContent">
                            

                            <PlatformLogo/>
                            <br/><br/>
                            <h3>Predicting Your Next Action</h3>
                            <p className="description">
                                Generative AI usually predicts the next word or next pixel. Headai predicts the next action you should take in order to be Futureproofed.
                                {/* Welcome to an extraordinary journey where possibilities unfold and imagination takes flight. 
                                Prepare to embark on an adventure like no other, 
                                where dreams become reality and the ordinary becomes extraordinary. */}
                            </p>
                            <br/>
                            <hr/>

                            <h3 style={{textAlign:'left'}}>Login to your Account</h3>
                            <br/>
                            {message}
                            {/**.call() is used to pass the this context to the function*/}
                            <Form onSubmit={(event)=>{this.handleSubmit.call(this,event)}}>
                                <Form.Input
                                    className='pinkInput'
                                    id='email'
                                    autoFocus
                                    required
                                    fluid
                                    icon='at'
                                    iconPosition='left'
                                    //label='Email'
                                    error={this.state.emailError}
                                    placeholder='email'
                                />
                                <Form.Input
                                    className='pinkInput'
                                    id='password'
                                    required
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    //label='Password'
                                    type='password'
                                    placeholder='password'
                                />
                                <br/><br/>
                                <Button style={{width:'300px'}} className="PinkButton" block="true" size="lg" type="submit">
                                    Log in
                                </Button>
                            </Form>
                        </div>

                        <p style={{fontSize:'16px', marginBottom: '60px'}}>
                            Don't have an account? &emsp; &emsp;
                            <NavLink style={{color:'#98379a'}} className="navbar-item" to="/signup">
                                Sign up
                            </NavLink>
                        </p>

                        </center>
                    </div>
                </div>
            </>
        );
    }
}

function MessageInvalidCredentials(){
    return (
        
            <Message negative>
                <center>
                <Message.Header>An Error Occurred</Message.Header>
                <p>Invalid E-mail or Password</p>
                </center>
            </Message>
        
    )
}

// With Router passes URL info to Class Component
export default withRouter(LoginPage);