import React, {useState} from "react"
import jwtServices from "../services/jwt.service";

const MainContext = React.createContext();

function MainContextProvider({children}) {
    const initialJwt = jwtServices.getJwt();
    const [jwt, setJwtAux] = useState(initialJwt);


    const setJwt = (jwt)=>{
        const valueSet = jwtServices.setJwt(jwt);
        setJwtAux(valueSet);
    }

    const getJwt = () => {
        const isValid = jwtServices.jwtIsValid(jwt);
        if (!isValid){
            setJwt(null);
        }
        return jwt;
    }

    return (
        <MainContext.Provider value={{getJwt, setJwt}}>
            {children}
        </MainContext.Provider>
    )


}

export {MainContextProvider, MainContext}