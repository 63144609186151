import { Header, Item, Segment } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { CourseCard } from "./CourseCard";

export const CourseContainer = ({courses}) => {
    return (
        <div className="page-container">
            <Grid stackable className="course-suggestions-grid">
                <Grid.Row columns={1}>
                    <Grid.Column className="padless-column">
                        <CourseList courses={courses}></CourseList>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
        
    )
}

export const CourseList = ({courses}) => {
    if (!Array.isArray(courses)) return NoResults();
    if (courses.length === 0) return NoResults();

    return (
        <div>
            <Segment attached="top">
                <Header as="h4" content={courses.length + " results. Suggestions are based on Comparison map."}/>
            </Segment>

            <Segment>
                <Item.Group relaxed unstackable divided>
                    {courses.map((course, i) => (
                        <CourseCard key={i} course={course} />
                    ))}
                </Item.Group>
            </Segment>
        </div>
    )
}


const NoResults = () => {
    return (
        <Segment attached="top">
            <Header as="h4" content={"No Results"}/>
        </Segment>
    )
}