import React from 'react';

import withRouter from '../components/withRouter';
// import Button from 'react-bootstrap/esm/Button';
import { Button, Container, Grid, Icon } from 'semantic-ui-react'
import { Navigate } from "react-router-dom";

import Path1 from './Path1';
import Path2 from './Path2';
import Path3 from './Path3';

import SessionServices from '../services/session.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import Footer from '../components/footer/Footer';
import HomeNotLoggedInPage from './HomeNotLoggedIn.page';

class LoginPage extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        super(props);

        this.state = {
            initial_title: 'Select your path',
            title: 'Select your path',
            path1: 'Unlock YOUR potential. See recommendations to reach your dream career.',
            path2: 'Futureproof education. Use skills demand scenarios to stay a step ahead.',
            path3: 'Minä itse, futureproof',
            path4: 'Improve your data quality. Automatisoitu laatu analyysi ja metadata',
            selected_path: 0,
            path_selected: false,
            reset_hidden: true,
        };
    }

    componentDidMount(){
        // ...
        document.body.style = 'background: #FFFFFF';
        this.handle_visible_path();
    }
    handle_visible_path() {
        const query = new URLSearchParams(this.props.location.search);
        const path = query.get('path');
        if (path === '1') {
            this.start_path(1);
        } else if (path === '2') {
            this.start_path(2);
        } else if (path === '3') {
            this.start_path(3);
        } else if (path === '4') {
            // this.start_path(4);
            this.start_path(1);
        } else {
            this.start_path(1);
        }
    }
    start_path(path) {
        // Handle path select
        if (path === 1) {
            // console.log(this.state.path1);
            this.setState({title: this.state.path1});
        } else if (path === 2) {
            // console.log(this.state.path2);
            this.setState({title: this.state.path2});
        } else if (path === 3) {
            // console.log(this.state.path3);
            this.setState({title: this.state.path3});
        } else if (path === 4) {
            // console.log(this.state.path4);
            // this.setState({title: this.state.path4});
            this.setState({title: this.state.path1});
        } else {
            // console.log(this.state.path1);
            this.setState({title: this.state.path1});
        }
        this.setState({
            path_selected: true,
            reset_hidden: false,
            selected_path: path,
        });
    }
    reset_path() {
        // Handle path reset
        this.setState({
            reset_hidden: true,
            title: this.state.initial_title,
            path_selected: false
        });
        document.body.style = 'background: #FFFFFF';
    }
    
    render(){
        /*******      Redirect to Home if already logged in  ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt) == false){
            const query = new URLSearchParams(this.props.location.search);
            const path = query.get('path');
            if (path == null)
                return <HomeNotLoggedInPage />
            else
                var redirectPath = `/Login?path=${path}`;
                return <Navigate to={redirectPath}/>
        }

        if (SessionServices.accountIsVerified(jwt) == false){
            return <Navigate to="/CheckYourEmail"/>
        }
        /****************************************************************/

        // Handle path options
        let pathClass = '';
        if (this.state.path_selected) {
            pathClass = 'hidden';
        } else {
            pathClass = '';
        }
        // Reset path
        let resetClass = '';
        if (this.state.reset_hidden) {
            resetClass = 'hidden';
        } else {
            resetClass = '';
        }
        // Path selection
        let path;
        console.log('selected_path: '+this.state.selected_path);
        if (this.state.selected_path === 1) {
            path = <Path1 />;
        } else if (this.state.selected_path === 2) {
            path = <Path2 />;
        } else if (this.state.selected_path === 3) {
            path = <Path3 />;
        } else if (this.state.selected_path === 4) {
            // path = <h2>Path 4 selected</h2>;
            path = <Path1 />;
        } else {
            path = <Path1 />;
        }
        return (
            <>
                {/* <center>
                    <h1>Home Page</h1>
                </center> */}
                <div id="main_container">
                    {/* <div className={resetClass}>
                        <center><Button color='blue' content='Reset path' onClick={() => this.reset_path()} /></center>
                    </div> */}
                    <div>
                        {/* <center>
                            <h1>{this.state.title}</h1>
                        </center> */}
                    </div>
                    <div id='SelectPath' className={pathClass}>
                        <div id='Path1' className='path_options'><center><Button onClick={() => this.start_path(1)}>Select</Button></center><p>{this.state.path1}</p></div>
                        <div id='Path2' className='path_options'><center><Button onClick={() => this.start_path(2)}>Select</Button></center><p>{this.state.path2}</p></div>
                        <div id='Path3' className='path_options'><center><Button onClick={() => this.start_path(3)}>Select</Button></center><p>{this.state.path3}</p></div>
                        <div id='Path4' className='path_options'><center><Button disabled onClick={() => this.start_path(4)}>Select</Button></center><p>{this.state.path4}</p></div>
                    </div>
                    <div id='path_content' className={resetClass}>
                    
                        {path}
                        
                    </div>
                    {/* <Footer /> */}
                </div>
            </>
        );
    }
}

// With Router passes URL info to Class Component
export default withRouter(LoginPage);