import axios from "axios";
const jwtServices = require('../services/jwt.service');

async function getDataUI(jwt){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/data/path3';
    const params = {jwt};
    var response = await axios.get(url, {params});
    if (response == null) return null;
    const content = response.data;
    return content;
}


export {
    getDataUI,
}