import Nav from 'react-bootstrap/Nav';

function SignupButton() {
    return (
        <>
            <Nav.Link href="/signup">SignUp</Nav.Link>
        </>
    )
}

export default SignupButton;