import React from 'react';

import withRouter from '../components/withRouter';

class PageNotFound extends React.Component {
    constructor(props){
        super(props);

        this.state = {

        };
    }

    componentDidMount(){
        // ...
    }

    render(){
        return (
            <>
                <center>
                    <h1>404 Page Not Found</h1>
                </center>
            </>
        );
    }
}

// With Router passes URL info to Class Component
export default withRouter(PageNotFound);