import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

import './NavBar.css';
import SignupButton from './SignupButton';

import {MainContext} from '../../contexts/MainContext';
import { Button, Icon } from 'semantic-ui-react';

function NavBar(props) {
  const jwt = React.useContext(MainContext).getJwt();

  const loggedIn = typeof(jwt) == "string" && jwt.length > 0;
  var sessionButtons = <></>;

  if (loggedIn === true){
    sessionButtons = <LogoutButton />
  } else {
    sessionButtons = (<>
      <LoginButton />
      <SignupButton />
    </>)
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="https://headai.com">
            <img alt="logo" className="logo-navbar" src="/headai-logo-horizontal.png"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <NavDropdown title="Use cases" href="#action" id="basic-nav-dropdown">
              <NavDropdown.Item href="/?path=1">Unlock your potential</NavDropdown.Item>
              <NavDropdown.Item href="/?path=2">Future-Proof Education</NavDropdown.Item>
              <NavDropdown.Item href="/?path=3">Sustainable Strategies</NavDropdown.Item>
              <NavDropdown.Item disabled href="/?path=4"><Icon name='lock'/>Data quality</NavDropdown.Item>
            </NavDropdown>
            {sessionButtons}
          </Nav>
        </Navbar.Collapse>
        {/* <Nav.Link href="/" style={{width: '100%'}}><Button style={{float: 'right'}}><Icon name='rocket'></Icon>Upgrade your plan</Button></Nav.Link> */}
        <div style={{width: '100%'}}><Nav.Link href="https://headai.com/upgrade/" target="_blank"><Button style={{float: 'right', backgroundColor: '#98379A', color: '#FFFFFF'}}>Upgrade your plan</Button></Nav.Link></div>
      </Container>
    </Navbar>
  );
}

export default NavBar;