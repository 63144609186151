import { useContext, useState } from 'react';

// Semantic UI
import { Message, Form, Modal } from 'semantic-ui-react'

import { MainContext } from '../../contexts/MainContext';
import { UploadMap } from '../../services/maps.service';
import { DocumentToText } from '../../services/megatron.service';

async function handleSubmit(params){
    const {
        event, getJwt, setCode, triggerRefresh, showModal, setContent,
    } = params;

    const jwt = getJwt();
    const formValues = event.target.elements;
    const title = formValues.map_title.value;
    const content = formValues.map_content.value;

    const response = await UploadMap(title, content, jwt);
    const {code, location} = response;
    setCode(code);
    if (code == null && typeof(location) == 'string' && location.length > 0) triggerRefresh();
    showModal(false);
    setContent('');
}

async function handleUploadFile(event,setContent,getJwt){
    event.preventDefault();

    const files = event.target.files;
    if (files.length == 0) return;

    const jwt = getJwt();
    const file = files[0];
    const params = {file,jwt};
    const response = await DocumentToText(params);

    const text = response.text;
    setContent(text);
}

async function handleContentChange(event,setContent){
    const newContent = event.target.value;
    setContent(newContent);
}

function MessageTitleExists(){
    return (
        
            <Message negative>
                <center>
                <Message.Header>An Error Occurred</Message.Header>
                <p>You have already used that title in another Custom MindMap</p>
                </center>
            </Message>
        
    )
}

export const UploadFileModal = (params) => {
    var {
        modalIsVisible=false,
        showModal,
        triggerRefresh = null,
    } = params;

    if (typeof(triggerRefresh) !== "function") triggerRefresh = () => {};

    const {getJwt} = useContext(MainContext);

    // This code is taken from the submit
    const [code, setCode] = useState(null);
    const [content, setContent] = useState('');

    const title = "Upload your own Data";

    var message = <></>;
    if (code == 'TitleExists') message = MessageTitleExists();

    return (
        <Modal
            open={modalIsVisible}
            onClose={()=>{showModal(false); setContent('')}}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content scrolling>
                {message}
                <Modal.Description>
                    <h4>
                        You can upload your own file or write the content that will be used to create the MindMap.
                    </h4>

                    <form id="CVForm" onChange={(event)=>{handleUploadFile(event,setContent,getJwt)}}>
                        <input id="file" type="file" name="fileToUpload" />
                    </form>

                    <br />

                    <Form onSubmit={(event)=>{handleSubmit({event,getJwt,setCode,setContent,triggerRefresh,showModal})}}>
                        <Form.Input
                            onChange={()=>{if (code == 'TitleExists') setCode(null)}}
                            id='map_title'
                            label='Title'
                            autoFocus
                            placeholder="Write the name that will identify the current data that you are uploading"
                            required
                        />
                        <Form.TextArea
                            id='map_content'
                            label='Content'
                            placeholder='Insert here the text that will be used for the analysis (E.j The content of your CV)'
                            required
                            value={content}
                            onChange={(event)=>{handleContentChange(event,setContent)}}
                        />
                        <Form.Button
                            id='submit'
                            label='Create a MindMap from your custom data'
                        >Upload</Form.Button>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );

}