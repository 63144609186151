import axios from "axios";

async function Scorecard(params){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/megatron/Scorecard';

    var response = await axios.get(url, {params});
    if (response == null) return null;
    const content = response.data;
    return content;
}

async function DocumentToText(params){
    const {jwt, file} = params;
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/megatron/DocumentToText';

    const formData = new FormData();
    formData.append('file',file);
    formData.append('jwt',jwt);

    const headers = {
        "Content-Type": "multipart/form-data",
    }

    const response = await axios.post(url, formData, { headers });

    return response.data;       
}

async function Compass(params){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/megatron/Compass';

    var response = await axios.post(url, params);
    if (response == null) return null;
    const content = response.data;
    return content;
}

// Sleep after checking if map is ready
const SLEEP_TIME = 1000;

async function waitUntilReady(response){
    var { status , location } = response;
    while (status === 'work in progress' || status === 'New calculation requested. Work is in queue.'){
        // Sleep
        await new Promise(r => setTimeout(r, SLEEP_TIME));
        response = await axios.get(location);
        if (response == null) return null;
        var { status , location } = response.data;
    }
    return response.data;
}

export {
    Scorecard,
    Compass,
    DocumentToText,
    waitUntilReady,
}