import React from 'react';

// Semantic UI
import { Message, Form } from 'semantic-ui-react'

// React Bootstrap
import Button from 'react-bootstrap/Button';

import { Navigate, NavLink } from "react-router-dom";

import withRouter from '../components/withRouter';

import * as authServices from '../services/auth.service';

import SessionServices from '../services/session.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import PlatformLogo from '../components/PlatformLogo';
import Footer from '../components/footer/Footer';

class CreateAccount extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        super(props);

        this.state = {
            errorType:null,
        };
    }

    componentDidMount(){
        // ...
        document.title = "Signup - Headai platform";
    }

    async handleSubmit(event){
        const formValues = event.target.elements;
        const data = {
            email : formValues.email.value,
            first_name : formValues.first_name.value,
            last_name : formValues.last_name.value,
            password : formValues.password.value,
            company: formValues.company.value,
            title: formValues.title.value,
            awareness_description: formValues.awareness_description.value,
            country: formValues.country.value,
        };
        // User must accept terms and conditions
        if (formValues.terms_conditions.checked) {

            const response = await authServices.CreateAccount(data);

            if (response == null){
                this.setState({errorType:'CreationError'});
                return;
            }

            const code = response.code;
            const token = response.token;

            this.setState({
                errorType:code
            });

            // Set JWT Globally
            this.context.setJwt(token);

            console.log(response,'Response from Create Account');
        } else {
            this.setState({errorType:'terms'});
            return;
        }
            
    }


    render(){
        /*******      Redirect to Home if already logged in       ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt)){
            return <Navigate to="/"/>
        }
        /****************************************************************/

        var message = (<div></div>)

        switch (this.state.errorType) {
            case 'UserAlreadyExists':
                message = messageUserExists();
                break;
            case 'terms':
                message = termsAndConditions();
                break;
            default:
                break;
        }

        return (
            <>

                <div className="Flex" style={{marginBottom: '0'}}>
                    <div className="VerticalImage" style={{ height: 'auto' }}>
                        {/* <img src={"/VerticalSideImage.jpg"}></img> */}
                    </div>
                    <div className="RightContainer">
                        <center>
                        <div className='RightContent' style={{paddingTop:'10px'}}>
                            {message}

                            {/**.call() is used to pass the this context to the function*/}
                            <Form onSubmit={(event)=>{this.handleSubmit.call(this,event)}}>
                                <Form.Input
                                    className='pinkInput' 
                                    id='first_name'
                                    label='First Name'
                                    autoFocus
                                    icon='address book'
                                    iconPosition='left'
                                    placeholder="Enter your first name"
                                    required
                                />

                                <Form.Input
                                    className='pinkInput'
                                    id='last_name'
                                    label='Last Name'
                                    icon='address book'
                                    iconPosition='left'
                                    placeholder="Enter your last name"
                                    required
                                />
                                
                                <Form.Input
                                    className='pinkInput'
                                    id='email'
                                    required
                                    fluid
                                    icon='at'
                                    iconPosition='left'
                                    label='Email'
                                    placeholder='Your e-mail goes here'
                                />

                                <Form.Input
                                    className='pinkInput'
                                    id='password'
                                    required
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Password'
                                    type='password'
                                    placeholder='Password'
                                />

                                <Form.Input 
                                    className='pinkInput'
                                    id='company'
                                    label='Company'
                                    icon='building'
                                    iconPosition='left'
                                    required
                                    placeholder="Company you are currently working in"
                                />

                                <Form.Input 
                                    className='pinkInput'
                                    id='title'
                                    label='Title'
                                    icon='briefcase'
                                    iconPosition='left'
                                    required
                                    placeholder="Which title suits you best"
                                />

                                <Form.Input 
                                    className='pinkInput'
                                    id='country'
                                    label='Country'
                                    icon='flag'
                                    iconPosition='left'
                                    required
                                    placeholder="Where do you live?"
                                />

                                <Form.Input 
                                    className='pinkInput'
                                    id='awareness_description'
                                    label='How did you hear about Headai?'
                                    icon='heart'
                                    iconPosition='left'
                                    placeholder="We'd love to hear how you heard about us"
                                />

                                <Form.Checkbox
                                    id='terms_conditions'
                                    inline
                                    label={<label>I agree to the <a style={{color:'#98379a'}} href='https://headai.com/eula' target='_blank'>Terms and Conditions</a></label>}
                                    required
                                />

                                <center>
                                    <Button style={{width:'300px'}} className="PinkButton" block="true" size="lg" type="submit">
                                        Create Account
                                    </Button>
                                </center>

                                <br/>
                                <p style={{fontSize:'16px'}}>
                                    Already have an account? &emsp; &emsp;
                                    <NavLink style={{color:'#98379a'}} className="navbar-item" to="/Login">
                                        Log in
                                    </NavLink>
                                </p>
                            </Form>
                        </div>
                        </center>
                    </div>
                </div>
                {/* <Footer /> */}
            </>
        );
    }
}

function messageUserExists(){
    return (
        <Message negative>
            <center>
            <Message.Header>An Error Occurred</Message.Header>
            <p>User Already Exists</p>
            </center>
        </Message>
    )
}
function termsAndConditions() {
    return (
        <Message negative>
            <center>
            <Message.Header>An Error Occurred</Message.Header>
            <p>Please accept terms and conditions</p>
            </center>
        </Message>
    )
}

// With Router passes URL info to Class Component
export default withRouter(CreateAccount);