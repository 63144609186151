import { Modal, Button, Header, Divider } from "semantic-ui-react";
import { processCourse, formatSkill } from "../../utils/courses.utils";

export const CourseDetails = ({course, modalIsVisible, showModal}) => {
    const {
        title, mediumDescription, 
        newSkills, existingSkills,
        url, code
    } = processCourse(course);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    return (
        <Modal
            open={modalIsVisible}
            onClose={()=>{showModal(false)}}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>

                    {mediumDescription}

                    <Divider section />

                    <Header size="medium">{"New Skills"}</Header>
                    {newSkills.length === 0 ? (
                        "Empty"
                    ):(
                        newSkills.map((skill, index)=>(
                            <Button
                                basic
                                skill={skill}
                                //appereance="subtle"
                                color="blue"
                                key={index}
                                //onClick={(event, params)=>{clickOnMissingSkill(skill, selectedSkills,setSelectedSkills)}}
                            >
                                {formatSkill(skill)}
                            </Button>
                        ))
                    )}

                    <Divider section />

                    <Header size="medium">{"Strenghtened Skills"}</Header>
                    {existingSkills.length === 0 ? (
                        "Empty"
                    ):(
                        existingSkills.map((skill, index)=>(
                            <Button
                                basic
                                color="orange"
                                key={index}
                            >
                                {formatSkill(skill)}
                            </Button>
                        ))
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    as="a"
                    color="blue"
                    content="Read More"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                />
            </Modal.Actions>
        </Modal>
    )
}