import { useState, useEffect, useContext } from 'react';
import { Header, Segment, Button, Container, Popup } from 'semantic-ui-react'
import {CourseContainer} from './CourseList';

import * as CourseServices from '../../services/courses.service';

// Used to Load Global JWT Variable
import { MainContext } from '../../contexts/MainContext';

function CourseRecommendations(props){
    const [ compass_recommendations, setRecommendations ] = useState([]);
    
    const {
        compare_url, namespace,
    } = props;

    const invalidMap = (typeof(compare_url) !== "string" || compare_url.length == 0);

    const {getJwt} = useContext(MainContext);
    const jwt = getJwt();
    
    useEffect(()=>{
        if (invalidMap) return;
        CourseServices.loadRecommendations(compare_url, namespace, jwt).then((recommendations) => {
            // Update state of the components
            setRecommendations(recommendations);
        });
    },[compare_url,namespace]);
    /** Compare_url and namespace are two dependencies of the function useEffect()
     *  Only fetches recommendations if compare_map or namespace changed
     */

    if (invalidMap) return NoValidMap();

    return (
        <CourseContainer courses={compass_recommendations}></CourseContainer>
    );
}

function NoValidMap(){
    return (
        <Segment attached="top">
            <Header as="h4" content={"You haven't generated a comparison in the previous step."}/>
        </Segment>
    );
}

export default CourseRecommendations;