import React from 'react';

import withRouter from '../components/withRouter';

// Handle Logged In State - Verified Account
import SessionServices from '../services/session.service';
import { Navigate } from "react-router-dom";

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';

class SelectGoalScorecard extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;
    
    constructor(props){
        super(props);

        this.state = {

        };
    }

    componentDidMount(){
        // ...
    }

    render(){
        /*******      Redirect to Home if already logged in       ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt) == false){
            return <Navigate to="/Login"/>
        }

        if (SessionServices.accountIsVerified(jwt) == false){
            return <Navigate to="/CheckYourEmail"/>
        }
        /****************************************************************/

        return (
            <>
                <center>
                    <h1>Select your Goal - Scorecard</h1>
                </center>
            </>
        );
    }
}

// With Router passes URL info to Class Component
export default withRouter(SelectGoalScorecard);