import React from 'react';
import withRouter from '../components/withRouter';
import '../App.css';

// Semantic UI
import { Grid, Container, Button,  } from 'semantic-ui-react'
import Nav from 'react-bootstrap/Nav';

import {Login} from '../services/auth.service';

// Handle Logged In State - Verified Account
import SessionServices from '../services/session.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import Footer from '../components/footer/Footer';
import HomeCarousel from '../components/carousel/HomeCarousel';
import PlatformLogo from '../components/PlatformLogo';
import { Link } from 'react-router-dom';

class HomeNotLoggedIn extends React.Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props){
        let navbar_height = 82.75;
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight - navbar_height,
            invalidCredentials: false,
            navbar_height: navbar_height,
            emailError: false
        };
    }

    componentDidMount(){
        document.title = "Login - Headai platform";
        // ...
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = (event) => {
        let height = window.innerHeight - this.state.navbar_height;
        this.setState({width: event.target.innerWidth, height: height});
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const formValues = event.target.elements;
        const email = formValues.email.value;
        const password = formValues.password.value;
        if (email.length === 0 || email.indexOf('@') < 0) {
            this.setState({
                emailError: true,
                invalidCredentials: true
            });
        } else {
            this.setState({
                emailError: false,
                invalidCredentials: false
            });
        }
        if (!this.state.emailError) {
            Login(email,password).then( response => {
                const credentialsAreInvalid = (response === null || response.code === 'InvalidCredentials');
                this.setState({
                        invalidCredentials : credentialsAreInvalid
                });    

                if (credentialsAreInvalid || response.token == null) return;

                // Set JWT Globally
                this.context.setJwt(response.token);
            });
        }
        //event.target.reset();
    }
    render(){
        /*******      Redirect to Home if already logged in       ********/
        const jwt = this.context.getJwt();
        if (SessionServices.isLoggedIn(jwt)){
            const query = new URLSearchParams(this.props.location.search);
            const path = query.get('path');
            console.log('PATH',path);
            if (path == null)
                var redirectPath = '/';        
            else
                redirectPath = `/?path=${path}`;
            //return <Navigate to={redirectPath}/>
        }
        /****************************************************************/

        return (
        <>
            <div className="Flex" style={{ marginBottom: '0' }}>
                <div className="VerticalImage" style={{ height: 'auto' }}>
                    {/* <img src={"/VerticalSideImage.jpg"}></img> */}
                </div>
                <div className="RightContainer">
                    <center>
                    <div className="RightContent">
                        <PlatformLogo />
                        <br/><br/>
                        <h3>Predicting Your Next Action</h3>
                        <p className="description">
                            Generative AI usually predicts the next word or next pixel. Headai predicts the next action you should take in order to be Futureproofed.
                            {/* Welcome to an extraordinary journey where possibilities unfold and imagination takes flight. 
                            Prepare to embark on an adventure like no other, 
                            where dreams become reality and the ordinary becomes extraordinary. */}
                        </p>

                        <br/>
                        <center>
                            {/* <Link style={{color:'white'}} to="/signup">
                                <Button color="violet" size="lg" type="submit" style={{'background-color': '#98379a'}}>
                                    Sign up
                                </Button>
                            </Link>
                            <Link style={{color:'#98379a'}} to="/Login">
                                <Button color='pink' basic size="lg" type="submit" style={{'color': '#98379a'}}>
                                    Log in
                                </Button>
                            </Link> */}
                            <Button color="violet" size="lg" type="submit" style={{'background-color': '#98379a'}} as='a' href='/signup'>
                                Sign up
                            </Button>
                            <Button color='pink' basic size="lg" type="submit" style={{'color': '#98379a'}} as='a' href='/Login'>
                                Log in
                            </Button>

                            <HomeCarousel />
                        </center>
                    </div>
                    </center>
                </div>
            </div>
            {/* <div>
                <Footer />
            </div> */}
        </>
        )
    }
}


// With Router passes URL info to Class Component
export default withRouter(HomeNotLoggedIn);