import React, { Component } from 'react'
import { Dropdown, Image, Grid, Accordion, Icon, Segment, Button, Container, Popup } from 'semantic-ui-react'

import * as Path1Services from '../services/path1.service';
import * as Path2Services from '../services/path2.service';
import * as MegatronServices from '../services/megatron.service';

import MapCarousel from '../components/carousel/MapCarousel';
import CourseRecommendations from '../components/courses/CourseRecommendations';
import * as CourseServices from '../services/courses.service';

// Used to Load Global JWT Variable
import { MainContext } from '../contexts/MainContext';
import { UploadFile } from '../components/upload/UploadFile';

import jwtServices from "../services/jwt.service";

const initial_recommendations_sources = [{
    key: '',
    text: 'Fetching Sources...',
    value: '',
    disabled: false
}];

const initial_top_map_options = [{
    key: '',
    text: 'Fetching Options...',
    value: '',
    disabled: false
}];

const initial_base_map_options = [{
    key: '',
    text: 'Fetching Options...',
    value: '',
    disabled: false
}];

class Path2 extends Component {
    // Used to Load Global JWT Variable
    static contextType = MainContext;

    constructor(props) {
        super(props);
    }
    state = {
        // Fetched Data
        recommendation_sources : initial_recommendations_sources,
        base_map_options : initial_base_map_options,
        top_map_options : initial_top_map_options,

        fetching_ui_data : true,

        // Urls of selected maps
        selected_dataset1: null,
        selected_dataset2: null,
        // Option selected (E.j. Linkedin)
        value_recommendations: null,
        // Url of the Generated Scorecard
        compare_map_url: null,

        text: '',
        activeIndex: null,
        activeIndex2: null,

        active_index_carousel: 0,
        // Active api index
        active_api_index: 0,
        // Set API token
        headaiToken: null,
        // Set active legend
        map_legend_1: '',
        map_legend_2: '',
        // Scorecard state
        scorecard_in_calculation: false,
        // Course listing api variables
        top_n_skills: [],
        top_n_missing: [],
    }

    handler = (val) => {
        console.log(val);
        this.setState({
            active_api_index: val
        });
    }
    handleChange = (e, {value}) => {
        // Arrow up and down must be skipped, otherwise wrong selected content is obtained
        if (e.code == 'ArrowDown') return;
        if (e.code == 'ArrowUp') return;

        if (e.nativeEvent.target.role == 'listbox'){
            var legend = e.nativeEvent.target.getElementsByClassName('selected')[0].textContent;
        } else {
            var legend = e.nativeEvent.target.textContent;
        }
        
        this.setState({ selected_dataset1:value , active_index_carousel:0, active_api_index: 0, map_legend_1: legend, value_recommendations: null });
    }

    handleChange2 = (e, {value}) => {
        // Arrow up and down must be skipped, otherwise wrong selected content is obtained
        if (e.code == 'ArrowDown') return;
        if (e.code == 'ArrowUp') return;

        if (e.nativeEvent.target.role == 'listbox'){
            var legend = e.nativeEvent.target.getElementsByClassName('selected')[0].textContent;
        } else {
            var legend = e.nativeEvent.target.textContent;
        }

        this.setState({ selected_dataset2:value, active_index_carousel:1, active_api_index: 1, map_legend_2: legend, value_recommendations: null });
    }

    handleRecommendationsChange = async (e, { value }) => {
        this.setState({ 
            value_recommendations: value,
        });
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }
    handleClickAccordion2 = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex2 } = this.state
        const newIndex = activeIndex2 === index ? -1 : index
    
        this.setState({ activeIndex2: newIndex })
    }

    async compare() {
        // Handle compare button state
        this.setState({scorecard_in_calculation: true});
        const url1 = this.state.selected_dataset1;
        const url2 = this.state.selected_dataset2;
        const legend_1 = this.state.map_legend_1;
        const legend_2 = this.state.map_legend_2;

        const params = {
            map_url_1: url1,
            map_url_2: url2,
            legend_1: legend_1,
            legend_2: legend_2,
            jwt: this.context.getJwt(),
        }
        
        
        try {
            var results = await MegatronServices.Scorecard(params);
        } catch (error) {
            var results = null;
            return;
        }

        await MegatronServices.waitUntilReady(results);
        const compareUrl = results.location;

        const {existing, missing, intersection} = await CourseServices.getGapFromCompareMap(results.location);
        
        this.setState({
            active_index_carousel:2,
            compare_map_url:compareUrl,
            active_api_index: 2,
            scorecard_in_calculation: false,
            top_n_skills: existing.slice(0, 10),
            top_n_missing: missing.slice(0, 10)
        });
    }

    componentDidMount() {
        document.body.style = 'background: #FFFFFF';
        document.title = "Future-Proof Education - Headai platform";
        // document.body.style = 'background: #DFCDD1';
        // Path 2 - #CACCDD
        // Path 3 - #EAF3E6
        this.refreshDataUI();
    }

    refreshDataUI(){
        console.log('Refreshing Data UI');
        const jwt = this.context.getJwt();
        const decoded = jwtServices.decodeJwt(jwt);
        Path2Services.getDataUI(jwt).then((data)=>{
            const {
                recommendation_sources: recommendation_sources,
                top_maps,
                base_maps,
            } = data;


            this.setState({
                recommendation_sources,
                top_map_options: top_maps,
                base_map_options: base_maps,
                fetching_ui_data: false,
                headaiToken: decoded.headaiToken,
            })
        });
    }
    
    render() {
        const {
            selected_dataset1,
            selected_dataset2,
            value_recommendations,
            compare_map_url,
            active_index_carousel,
            activeIndex,
            activeIndex2,
        } = this.state
        
        let accordion_title = 'Show API call <>';
        if (activeIndex === 0) {
            accordion_title = 'Hide API call <>';
        } else {
            accordion_title = 'Show API call <>';
        }
        let accordion_title2 = 'Show API call <>';
        if (activeIndex2 === 0) {
            accordion_title2 = 'Hide API call <>';
        } else {
            accordion_title2 = 'Show API call <>';
        }

        // Build Data for Carousel
        const infoMapsCarousel = [
            {
                'url': selected_dataset1,
                'props':{
                    hideLegend :false,
                    colors: ['#361C87'],
                }
            },{
                'url': selected_dataset2,
                'props':{
                    hideLegend:false,
                    colors: ['#5F0B61'],
                }
            },{
                'url': compare_map_url,
                'props':{
                    // colors: ['#0C690B', '#361C87', '#5F0B61'],
                    // colors: ['#249a22', '#4947b9', '#98379a'],
                    // colors: ['#50d04d', '#6b69e4', '#d35dd6'],
                    colors: ['#249a22', '#6b69e4', '#d35dd6'],
                    scale: 'flat',
                }
            }
        ];

        // Handle active api documentation
        let active_api_call = "";
        if (this.state.active_api_index === 0) {
            active_api_call = Path1Services.getApiCallTextToGraph(this.state.map_legend_1, this.state.headaiToken);
        } else if (this.state.active_api_index === 1) {
            active_api_call = Path1Services.getApiCallBuildKnowledgeGraph("",this.state.headaiToken);
        } else {
            active_api_call = Path1Services.getApiCallScorecard(this.state.selected_dataset1,this.state.selected_dataset2,this.state.map_legend_1,this.state.map_legend_2,this.state.headaiToken);
        }
        let active_suggestion = Path1Services.getApiCallCompass(this.state.top_n_skills, this.state.top_n_missing, this.state.headaiToken);
        // Handle scorecard state
        let calculation_button = '';
        if (this.state.scorecard_in_calculation) {
            calculation_button = <Button loading color='green' fluid content='Loading' disabled/>;
        } else {
            // calculation_button = <Button color='green' fluid content='Compare' onClick={() => this.compare()} />;
            if (this.state.selected_dataset1 !== null && this.state.selected_dataset2 !== null) {
                calculation_button = <Button color='green' fluid content='Compare' onClick={() => this.compare()} />;
            } else {
                calculation_button = <Button color='green' fluid content='Compare' disabled onClick={() => this.compare()} />;
            }
        }

        return (
            <>
                <Container fluid style={{backgroundImage: 'url(/hero.png)', backgroundPosition: 'center center', color: '#fff'}}>
                    {/* This to banner */}
                    <Grid stackable>
                        <Grid.Column width={8} style={{margin: '10px 60px'}}>
                            <h1>Futureproof education. Forecast future skill needs with data-driven insights.</h1>
                        </Grid.Column>
                    </Grid>
                </Container>
                <Container fluid>
                    <Grid stackable verticalAlign='bottom'>
                        <Grid.Column width={3}>
                            <UploadFile triggerRefresh={()=>{this.refreshDataUI.call(this)}} />
                            {/* This to open modal view */}
                            <Dropdown
                                placeholder='Select a Dataset'
                                fluid
                                selection
                                // search
                                inline
                                label='First map'
                                className='dataset1_dropdown'
                                options={this.state.base_map_options}
                                onChange={this.handleChange}
                                value={selected_dataset1}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <span>Compare to other dataset</span>
                            <Dropdown
                                placeholder='Select a Dataset'
                                fluid
                                selection
                                // search
                                inline
                                label='Second map'
                                className='dataset2_dropdown'
                                options={this.state.top_map_options}
                                onChange={this.handleChange2}
                                value={selected_dataset2}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {calculation_button}
                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                            <p>
                                Unlock skills data to revolutionize job demand and education match. Begin with Data 1: Curriculum or use your own. We transform this into actionable skills data. Explore Data 2 for immediate and future skill needs. Compare to your curriculum, find gaps and opportunities for a competitive edge.
                            </p>
                        </Grid.Column>
                    </Grid>
                </Container>
                <Container fluid>
                    {/* VISUALIZE SELECTED MAP HERE */}
                    <MapCarousel handler={this.handler} active_index_carousel={active_index_carousel} info_maps={infoMapsCarousel}></MapCarousel>
                    {/**<Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />**/}
                    <Grid floated='right'>
                        <Grid.Column floated='right' width={1}>
                            <Popup
                                trigger={<Icon name='question' color='black' inverted size='large' circular />}
                                hoverable
                                // open={true}
                                content={<div>How to read Headai Graphs?<br />
                                Headai Visualizer allows you to easily find specific concepts in the Graphmind, recenter the map around the chosen concept, highlight the clusters, delete the concepts according to your preference, and display the source data of the concepts.<br />
                                Below can be found the meanings of each icon:<br />
                                <div style={{display: 'flex'}}><div style={{margin: '3px'}}>🔍</div><div style={{margin: '3px'}}>Search the concept by clicking on this icon and typing the concept in the search bar.<br /></div></div>
                                <div style={{display: 'flex'}}><div style={{margin: '3px'}}>👆</div><div style={{margin: '3px'}}>Click this to see all "Action" -buttons in the interface. First, choose the action you want to execute (as seen from the below list) and then click the concept you want to execute it in the MindMap:<br /></div></div>
                                <div style={{display: 'flex'}}><div style={{margin: '3px 3px 3px 23px'}}>🗑️</div><div style={{margin: '3px'}}>Remove clicked elements from the Graphmind temporally. This action is reverted if you reload the visual.<br /></div></div>
                                <div style={{display: 'flex'}}><div style={{margin: '3px 3px 3px 23px'}}>🎯</div><div style={{margin: '3px'}}>Recenter the concepts of the Graphmind around the selected one.<br /></div></div>
                                <div style={{display: 'flex'}}><div style={{margin: '3px 3px 3px 23px'}}>💡</div><div style={{margin: '3px'}}>Highlight the related concepts (cluster).<br /></div></div>
                                <div style={{display: 'flex'}}><div style={{margin: '3px 3px 3px 23px'}}>🕵️‍♂️</div><div style={{margin: '3px'}}>See the sources of the selected concept.<br /></div></div>
                                </div>}
                                position='left center'
                                pinned
                                wide='very'
                            />
                            
                        </Grid.Column>
                    </Grid>
                </Container>
                <Container fluid className='margin-top-50'>
                    <Accordion fluid>
                        <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={this.handleClick}
                        >
                            {/* <Icon name='dropdown' /> */}
                            <b><u>{accordion_title}</u></b>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                            <pre>
                                {/* #!/bin/bash<br/>
                                Try to detect index from MapCarousel: {this.state.active_api_index} <br/>
                                API CALL FOR MAP: {active_index_carousel}<br/>
                                # test
                                echo 'hello there' */}
                                {active_api_call}
                            </pre>
                        </Accordion.Content>
                    </Accordion>
                </Container>
                <Container fluid>
                    <Grid stackable verticalAlign='top'>
                        <Grid.Column width={3}>
                            <p>
                                Identify gaps and areas for development through dataset comparison. Next, Headai Graphmind provides you valuable resources as a compass, guiding your path for further improvement and growth.
                            </p>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown
                                placeholder='Get recommendations'
                                selection
                                // search
                                inline
                                label=''
                                className='dataset2_dropdown'
                                options={this.state.recommendation_sources}
                                onChange={this.handleRecommendationsChange}
                                value={value_recommendations}
                            />
                            {/* <Container> */}
                                {/* Here will be course recommendations. Same component that is in VisionsGalaxy */}
                                <CourseRecommendations namespace={value_recommendations} compare_url={compare_map_url}></CourseRecommendations>
                            {/* </Container> */}
                        </Grid.Column>
                    </Grid>
                    <Grid stackable verticalAlign='top' className='margin-top-50'>
                        <Grid.Column floated='right' width={13}>
                            <Accordion fluid>
                                <Accordion.Title
                                    active={activeIndex2 === 0}
                                    index={0}
                                    onClick={this.handleClickAccordion2}
                                >
                                    {/* <Icon name='dropdown' /> */}
                                    <b><u>{accordion_title2}</u></b>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex2 === 0}>
                                    <pre>
                                        {active_suggestion}
                                    </pre>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Column>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default Path2;