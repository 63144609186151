import axios from "axios";

async function getDataUI(jwt){
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    const url = BACKEND_ROUTE + '/data/path1';
    const params = {jwt};
    var response = await axios.get(url, {params});
    if (response == null) return null;
    const content = response.data;
    return content;
}

function getApiCallTextToGraph(text, internal_token) {
    // const api = "https://megatron.headai.com/Scorecard?map_url_1="+map_url_1+"&map_url_2="+map_url_2+"&legend_1="+legend_1+"&legend_2="+legend_2+"&token="+tok
    let api = 
`You can copy and paste these queries to for example Postman to test.
Sample call: https://megatron.headai.com/TextToGraph
             https://megatron.headai.com/TextToGraph?language=en&ontology=headai&text=**YOUR TEXT HERE**&token=${internal_token}
Explain: Build Graph Map out of text. Data can be cached for future analysis purposes or result can be stored if wanted later on. In privacy mode nothing is stored (mydata)
         Full API: https://megatron.headai.com/TextToGraph
Detail: Send data in json body or in url parameters
Parameters:
            token: [token]
            language: [en, fi]
            ontology: [headai, esco, yso, fibo, no-ontology, ...]
            text: [free text]
            output: [json]
            update: [boolean, used if wanted to update existing text]
            high_privacy_mode: [true, false, empty] || false or empty -> results will be stored, true -> nothing will be stored
JSON body format:
            {
                "token": "${internal_token}",
                "language": "en",
                "output": "json",
                "update": false,
                "text": "**YOUR TEXT HERE**",
                "ontology": "headai"
            }\n`;
    return api;
}

function getApiCallBuildKnowledgeGraph(text, internal_token) {
    // const api = "https://megatron.headai.com/Scorecard?map_url_1="+map_url_1+"&map_url_2="+map_url_2+"&legend_1="+legend_1+"&legend_2="+legend_2+"&token="+tok
    let api = 
`NOTE: You can not build these with Freemium token.
Sample call: https://megatron.headai.com/BuildKnowledgeGraph
             https://megatron.headai.com/BuildKnowledgeGraph?language=en&ontology=headai&output=json&token=${internal_token}
Explain: Build analysis based on open data (job ads, DOAJ articles, theseus). Check result JSON using: https://megatron.headai.com/HeadaiVisualizer.html?json_url=RETURN_URL
Detail: Send data in json body or in url parameters
Parameters:
            token: [token]
            dataset: [job_ads, doaj_articles, imported, investment_data]
            search_text: [comma separated list] (environment-and-energy,digital-and-energy -> environment AND energy OR digital AND energy)
            search_year: [2000->]
            search_month: [1-12]
            language: [fi, en]
            country: [country (or countries separated by comma), if city is defined country should not be used]
            city: [city (or cities separated by comma), if country is defined city has no effect]
            legend: [legend to map]
            output: [json]
JSON body format:
            {
                "token": "${internal_token}",
                "dataset": "job_ads",
                "search_text": "",
                "search_year": "2022",
                "search_month": "6",
                "language": "fi",
                "country": "",
                "city": "pori",
                "output": "json"
            }
`;
    return api;
}
function getApiCallScorecard(map1, map2, legend1, legend2, internal_token) {
    // let internal_token = '';
    let api = 
`Sample call: https://megatron.headai.com/Scorecard
             https://megatron.headai.com/Scorecard?token=${internal_token}&language=en&output=json&map_url_1=${map1}&map_url_2=${map2}&legend1=${legend1}&legend2=${legend2}
Explain: Build Scorecard with your data. Headai Graphmind results can be visualized in: https://megatron.headai.com/HeadaiVisualizer.html?json_url=RETURN_URL
Detail: Send data in json body or in url parameters
Parameters:
            token: [token]
            language: [en, fi]
            map_url_1: [URL to map]
            map_url_2: [URL to goal map]
            legend_1: [set legend to map_url_1]
            legend_2: [set legend to map_url_2]
JSON body format:
            {
                "token": "${internal_token}", 
                "language": "en", 
                "map_url_1": "${map1}", 
                "map_url_2": "${map2}", 
                "legend_1": "${legend1}",
                "legend_2: "${legend2}"
            }
`;
    return api;
}
function getApiCallCompass(skills, interests, internal_token) {
    // let internal_token = '';
    // Top 10 keywords for each skills and interests
    let input_json = {
        "data": {
            "namespace": "linkedin_learning",
            "request": ["zpd"],
            "interests": interests,
            "skills": skills,
            "completed": [],
            "mandatory": [],
            "suggest_from_set": []
        }
    };
    // Fix data for output
    const searchRegExp = /,/gi;
    const replaceWith = '","';

    const skills_fixed = skills.toString().replace(searchRegExp, replaceWith);
    const interests_fixed = interests.toString().replace(searchRegExp, replaceWith);
    
    let input_json_str = JSON.stringify(input_json);
    let api = 
`
Sample call: https://megatron.headai.com/Utils
             https://megatron.headai.com/Utils?action=skills_compass&data=${input_json_str}&output=json&token=${internal_token}
Explain: Suggest relevant courses in given scheme
Detail: Send data in header or in url parameters
Variables:
            token: [token]
            action: [skills_compass]
            data: [json format can be seen below]
            output: [json]

Explain 
zpd == Zone of Proximal development
match == matching
{
    "data": {
        "namespace": "linkedin_learning",
        "request": ["zpd"],
        "interests":["${interests_fixed}"],
        "skills":["${skills_fixed}"],
        "completed":[],
        "mandatory":[],
        "suggest_from_set": []
    }
}
`;
    return api;
}
export {
    getApiCallCompass,
    getApiCallScorecard,
    getApiCallTextToGraph,
    getApiCallBuildKnowledgeGraph,
    getDataUI,
}